/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
import {useHistory} from 'react-router-dom';
//GraphQl
import {useQuery} from '@apollo/client';
import {
  GET_MESSAGES,
  // GET_STUDENTS_ID
} from 'requests/queries';
//Actions
import {setDataCSV} from 'store/students/actions';
import {MessagesActionsRedux} from 'store/messages/actions';
//Components
import PaginationComponent from 'components/Pagination';
// import {ModalFiltersMessages} from 'components/Modals';
import {columns} from 'components/Tables/TableMessagesComponent/columns';
import PageWithDrawer from 'components/PageWithDrawer';
import LoadingComponent from 'components/LoadingComponent';
import TableComponent from 'components/Tables/TableMessagesComponent';
import {Button, Radio, Tooltip} from 'antd';
//Styles
import {useStyles} from '../defaultStylesListPage';
//Functions, utils
import {setValueSearch, setNumberPage} from 'containers/defaultFunc';
import {StudentsActionsRedux} from "../../store/students/actions";

const options = [
  {label: 'Manual messages', value: false},
  {label: 'Auto messages', value: true},
];

const MessagesHistory: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {limit, currentPage, messages, messagesCount, filters}: any = useSelector((state: AppState) => state.messages);
  const history = useHistory();
  // const [filterOpen, setfilterOpen] = useState(false);
  // const [studentsAvailable, setstudentsAvailable] = useState({students: []});

  // const getStudents = useQuery(GET_STUDENTS_ID, {
  //   variables: {
  //     limit: 100000,
  //     page: currentPage,
  //     order: undefined,
  //     orderDirection: undefined,
  //     filters: {
  //       student: undefined,
  //     },
  //   },
  // });
  const getMessages = useQuery(GET_MESSAGES, {
    variables: {
      limit,
      page: currentPage,
      filters: {is_automated: (filters.is_automated && filters.is_automated) || undefined},
    },
  });
  // useEffect(() => {
  //   if (getStudents) {
  //     if (getStudents.data) {
  //       setstudentsAvailable({
  //         students: getStudents.data.students.result.map((item: any) => {
  //           return {
  //             name: item.first_name && item.last_name ? `${item.first_name} ${item.last_name}` : `${item.username}`,
  //             key: item.id,
  //             id: item.id,
  //           };
  //         }),
  //       });
  //     }
  //   }
  // }, [getStudents]);
  useEffect(() => {
    if (getMessages.data) {
      dispatch(MessagesActionsRedux.setEntities(getMessages.data.messages.result));
      dispatch(MessagesActionsRedux.setEntityCount(getMessages.data.messages.total));
    }
  }, [getMessages]);

  useEffect(() => {
    getMessages.refetch();
  }, [history]);

  useEffect(() => {
    dispatch(MessagesActionsRedux.setSorting(undefined));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDataCSV(undefined));
  }, [dispatch]);

  const onChange1 = (e: any) => {
    dispatch(MessagesActionsRedux.setCurrentPage(1));
    dispatch(MessagesActionsRedux.setFilters({is_automated: e.target.value}));
  };
  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {getMessages.error && <LoadingComponent />}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Radio.Group options={options} style={{margin: '1rem'}} onChange={onChange1} value={filters.is_automated} />
          <Tooltip
            placement="top"
            title={`Manual messages refer to messages sent directly from the Admin panel, Auto messages - refer to messages sent from likin Opportunity/Event.`}>
            <Button
              type="text"
              style={{padding: '5px', display: 'flex', alignItems: 'center', height: '2rem', marginLeft: '1rem'}}>
              <span style={{fontSize: '10px'}}>ⓘ</span>
            </Button>
          </Tooltip>
          {/* <Button type="primary" style={{marginLeft: '4rem'}} onClick={() => setfilterOpen(true)}>
            Set filters
          </Button> */}
        </div>
        <div style={{background: '#ffffff', width: '100%'}}>
          <TableComponent
            columns={columns}
            entity={messages}
            onChangeTable={setValueSearch({
              dispatch,
              setFunc: MessagesActionsRedux.setSorting,
              refetchEntity: getMessages,
              setPageFunc: MessagesActionsRedux.setCurrentPage
            })}
          />
          <div className={styles.borderPagination}>
            <PaginationComponent
              currentPage={currentPage}
              limit={limit}
              onChangeNumber={setNumberPage(
                dispatch,
                MessagesActionsRedux.setCurrentPage,
                MessagesActionsRedux.setLimit,
                getMessages,
              )}
              total={messagesCount}
            />
          </div>
        </div>
        {/* <ModalFiltersMessages
          open={filterOpen}
          onClose={() => setfilterOpen(false)}
          optionsData={studentsAvailable}
          getMessages={getMessages}
        /> */}
      </PageWithDrawer>
    </div>
  );
};

export default MessagesHistory;
