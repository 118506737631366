import React, {FC} from 'react';
import {TreeSelect} from 'antd';
 

interface Props {
  treeData: any;
  width?: string;
  handleChange?: any;
  defaultValue?: any;
  valueSelector?: any;
}

const TreeSelector: FC<Props> = ({treeData, width = '100%', handleChange, defaultValue, valueSelector}) => {
  return (
    <TreeSelect
      treeData={treeData}
      treeCheckable={true}
      defaultValue={defaultValue && defaultValue}
      showSearch
      value={valueSelector && valueSelector}
      filterTreeNode={(input: any, option: any) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      placeholder={'Please select'}
      style={{
        width,
        paddingRight: '1rem',
        margin: '0.2rem 0px',
      }}
      onChange={handleChange}
    />
  );
};
export default TreeSelector;
