import React, {FC} from 'react';
//Components
import {Form, Input, Select, Button} from 'antd';
import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/UI/Icon';
import FormInput from 'components/UI/FormInput';
//Types
import {Filters} from 'store/referrals/types';

//Styling
import {useStyles} from './styles';

const {Option} = Select;

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: Filters) => void;
  filters?: Filters;
  isFilter?: boolean;
  onResetFilters?: () => void;
}

const referralTypesOptions = [
  {title: 'Account manager', value: 'account_manager'},
  {title: 'Campus ambassador', value: 'campus_ambassador'},
  {title: 'Student organisation', value: 'student_organisation'},
];

const ModalReferrals: FC<Props> = ({onClose, onSubmit, open, isFilter, filters, onResetFilters}: Props) => {
  const styles = useStyles();
  const [form] = Form.useForm();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleReset = () => {
    form.resetFields();
    onResetFilters?.();
  };

  const handleInputCode = (e: any) => {
    const formattedValue = e.target.value.replace(/\s+/g, '')?.toUpperCase();
    form.setFieldsValue({code: formattedValue});
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <Form form={form} onFinish={onSubmit} initialValues={filters}>
        <div className={styles.modalFilters}>
          <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={handleClose}>
            <Icon name="close" />
          </div>
          <div className={styles.borderAllFilters2}>
            <FormInput
              name={'code'}
              labelKey="Code:"
              className={styles.input1}
              message={isFilter ? undefined : 'Referral code is required field'}>
              <Input onChange={handleInputCode} placeholder="Referral code" />
            </FormInput>

            <FormInput
              name={'type'}
              labelKey={'Type:'}
              className={styles.input1}
              message={isFilter ? undefined : 'Type code is required field'}>
              <Select showSearch filterOption={false} allowClear={true}>
                {referralTypesOptions?.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </FormInput>
          </div>

          <div className={styles.buttonsWrapperFilters}>
            <Button
              type="primary"
              className={styles.button}
              style={{marginLeft: 0}}
              onClick={isFilter ? handleReset : handleClose}>
              {isFilter ? 'Reset' : 'Cancel'}
            </Button>
            <Button type="primary" htmlType="submit" style={{width: '200px'}}>
              {isFilter ? 'Set filters' : 'Save'}
            </Button>
          </div>
        </div>
      </Form>
    </Dialog>
  );
};

export default ModalReferrals;
