import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal} from 'antd';

interface ICropperModalProps {
  visible?: boolean;
  onCancel: () => void;
  onSave: (imageUrl: string) => void;
  image: any;
  aspectValue: number;
}

const MAX_IMAGE_WIDTH = 3000;

const CropperModal: React.FunctionComponent<ICropperModalProps> = ({visible, onCancel, image, onSave, aspectValue}) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const previewCanvasRef = React.useRef(null);

  const [crop, setCrop] = React.useState<any>({unit: '%', width: 100, aspect: aspectValue});
  const [completedCrop, setCompletedCrop] = React.useState<any>(null);

  const setImageRef = React.useCallback((img: HTMLImageElement) => {
    (imgRef as any).current = img;
  }, []);

  const generateDownload = React.useCallback(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    const canvasWidth = Math.min(completedCrop.width * scaleX, MAX_IMAGE_WIDTH);
    const canvasHeight = Math.min(
      completedCrop.height * scaleY,
      (MAX_IMAGE_WIDTH * completedCrop.height) / completedCrop.width,
    );

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        canvasWidth,
        canvasHeight,
      );
    }

    canvas.toBlob(
      (blob: any) => {
        const previewUrl = window.URL.createObjectURL(blob);
        onSave(previewUrl);
      },
      'image/jpeg',
      1,
    );
  }, [onSave, completedCrop]);

  return (
    <Modal
      visible={visible}
      footer={null}
      width={600}
      maskClosable={false}
      onCancel={onCancel}
      title="image"
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <ReactCrop src={image} onImageLoaded={setImageRef} crop={crop} onChange={setCrop} onComplete={setCompletedCrop} />
      <div style={{display: 'none'}}>
        <canvas ref={previewCanvasRef} />
      </div>
      <Button type="primary" onClick={generateDownload} style={{marginTop: '1rem', width: '100%'}}>
        Save
      </Button>
    </Modal>
  );
};

export default CropperModal;
