import React, {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
//Components
import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from '../styles';
import {EventsActionsRedux} from 'store/events/actions';
import {setCurrentFilters} from 'containers/defaultFunc';
import TreeSelector from 'components/UI/TreeSelector';
import UIButtonsWrapper from './UIButtonsWrapper';

interface Props {
  open: boolean;
  onClose: any;
  filters: any;
  getEvents: any;
}
const ModalFiltersEvents: FC<Props> = ({onClose, open, filters, getEvents}: Props) => {
  // Styling
  const styles = useStyles();
  const [filtersSelect, setfiltersSelect] = useState<any>([]);
  const dispatch = useDispatch();

  const {filters: reduxFilters}: any = useSelector((state: AppState) => state.events);

  const onChange = (data: any, data1: any) => {
    setfiltersSelect({...filtersSelect, [data1]: data});
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <div className={styles.modalFilters}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={styles.borderAllFilters}>
          {filters.map((item: any, id: number) => {
            return (
              <div key={id} className={styles.borderOneFilter}>
                <div className={styles.label}>{item.title}</div>
                <TreeSelector
                  treeData={item.values}
                  width={'26rem'}
                  handleChange={(id: any) => onChange(id, item.keySave)}
                  defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
                />
              </div>
            );
          })}
        </div>
        <UIButtonsWrapper
          styles={styles}
          onClickReset={() => {
            setCurrentFilters([], dispatch, EventsActionsRedux, onClose, getEvents);
            setfiltersSelect([]);
          }}
          onClickSetFilters={() => {
            setCurrentFilters(filtersSelect, dispatch, EventsActionsRedux, onClose, getEvents);
          }}
        />
      </div>
    </Dialog>
  );
};

export default ModalFiltersEvents;
