import React, {FC, useEffect, useState} from 'react';

import PageWithDrawer from 'components/PageWithDrawer';
import {useStyles} from '../defaultStylesListPage';

const Page404: FC = () => {
  // Styling
  const styles = useStyles();
  const [goToEntity, setgoToEntity] = useState('companies');
  useEffect(() => {
    if (window.location.pathname.includes('/opportunities')) {
      setgoToEntity('opportunities');
    } else if (window.location.pathname.includes('/events')) {
      setgoToEntity('events');
    } else if (window.location.pathname.includes('/students')) {
      setgoToEntity('students');
    }
  }, []);
  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        <div style={{textAlign: 'center'}}>
          <h1>404</h1>
          <h2>The page does not exist</h2>
          <h2
            style={{textDecoration: 'underline', cursor: 'pointer'}}
            onClick={() => window.open(`/${goToEntity}`, '_self')}>
            Go to list of {goToEntity}
          </h2>
        </div>
      </PageWithDrawer>
    </div>
  );
};

export default Page404;
