import {Reducer} from 'redux';
import {produce} from 'immer';
import {StudentsActionTypes, StudentsActions, StudentsState} from './types';

const initialState: StudentsState = {
  studentsCount: 0,
  students: [],
  currentPage: 1,
  likedEntity: {},
  limit: 20,
  selectedRowKeys: [],
  selectedRowKeysWithMessages: [],
  selectedRowKeysOnDiffPages: {},
};

const studentsReducer: Reducer<StudentsState, StudentsActions> = (state = initialState, action): StudentsState => {
  return produce(state, (draft: StudentsState) => {
    switch (action.type) {
      case StudentsActionTypes.STUDENTS_SET_STUDENTS:
        draft.students = action.payload;
        return;

      case StudentsActionTypes.STUDENTS_SET_STUDENTS_COUNT:
        draft.studentsCount = action.payload;
        return;

      case StudentsActionTypes.STUDENTS_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case StudentsActionTypes.STUDENTS_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case StudentsActionTypes.STUDENTS_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_FILTERS:
        draft.filters = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_SEARCH_NAME:
        draft.searchName = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_DATA_FOR_CSV:
        draft.dataCSV = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS:
        draft.selectedRowKeys = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_WITH_MESSAGES:
        draft.selectedRowKeysWithMessages = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_ON_DIFF_PAGES:
        draft.selectedRowKeysOnDiffPages = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_MESSAGE_ID:
        draft.messageId = action.payload;
        return;
      case StudentsActionTypes.STUDENTS_SET_LIKED_ENTITY:
        draft.likedEntity = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default studentsReducer;
