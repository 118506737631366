import {Sorter} from 'store/classForListPage';

export interface StudentsState {
  students: Student[];
  studentsCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  searchName?: string;
  filters?: Filters;
  likedEntity: any;
  dataCSV?: string[];
  messageId?: string;
  selectedRowKeys: string[];
  selectedRowKeysWithMessages: string[];
  selectedRowKeysOnDiffPages: any;
}
export interface Filters {
  achievements: string[];
  activities: string[];
  work_experiences: string[];
  international_experiences: string[];
  institutes: string[];
  education_level: string[];
  city: string;
  country: string;
  referrer: string;
}

export interface Student {
  id: string;
  email: string;
  phone_number: string;
  username: string;
  first_name: string;
  last_name: string;
  birthday: string;
  country: string;
  city: string;
  provider: string;
  request_language: string[];
  institutes: string[];
  international_experiences: string[];
  work_experiences: string[];
  education_level: string[];
  achievements: string[];
  activities: string[];
  languages: string[];
  created: string;
  short_bio: string;
  social_media: string[];
  image_url: string;
  referrer: string;
  contact_email: string;
}
export enum StudentsActionTypes {
  STUDENTS_SET_STUDENTS = 'STUDENTS_SET_STUDENTS',
  STUDENTS_SET_STUDENTS_COUNT = 'STUDENTS_SET_STUDENTS_COUNT',
  STUDENTS_SET_LIMIT = 'STUDENTS_SET_LIMIT',
  STUDENTS_SET_CURRENT_PAGE = 'STUDENTS_SET_CURRENT_PAGE',
  STUDENTS_SET_SORTING = 'STUDENTS_SET_SORTING',
  STUDENTS_SET_SEARCH_NAME = 'STUDENTS_SET_SEARCH_NAME',
  STUDENTS_SET_FILTERS = 'STUDENTS_SET_FILTERS',
  STUDENTS_SET_DATA_FOR_CSV = 'STUDENTS_SET_DATA_FOR_CSV',
  STUDENTS_SET_SELECTED_ROW_KEYS = 'STUDENTS_SET_SELECTED_ROW_KEYS',
  STUDENTS_SET_SELECTED_ROW_KEYS_WITH_MESSAGES = 'STUDENTS_SET_SELECTED_ROW_KEYS_WITH_MESSAGES',
  STUDENTS_SET_SELECTED_ROW_KEYS_ON_DIFF_PAGES = 'STUDENTS_SET_SELECTED_ROW_KEYS_ON_DIFF_PAGES',
  STUDENTS_SET_MESSAGE_ID = 'STUDENTS_SET_MESSAGE_ID',
  STUDENTS_SET_LIKED_ENTITY = 'STUDENTS_SET_LIKED_ENTITY',
}

export interface StudentsSetStudents {
  type: StudentsActionTypes.STUDENTS_SET_STUDENTS;
  payload: Student[];
}

export interface StudentsSetStudentsCount {
  type: StudentsActionTypes.STUDENTS_SET_STUDENTS_COUNT;
  payload: number;
}

export interface StudentsSetCount {
  type: StudentsActionTypes.STUDENTS_SET_LIMIT;
  payload: number;
}

export interface StudentsSetPage {
  type: StudentsActionTypes.STUDENTS_SET_CURRENT_PAGE;
  payload: number;
}

export interface StudentsSetSorting {
  type: StudentsActionTypes.STUDENTS_SET_SORTING;
  payload: Sorter | undefined;
}
export interface StudentsSetSearchName {
  type: StudentsActionTypes.STUDENTS_SET_SEARCH_NAME;
  payload: string | undefined;
}
export interface StudentsSetFilters {
  type: StudentsActionTypes.STUDENTS_SET_FILTERS;
  payload: Filters | undefined;
}
export interface StudentsSetMessageId {
  type: StudentsActionTypes.STUDENTS_SET_MESSAGE_ID;
  payload: string | undefined;
}
export interface StudentsSetSelectedForCSV {
  type: StudentsActionTypes.STUDENTS_SET_DATA_FOR_CSV;
  payload: string[] | undefined;
}
export interface StudentsSetSelectedRowKeys {
  type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS;
  payload: string[];
}
export interface StudentsSetSelectedRowKeysWithMessages {
  type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_WITH_MESSAGES;
  payload: string[];
}
export interface StudentsSetSelectedRowKeysOnDiffPages {
  type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_ON_DIFF_PAGES;
  payload: any;
}
export interface setEntityLiked {
  type: StudentsActionTypes.STUDENTS_SET_LIKED_ENTITY;
  payload: any;
}
export type StudentsActions =
  | StudentsSetStudents
  | StudentsSetStudentsCount
  | StudentsSetCount
  | StudentsSetPage
  | StudentsSetSorting
  | StudentsSetSearchName
  | StudentsSetFilters
  | StudentsSetSelectedForCSV
  | StudentsSetSelectedRowKeys
  | StudentsSetSelectedRowKeysWithMessages
  | StudentsSetSelectedRowKeysOnDiffPages
  | setEntityLiked
  | StudentsSetMessageId;
