import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store';
//GraphQl
import {useQuery, useMutation} from '@apollo/client';
import {GET_EVENTS, GET_EVENTS_OPTIONS_FOR_FILTERS} from 'requests/queries';
import {DELETE_EVENT} from 'requests/mutations';
//Actions
import {EventsActionsRedux} from 'store/events/actions';
import {initialState} from 'store/eventDetails/reducer';
import {EventActionsRedux} from 'store/eventDetails/actions';
//Components
import {columns} from 'components/Tables/TableEventsComponent/columns';
import {ModalFiltersEvents} from 'components/Modals';

import EntityList from 'containers/classList';

const optionsSynchronize = [{label: 'Types', value: 'types'}];

const Events: FC = () => {
  const [filterOpen, setfilterOpen] = useState(false);
  const [filtersCurrent, setfiltersCurrent] = useState<any>([]);

  const {limit, currentPage, sorting, searchName, filters}: any = useSelector((state: AppState) => state.events);
  const {data: eventOptionsData} = useQuery(GET_EVENTS_OPTIONS_FOR_FILTERS, {
    skip: !filterOpen,
  });

  const getStartDate = (): string => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date.toUTCString();
  };

  const getEvents = useQuery(GET_EVENTS, {
    variables: {
      limit,
      page: currentPage,
      orderDirection: sorting?.orderType,
      order: sorting?.order,
      search: {
        types: (filters?.types?.length > 0 && filters?.types) || undefined,
        sectors: (filters?.sectors?.length > 0 && filters?.sectors) || undefined,
        name: searchName,
        start_date: getStartDate(),
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [deleteEvent] = useMutation(DELETE_EVENT);

  useEffect(() => {
    if (eventOptionsData) {
      setfiltersCurrent([
        {
          title: 'Types',
          keySave: 'types',
          values: eventOptionsData?.eventTypes.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Sectors',
          keySave: 'sectors',
          values: eventOptionsData?.companySectors.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
      ]);
    }
  }, [eventOptionsData]);

  return (
    <EntityList
      optionsSynchronize={optionsSynchronize}
      EntityActionsRedux={EventsActionsRedux}
      SingleEntityActionsRedux={EventActionsRedux}
      getEntity={getEvents}
      deleteNext={getEvents.refetch}
      deleteEntity={deleteEvent}
      entityTitle={'event'}
      entitiesTitle={'events'}
      initialState={initialState.event}
      filterOpen={{filterOpen, setfilterOpen}}
      columns={columns}
      tooltipText={`In case event type was changed in the database this button has to be pressed. No additional action needed if new type was added.`}>
      <ModalFiltersEvents
        open={filterOpen}
        onClose={() => setfilterOpen(false)}
        filters={filtersCurrent}
        getEvents={getEvents}
      />
    </EntityList>
  );
};

export default Events;
