import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    width: '100%',
    margin: '0.5rem 0',
    color: 'rgba(0, 0, 0, 0.85)',
    '& div': {
      fontWeight: 'initial',
      wordWrap: 'break-word',
    },
    '& li': {
      fontWeight: 'initial',
      wordWrap: 'break-word',
    },
  },
  borderWithImage: {
    position: 'relative',
  },
  containerStyleJustImage: {
    width: '105px',
    height: '105px',
    position: 'relative',
    marginRight: '10px',
    marginBottom: '10px',
    objectFit: 'contain',
    border: '1px solid rgb(217, 217, 217)',
  },
  titleLiked: {
    fontWeight: 'bold',
  },
  divLiked: {
    width: '33%',
    padding: '1rem 2rem ',
    borderRight: '1px solid lightgray',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  container: {
    padding: '1rem 3rem 3rem',
    background: '#ffffff',
    display: 'flex',
    width: '100%',
  },
}));
