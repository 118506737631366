export interface RouteObj {
  to: string;
  labelKey: string;
}

export const routes: RouteObj[] = [
  {
    to: '/companies',
    labelKey: 'Companies',
  },
  {
    to: '/opportunities',
    labelKey: 'Opportunities',
  },
  {
    to: '/events',
    labelKey: 'Events',
  },
  {
    to: '/students',
    labelKey: 'Students',
  },
  {
    to: '/messages-history',
    labelKey: 'Messages history',
  },
  {
    to: '/referrals',
    labelKey: 'Referral codes',
  },
];
