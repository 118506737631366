export class SingleEntity {
  actionTypes: any;
  title: string;

  constructor(actionTypes: any, title: string) {
    this.actionTypes = actionTypes;
    this.title = title;
  }
  setEntity = (entity: any) => {
    return {
      type: this.actionTypes[`${this.title}_SET`],
      payload: entity,
    };
  };

  setEntityId = (id: any) => {
    return {
      type: this.actionTypes[`${this.title}_SET_ID`],
      payload: id,
    };
  };
}
