import React, {FC} from 'react';
import {TreeSelect} from 'antd';
 
import FormInput from '../FormInput';

interface Props {
  array: any;
  name: string;
  label: string;
  onChange?: any;
  optional?: boolean;
}

const TreeSelector: FC<Props> = ({array, name, label, onChange, optional}) => {
  return (
    <FormInput name={name} labelKey={`${label}:`} message={optional ? undefined : `${label} is required field`}>
      <TreeSelect
        treeData={array}
        treeCheckable={true}
        showSearch
        filterTreeNode={(input: any, option: any) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        placeholder={'Please select'}
        style={{
          width: '100%',
          paddingRight: '1rem',
          margin: '0.2rem 0px',
        }}
        onChange={onChange}
      />
    </FormInput>
  );
};
export default TreeSelector;
