import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
//Actions
import {login} from 'store/session/actions';
//Styling
import {Form} from 'antd';
import {Input} from 'antd';
import FormInput from 'components/UI/FormInput';
import {useStyles} from './styles';
//Images
import logo from 'assets/images/launch.svg';

const Login: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = async (data: any) => {
    const response = await dispatch(login({...data}));
    if (response.msg === 'success login') {
      window.open('/companies', '_self');
    }
  };
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.block}>
        <div className={styles.left}>
          <div className={styles.borderLogo}>
            <img src={logo} alt="logo" style={{width: '16rem'}} />
          </div>
        </div>
        <div className={styles.right}>
          <Form form={form} onFinish={onSubmit}>
            <p className={styles.adminPanel}>Admin Panel</p>
            <p className={styles.login}>Login</p>
            <FormInput
              name={`email`}
              className={styles.input}
              labelKey="Email:"
              rule={[
                {
                  type: 'email',
                  message: 'The input is not valid email',
                },
                {
                  required: true,
                  message: 'Please input your email',
                },
              ]}>
              <Input className="" placeholder="Email" />
            </FormInput>
            <FormInput
              name={`password`}
              className={styles.input}
              labelKey="Password:"
              message="Password can’t be empty">
              <Input placeholder="Password" />
            </FormInput>

            <button className={styles.button} type={'submit'}>
              Login
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
