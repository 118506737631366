import {Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles((theme: Theme) => ({
  title: constStyles.title,
  block: {
    display: 'flex',
  },
  input: {
    width: '100%',
    paddingRight: '1rem !important',
    '& label': {
      paddingRight: '1rem !important',
      marginBottom: '2rem !important',
    },
  },
  input2: {
    width: '100%',
    paddingRight: '1rem !important',
    '& label': {
      paddingRight: '1rem !important',
    },
  },
  label: constStyles.labelDetails,
  customGradientButton: {
    appearance: 'none',
    height: '3rem',
    border: 'none',
    display: 'flex',
    fontSize: '1.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    width: '12rem',
    color: 'white',
    cursor: 'pointer',
    marginRight: '8px',
    marginTop: '1.5rem',

    '&:hover': {
      background: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.dark} 100%)`,
    },
  },
  borderWithImage: {
    position: 'absolute',
    border: '1px solid rgb(217, 217, 217)',
    width: '107px',
    height: '107px',
  },
  borderWithImage0: {
    position: 'relative',
    width: '107px',
    height: '107px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '&:hover > #border': {
      filter: 'brightness(0.5)',
      background: 'rgb(0,0,0,0.3)',
    },
    '&:hover': {
      '& #deleteBtn': {
        display: 'initial !important',
      },
      '& #redoBtn': {
        display: 'initial !important',
      },
    },
  },
  containerStyleJustImage: {
    width: '105px',
    height: '105px',
    position: 'relative',
    objectFit: 'contain',
  },
  deleteBtn: {
    position: 'relative',
    display: 'none !important',
    color: 'white !important',
  },
  redoBtn: {
    position: 'relative',
    display: 'none !important',
    color: 'white !important',
  },
  titleLiked: {
    fontWeight: 'bold',
  },
  divLiked: {
    width: '33%',
    padding: '1rem 2rem ',
    borderRight: '1px solid lightgray',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  container: {
    padding: '1rem 3rem 3rem',
    background: '#ffffff',
    display: 'flex',
    width: '100%',
  },
}));
