import React, {FC} from 'react';
import TreeSelector from 'components/UI/TreeSelector';

interface Props {
  styles: any;
  item: any;
  onChange: any;
  reduxFilters: any;
}

const RepeatedTreeSelector: FC<Props> = ({styles, item, onChange, reduxFilters}: Props) => {
  return (
    <div className={styles.borderOneFilter}>
      <div className={styles.label}>{item.title}</div>
      <TreeSelector
        treeData={item.values}
        width={'26rem'}
        handleChange={(arrayIds: any) => onChange(arrayIds, item.keySave)}
        defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
      />
    </div>
  );
};

export default RepeatedTreeSelector;
