import {Sorter} from 'store/classForListPage';

export interface OpportunitiesState {
  opportunities: Opportunity[];
  opportunitiesCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  searchName?: string;
  filters?: Filters;
}

export interface Filters {
  types: string[];
  sectors: string[];
  experiences: string[];
  languages: string[];
  company_id: string;
  location: string;
  degrees: string[];
}
export interface Opportunity {
  id: string;
  name: string;
  banner_img: string;
  company_id?: number;
  location: string;
  types: string[];
  degrees: string[];
  experiences: string[];
  languages: string[];
  description_en: string;
  description_fr: string;
  description_nl: string;
  main_description_en: string;
  main_description_fr: string;
  main_description_nl: string;
  text_en: string;
}

export enum OpportunitiesActionTypes {
  OPPORTUNITIES_SET_OPPORTUNITIES = 'OPPORTUNITIES_SET_OPPORTUNITIES',
  OPPORTUNITIES_SET_OPPORTUNITIES_COUNT = 'OPPORTUNITIES_SET_OPPORTUNITIES_COUNT',
  OPPORTUNITIES_SET_LIMIT = 'OPPORTUNITIES_SET_LIMIT',
  OPPORTUNITIES_SET_CURRENT_PAGE = 'OPPORTUNITIES_SET_CURRENT_PAGE',
  OPPORTUNITIES_SET_SORTING = 'OPPORTUNITIES_SET_SORTING',
  OPPORTUNITIES_SET_SEARCH_NAME = 'OPPORTUNITIES_SET_SEARCH_NAME',
  OPPORTUNITIES_SET_FILTERS = 'OPPORTUNITIES_SET_FILTERS',
}

export interface OpportunitiesSetOpportunities {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_OPPORTUNITIES;
  payload: Opportunity[];
}

export interface OpportunitiesSetOpportunitiesCount {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_OPPORTUNITIES_COUNT;
  payload: number;
}

export interface OpportunitiesSetCount {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_LIMIT;
  payload: number;
}

export interface OpportunitiesSetPage {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_CURRENT_PAGE;
  payload: number;
}

export interface OpportunitiesSetSorting {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_SORTING;
  payload: Sorter | undefined;
}
export interface OpportunitiesSetSearchName {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_SEARCH_NAME;
  payload: string | undefined;
}
export interface OpportunitiesSetFilters {
  type: OpportunitiesActionTypes.OPPORTUNITIES_SET_FILTERS;
  payload: Filters | undefined;
}
export type OpportunitiesActions =
  | OpportunitiesSetOpportunities
  | OpportunitiesSetOpportunitiesCount
  | OpportunitiesSetCount
  | OpportunitiesSetPage
  | OpportunitiesSetSorting
  | OpportunitiesSetSearchName
  | OpportunitiesSetFilters;
