import {Reducer} from 'redux';
import {produce} from 'immer';
import {EventActionTypes, EventDetailsActions, EventState} from './types';

export const initialState: EventState = {
  event: {
    id: '',
    name: '',
    banner_img: '',
    start_date: '',
    end_date: '',
    address: '',
    main_description_en: '',
    main_description_fr: '',
    main_description_nl: '',
    text_en: '',
    types: [],
  },
  id: null,
};

const eventDetailsReducer: Reducer<EventState, EventDetailsActions> = (state = initialState, action): EventState => {
  return produce(state, (draft: EventState) => {
    switch (action.type) {
      case EventActionTypes.EVENT_SET:
        draft.event = action.payload;
        return;

      case EventActionTypes.EVENT_SET_ID:
        draft.id = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default eventDetailsReducer;
