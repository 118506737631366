import React, {FC, useState} from 'react';
import {useHistory} from 'react-router';
import {Table} from 'antd';
import {StudentsActionsRedux} from 'store/students/actions';
import {ModalViewMessage} from 'components/Modals';

interface Props {
  columns: any;
  entity: any;
  onChangeTable: any;
}
const TableComponentMessages: FC<Props> = ({columns, entity, onChangeTable}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [textinModal, settextinModal] = useState('');

  const goToItem = (ids: any, messageId: any) => {
    StudentsActionsRedux.setEntities(ids);
    history.push(`students`, {ids, messageId});
  };

  const sorting = (_a: any, _b: any, sorter: any) => {
    const newOrder =
      sorter.order === undefined
        ? {order: undefined, orderType: undefined}
        : sorter.order === 'descend'
        ? {order: sorter.columnKey, orderType: 'desc'}
        : {order: sorter.columnKey, orderType: 'asc'};

    onChangeTable(newOrder);
  };
  const openPopupMessage = (text: any) => {
    setOpenModal(true);
    settextinModal(text);
  };
  return (
    <>
      <Table
        columns={columns(goToItem, openPopupMessage)}
        onChange={sorting}
        dataSource={entity}
        pagination={false}
        scroll={{y: '76vh'}}
      />
      <ModalViewMessage open={openModal} onClose={() => setOpenModal(false)} text={textinModal} />
    </>
  );
};
export default TableComponentMessages;
