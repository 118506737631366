/**
 * Notification util with react-toastify
 * https://www.npmjs.com/package/react-toastify
 */

import {toast} from 'react-toastify';
import {AxiosError} from 'axios';

export enum NotificationTypes {
  success,
  info,
  warning,
  error,
}

const options = {
  autoClose: 5000,
  hideProgressBar: false,
};
/**
 * Throws notification popup
 * @param notificationType given type of notification
 * @param message notification text content
 */
export const showNotification = (notificationType: NotificationTypes, message: string): any => {
  switch (notificationType) {
    case NotificationTypes.success:
      return toast.success(message, options);

    case NotificationTypes.info:
      return toast.info(message, options);

    case NotificationTypes.warning:
      return toast.warn(message, options);

    case NotificationTypes.error:
      return toast.error(message, options);

    default:
      return toast(message, options);
  }
};

/**
 * Shows error notification
 * @param {AxiosError} error
 */
export const showErrorNotification = (error: AxiosError): any => {
  if (error.isAxiosError) {
    console.log(error.response);
    const {status} = error.response as {status: number};
    
    if(status === 401){
      return toast.error('Access denied', options);
    } else if (error.response && error.response.data.statusCode && error.response.data.error) {
      return toast.error(`${error.response.data.statusCode}. ${error.response.data.error}`, options);
    } else if (error.response && error.response.data.message) {
      return toast.error(error.response.data.message, options);
    } else if (!!error.response && error.response.status === 500) {
      return toast.error('Server error', options);
    } else if (!!error.response && error.response.status === 502) {
      return toast.error('Server is not running', options);
    } else if (error.message) {
      if (window.location.hostname.includes('localhost')) {
        return toast.error('Server is not running', options);
      } else return toast.error(error.message, options);
    } else {
      console.error(error); // TODO: delete console
      return toast.error('Something went wrong', options);
    }
  } else {
    console.error(error); // TODO: delete console
    return toast.error('Server is not running', options);
  }
};
