import React, {useEffect} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Tooltip} from 'antd';
import {DeleteOutlined, RedoOutlined} from '@ant-design/icons';
import Cropper from 'react-easy-crop';

import {NotificationTypes, showNotification} from '../../../utils/notifications';
import {useStyles} from '../styles';

import CropperModal from './components/CropperModal';
import UploadButton from './components/UploadButton';

interface IUploadPhotosProps {
  onChange: any;
  onLoad: any;
  image: any;
  deleteImage: any;
  redoImage: any;
  title: string;
  aspectValue: any;
  tooltipText: string;
  hasCache: boolean;
}

const defaultProps = {
  crop: {x: 0, y: 0},
  zoom: 1,
};

const fakeFunc = () => false;

const UploadPhotos: React.FunctionComponent<IUploadPhotosProps> = ({
  hasCache,
  onChange,
  onLoad,
  image,
  deleteImage,
  redoImage,
  title,
  aspectValue,
  tooltipText,
}) => {
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const styles = useStyles();

  const onSelectFile = React.useCallback(
    (e: any) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        if (file.size <= 5000000) {
          const reader: any = new FileReader();
          reader.addEventListener('load', () => {
            onLoad(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
        } else {
          showNotification(NotificationTypes.info, 'Image size should be less than 5MB');
        }
      }
    },
    [onLoad],
  );

  const hideCropperModel = React.useCallback(() => {
    setPreviewVisible(false);
  }, []);

  const showCropperModel = React.useCallback(() => {
    setPreviewVisible(true);
  }, []);

  const onSaveCroppedImage = React.useCallback(
    (imageUrl) => {
      onChange(imageUrl);

      hideCropperModel();
    },
    [onChange, hideCropperModel],
  );

  const onRedo = React.useCallback(() => {
    redoImage();
  }, [redoImage]);

  const onDelete = React.useCallback(() => {
    deleteImage();
  }, [deleteImage]);
  useEffect(() => {
    if (!image?.includes('blob:http') && !image?.includes('https:') && image) {
      setPreviewVisible(true);
    }
  }, [image]);

  return (
    <div style={{marginRight: '10px', marginBottom: '10px'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{width: '8rem'}}>{title}</div>
        <Tooltip placement="top" title={tooltipText}>
          <Button
            type="text"
            style={{padding: '5px', display: 'flex', alignItems: 'center', height: '2rem', marginLeft: '1rem'}}>
            <span style={{fontSize: '10px'}}>ⓘ</span>
          </Button>
        </Tooltip>
      </div>
      <CropperModal
        visible={previewVisible}
        onCancel={hideCropperModel}
        onSave={onSaveCroppedImage}
        aspectValue={aspectValue}
        image={image}
      />
      {image !== '' && image !== null ? (
        image?.includes('blob:http') || image?.includes('https:') ? (
          <div className={styles.borderWithImage0}>
            <div className={styles.borderWithImage} id="border">
              <img src={image} className={styles.containerStyleJustImage} alt="item" />
            </div>
            {hasCache && <RedoOutlined className={styles.redoBtn} id="redoBtn" onClick={onRedo} />}
            <DeleteOutlined className={styles.deleteBtn} id="deleteBtn" onClick={onDelete} />
          </div>
        ) : (
          <div onClick={showCropperModel}>
            <Cropper
              image={image}
              crop={defaultProps.crop}
              zoom={defaultProps.zoom}
              aspect={aspectValue}
              onCropChange={fakeFunc}
              style={{
                containerStyle: {
                  width: '105px',
                  height: '105px',
                  position: 'relative',
                  marginRight: '10px',
                  marginBottom: '10px',
                },
              }}
            />
          </div>
        )
      ) : (
        (image === '' || image === null || image === undefined) && <UploadButton onChange={onSelectFile} />
      )}
    </div>
  );
};

export default UploadPhotos;
