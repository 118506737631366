import {makeStyles} from '@material-ui/styles';
import {constStyles} from '../materialThemes';

export const useStyles = makeStyles(() => {
  return {
    container: {
      padding: ' 0.2rem',
      '@media (max-width:1200px)': {
        padding: ' 0.2rem',
      },
    },
    label: {
      ...constStyles.labelDetails,
      marginBottom: '0.6rem',
    },
    input: {
      marginBottom: '1rem !important ',
    },
  };
});
