import React, {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
//Components
import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from '../styles';
import {setCurrentFilters} from 'containers/defaultFunc';
import {OpportunitiesActionsRedux} from 'store/opportunities/actions';
import SelectorWithSearch from 'components/UI/Selector';
import UIButtonsWrapper from './UIButtonsWrapper';
import RepeatedTreeSelector from './RepeatedTreeSelector';

interface Props {
  open: boolean;
  onClose: any;
  filters: any;
  optionsData: any;
  getOpportunities: any;
}

const ModalFiltersOpportunities: FC<Props> = ({onClose, open, filters, getOpportunities, optionsData}: Props) => {
  // Styling
  const styles = useStyles();
  const dispatch = useDispatch();
  const [filtersSelect, setfiltersSelect] = useState<any>([]);

  const {filters: reduxFilters}: any = useSelector((state: AppState) => state.opportunities);

  const onChange = (data: any, data1: any) => {
    setfiltersSelect({...filtersSelect, [data1]: data});
  };
  const onChangeSelectLocation = (_a: any, data: any) => {
    if (data) {
      setfiltersSelect({...filtersSelect, location: data.children});
    } else {
      setfiltersSelect({...filtersSelect, location: undefined});
    }
  };
  const onChangeSelectCompany = (_a: any, data: any) => {
    if (data) {
      setfiltersSelect({...filtersSelect, company_id: data.value.toString()});
    } else {
      setfiltersSelect({...filtersSelect, company_id: undefined});
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <div className={styles.modalFilters}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={styles.borderAllFilters}>
          {filters.map((item: any, id: number) => {
            if (id === filters.length - 1) {
              return (
                <>
                  <RepeatedTreeSelector styles={styles} item={item} onChange={onChange} reduxFilters={reduxFilters} />
                  <SelectorWithSearch
                    onChange={onChangeSelectCompany}
                    array={optionsData.companies}
                    value={'id'}
                    title={'name'}
                    reduxFilters={
                      optionsData.companies.filter((item: any) => {
                        return item.id.toString() === reduxFilters?.company_id;
                      })[0]?.name
                    }
                    label={'Company'}
                    styles={styles}
                  />
                </>
              );
            }

            return (
              <RepeatedTreeSelector
                key={id}
                styles={styles}
                item={item}
                onChange={onChange}
                reduxFilters={reduxFilters}
              />
            );
          })}
        </div>
        <UIButtonsWrapper
          styles={styles}
          onClickReset={() => {
            setCurrentFilters([], dispatch, OpportunitiesActionsRedux, onClose, getOpportunities);
            setfiltersSelect([]);
          }}
          onClickSetFilters={() => {
            setCurrentFilters(filtersSelect, dispatch, OpportunitiesActionsRedux, onClose, getOpportunities);
          }}
        />
      </div>
    </Dialog>
  );
};

export default ModalFiltersOpportunities;
