import React, {ReactNode, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loader from 'components/UI/Loader';
import {AppState} from 'store';
import {bootstrap} from 'store/session/actions';

type Props = {
  children?: ReactNode;
};

const App: React.FunctionComponent = (props: Props) => {
  const isLoading = useSelector((state: AppState) => state.ui.isLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bootstrap());
  }, [dispatch]);
  return (
    <>
      {isLoading && <Loader />}
      {props.children}
    </>
  );
};

export default App;
