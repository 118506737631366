import React, {FC, ReactElement, Suspense} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from 'store';
import App from 'components/App/App';
import {RouteObj, routes} from './routes';
import * as storage from 'utils/storage';

import Login from 'containers/Login';

import Companies from 'containers/Companies';
import CompanyPage from 'containers/CompanyPage';

import Opportunities from 'containers/Opportunities';
import OpportunityPage from 'containers/OpportunityPage';

import Events from 'containers/Events';
import EventPage from 'containers/EventPage';

import Students from 'containers/Students';
import StudentDetails from 'containers/StudentDetails';

import Messages from 'containers/MessagesHistory';

import ReferralCodes from 'containers/ReferralCodes';

import Page404 from 'containers/Page404';

const RouterComponent: FC = (): ReactElement | null => {
  const sessionLoading = useSelector((state: AppState) => state.session.loading);
  const [tokens, setTokens] = React.useState<string | null>(null);

  React.useEffect(() => {
    const tokens = storage.getTokens();

    if (tokens) {
      setTokens(tokens);
    }
  }, []);

  return (
    <BrowserRouter>
      <App>
        {sessionLoading ? <></> : null}
        {!sessionLoading && tokens ? (
          <Suspense fallback={<></>}>
            <Switch>
              <Route exact={true} path={['/companies']}>
                <Companies />
              </Route>
              <Route exact={true} path={['/create-company']}>
                <CompanyPage />
              </Route>
              <Route exact={true} path={['/companies/details']}>
                <CompanyPage />
              </Route>
              <Route exact={true} path={['/opportunities']}>
                <Opportunities />
              </Route>
              <Route exact={true} path={['/opportunities/details']}>
                <OpportunityPage />
              </Route>
              <Route exact={true} path={['/create-opportunity']}>
                <OpportunityPage />
              </Route>

              <Route exact={true} path={['/events']}>
                <Events />
              </Route>
              <Route exact={true} path={['/events/details']}>
                <EventPage />
              </Route>
              <Route exact={true} path={['/create-event']}>
                <EventPage />
              </Route>

              <Route exact={true} path={['/students']}>
                <Students />
              </Route>
              <Route exact={true} path={['/students/details']}>
                <StudentDetails />
              </Route>

              <Route exact={true} path={['/messages-history']}>
                <Messages />
              </Route>

              <Route exact={true} path={['/referrals']}>
                <ReferralCodes />
              </Route>

              <Route exact={true} path={['/']}>
                <Redirect from="/" to="/companies" />
              </Route>
              <Suspense fallback={<></>}>
                <Route component={Page404} />
              </Suspense>
            </Switch>
          </Suspense>
        ) : null}
        {!sessionLoading && !tokens ? (
          <Suspense fallback={<></>}>
            <Switch>
              <Route exact={true} path={['/']}>
                <Login />
              </Route>

              {/* {routes.map((route: RouteObj, index) => {
                return <Redirect key={index} from={route.to} to="/" />;
              })}
              <Redirect from="/companies" to="/" /> */}

              <Suspense fallback={<></>}>
                <Route component={Login} />
              </Suspense>
            </Switch>
          </Suspense>
        ) : null}
      </App>
    </BrowserRouter>
  );
};

export const Router = React.memo(RouterComponent);
