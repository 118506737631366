import React, {FC, ReactElement} from 'react';
import {useStyles} from './styles';
import {Button, Form, Tooltip} from 'antd';

interface Props {
  labelKey?: string;
  defaultValue?: string;
  name?: string;
  children?: any;
  className?: string;
  placeholder?: string;
  message?: string;
  rule?: any;
  rules?: any;
  tooltipText?: string;
}

const FormInput: FC<Props> = ({
  labelKey,
  name,
  className,
  children,
  message,
  rule,
  rules,
  tooltipText,
}: Props): ReactElement => {
  const styles = useStyles();
  const containerClasses = [styles.container, '', className].join(' ');

  const renderInput = (): ReactElement => {
    return (
      <Form.Item
        name={name}
        className={className}
        rules={
          rules
            ? rules
            : message !== undefined
            ? rule !== undefined
              ? [
                  {
                    required: true,
                    message: message,
                  },
                  rule,
                ]
              : [
                  {
                    required: true,
                    message: message,
                  },
                ]
            : rule !== undefined
            ? rule
            : []
        }>
        {children}
      </Form.Item>
    );
  };
  return (
    <div className={containerClasses}>
      {labelKey ? (
        <>
          <div className={styles.label}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {labelKey}{' '}
              {tooltipText && (
                <Tooltip placement="top" title={tooltipText}>
                  <Button
                    type="text"
                    style={{padding: '5px', display: 'flex', alignItems: 'center', height: '2rem', marginLeft: '1rem'}}>
                    <span style={{fontSize: '10px'}}>ⓘ</span>
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          {renderInput()}
        </>
      ) : (
        renderInput()
      )}
    </div>
  );
};

export default FormInput;
