import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: constStyles.mainWrapper,
  block: {
    width: '75rem',
    height: '47rem',
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  left: {
    minWidth: '30rem',
    width: '35%',
    background: 'white',
    border: ' 1px solid gray',
    height: '100%',
    padding: '12rem 5rem',
  },
  borderLogo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  right: {
    height: '100%',
    width: '65%',
    background: 'white',
    padding: '5rem',
  },
  button: {
    appearance: 'none',
    height: '3rem',
    border: 'none',
    display: 'flex',
    fontSize: '1.3rem',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#959595',
    color: 'white',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  input: {
    width: '100%',
    marginBottom: '2rem',
    '& label': {
      marginBottom: '2rem !important',
    },
  },
  label: constStyles.labelDetails,
  adminPanel: {
    color: theme.palette.primary.dark,
  },
  login: {
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
  },
}));
