import React, {FC} from 'react';
//Components
import {Input} from 'antd';
import FormInput from 'components/UI/FormInput';
import RichText from 'components/UI/RichText';
//Styling
import {useStyles} from '../styles';

//Functions, utils
import {NotificationTypes, showNotification} from 'utils/notifications';
import Selector from 'components/UI/Selector/SelectorFormInput';
import TreeSelector from 'components/UI/TreeSelector/TreeSelectorFormInput';
import PromoBlock from '../PromoBlock';

interface Props {
  richArea: any;
  dataForSelectors: any;
  forCalendar: any;
  promo: any;
}

const DetailsBlock2: FC<Props> = ({richArea, dataForSelectors, forCalendar, promo}) => {
  const styles = useStyles();
  const handleChange = (value: any) => {
    if (value.length > 3) {
      showNotification(NotificationTypes.info, 'Perk max length is 3');
    }
  };

  return (
    <>
      <PromoBlock forCalendar={forCalendar} promo={promo} />
      <div className={styles.title}>Socials</div>
      <div style={{display: 'flex'}}>
        <FormInput name={`Facebook`} className={styles.input} labelKey={`Facebook:`}>
          <Input placeholder={`Facebook`} />
        </FormInput>
        <FormInput name={`Instagram`} className={styles.input} labelKey={`Instagram:`}>
          <Input placeholder={`Instagram`} />
        </FormInput>
        <FormInput name={`Twitter`} className={styles.input} labelKey={`Twitter:`}>
          <Input placeholder={`Twitter`} />
        </FormInput>
        <FormInput name={`Linkedin`} className={styles.input} labelKey={`Linkedin:`}>
          <Input placeholder={`Linkedin`} />
        </FormInput>
      </div>
      <Selector
        array={dataForSelectors.sectors}
        value={'value'}
        title={'title'}
        styles={styles.input}
        name={'sectors'}
        label={'Sector'}
      />
      <TreeSelector array={dataForSelectors.emojis} name={'emoji'} label={'Company perks'} onChange={handleChange} />
      <RichText
        value={richArea.en.value}
        labelKey="Additional Info EN"
        required={true}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
      <RichText
        value={richArea.fr.value}
        labelKey="Additional Info FR"
        required={true}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
      <RichText
        value={richArea.nl.value}
        labelKey="Additional Info NL"
        required={true}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
    </>
  );
};
export default DetailsBlock2;
