import React, {FC, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {RouteObj, routes} from 'Router/routes';
import {AppState} from 'store';
//Styling
import {useStyles} from './styles';
//Actions
import {uIToggleDrawer} from 'store/ui/actions';
import {logout} from 'store/session/actions';
import {uISetActiveTab} from 'store/ui/actions';
//Components
import Icon from 'components/UI/Icon';

const DrawerMenu: FC = () => {
  // Styling
  const styles = useStyles();

  // Redux
  const dispatch = useDispatch();
  const activeTab = useSelector((state: AppState) => state.ui.activeTab);
  const history = useHistory();

  useEffect(() => {
    dispatch(uISetActiveTab('/' + window.location.pathname.split('/')[1]));
  }, [dispatch]);

  const handleMenuClick = (to: string): void => {
    dispatch(uISetActiveTab(to));
    dispatch(uIToggleDrawer(false));

    setTimeout(() => {
      if(history.location.pathname === to){
        history.go(0);
      } else {
        history.push(to);
      }
    }, 200);
  };
  const handleLogout = (): void => {
    setTimeout(() => {
      dispatch(logout());
    }, 200);
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Icon name="logo" />
      </div>
      {routes.map((route: RouteObj, index: number) => {
        return (
          <div
            key={index}
            className={activeTab === route.to ? [styles.menuButton, styles.activeButton].join(' ') : styles.menuButton}
            onClick={(): void => {
              handleMenuClick(route.to);
            }}>
            {route.labelKey}
          </div>
        );
      })}
      <div className={styles.menuButton} onClick={() => handleLogout()}>
        <Icon name={'logout'} styles={{marginRight: '2rem'}} />
        Log Out
      </div>
    </div>
  );
};

export default DrawerMenu;
