import {makeStyles} from '@material-ui/styles';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles(() => ({
  block: {
    display: 'flex',
  },
  input2: {
    width: '100%',
    paddingRight: '0.5rem !important',
    '& label': {
      marginBottom: '2rem !important',
    },
  },
  label: constStyles.labelDetails,
}));
