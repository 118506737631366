import React, {FC} from 'react';

import moment from 'moment';
import {Select} from 'antd';
import FormInput from 'components/UI/FormInput';
import {Referral} from 'store/referrals/types';

import {useStyles} from '../styles';

const {Option} = Select;

interface Props {
  student: any;
  referrals: Referral[];
  fetchMoreReferrals: () => void;
  updateReferral: (value: string) => void;
  clearReferral: () => void;
}

const DetailsBlock: FC<Props> = ({student, referrals, fetchMoreReferrals, updateReferral, clearReferral}) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.borderWithImage}>
        <img src={student.image_url} className={styles.containerStyleJustImage} alt="photo_user" />
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Username:
          <div>{student.username}</div>
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          First name:
          <div>{student.first_name}</div>
        </label>
        <label className={styles.label}>
          Last name:
          <div>{student.last_name}</div>
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Sex:
          <div>{student.sex?.name}</div>
        </label>
        <label className={styles.label}>
          Birthday:
          {student.birthday && <div>{moment(student.birthday).format('YYYY-MM-DD')}</div>}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Phone number:
          <div>{student.phone_number}</div>
        </label>
        <label className={styles.label}>
          Email:
          <div>{student.email}</div>
        </label>
      </div>

      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Referral code:
          <FormInput key={`referrer-${student.referrer}`} name={'referrer'}>
            <Select
              defaultValue={student.referrer}
              showSearch
              filterOption={false}
              allowClear={true}
              onSelect={updateReferral}
              onClear={clearReferral}
              onPopupScroll={(e) => {
                if ((e.target as any).scrollHeight - (e.target as any).scrollTop < 257) {
                  fetchMoreReferrals();
                }
              }}>
              {referrals?.map((item) => (
                <Option key={item.code} value={item.code}>
                  {item.code}
                </Option>
              ))}
            </Select>
          </FormInput>
        </label>
        <label className={styles.label}>
          Contact email:
          <div>{student.contact_email}</div>
        </label>
      </div>

      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Country:
          <div>{student.country}</div>
        </label>
        <label className={styles.label}>
          City:
          <div>{student.city}</div>
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Provider:
          <div>{student.provider}</div>
        </label>
        <label className={styles.label}>
          Request language:
          <div>{student.request_language}</div>
        </label>
      </div>

      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Short bio:
          <div>{student.short_bio}</div>
        </label>
      </div>
    </>
  );
};
export default DetailsBlock;
