import {Reducer} from 'redux';
import {produce} from 'immer';
import {StudentActionTypes, StudentDetailsActions, StudentState} from './types';

const initialState: StudentState = {
  student: {
    id: '',
    email: '',
    phone_number: '',
    username: '',
    first_name: '',
    last_name: '',
    birthday: '',
    country: '',
    city: '',
    provider: '',
    request_language: [],
    institutes: [],
    international_experiences: [],
    work_experiences: [],
    education_level: [],
    achievements: [],
    activities: [],
    languages: [],
    created: '',
    short_bio: '',
    social_media: [],
    image_url: '',
    referrer: '',
    contact_email: '',
  },
  id: null,
};

const studentDetailsReducer: Reducer<StudentState, StudentDetailsActions> = (
  state = initialState,
  action,
): StudentState => {
  return produce(state, (draft: StudentState) => {
    switch (action.type) {
      case StudentActionTypes.STUDENT_SET:
        draft.student = action.payload;
        return;

      case StudentActionTypes.STUDENT_SET_ID:
        draft.id = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default studentDetailsReducer;
