import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Checkbox} from 'antd';
import {useStyles} from './styles';
import {GET_ALL_STUDENTS_IDS, GET_LIKED_STUDENTS_IDS, GET_WITH_MESSAGE_STUDENTS_IDS} from '../../requests/queries';
import {useDispatch, useSelector} from 'react-redux';
import {setSelectedRowKeys} from 'store/students/actions';
import {AppState} from '../../store';

interface Props {
  children?: ReactNode;
  buttonTop?: any;
}

const SelectAll: FC<Props> = () => {
  // Styling
  const styles = useStyles();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const {filters, searchName}: any = useSelector((state: AppState) => state.students);

  const {studentsCount, selectedRowKeys, selectedRowKeysWithMessages, messageId = '', likedEntity}: any = useSelector(
    (state: AppState) => state.students,
  );

  const {data: dataAll, refetch: refetchAll} = useQuery(GET_ALL_STUDENTS_IDS, {
    variables: {
      page: 1,
      filters: {
        archetype: filters?.archetype || undefined,
        achievements: (filters?.achievements?.length > 0 && filters?.achievements) || undefined,
        activities: (filters?.activities?.length > 0 && filters?.activities) || undefined,
        work_experiences: (filters?.work_experiences?.length > 0 && filters?.work_experiences) || undefined,
        international_experiences:
        (filters?.international_experiences?.length > 0 && filters?.international_experiences) || undefined,
        archetypes: (filters?.archetypes?.length > 0 && filters?.archetypes) || undefined,
        institutes: (filters?.institutes?.length > 0 && [filters?.institutes]) || undefined,
        education_level: (filters?.education_level?.length > 0 && filters?.education_level) || undefined,
        education_subject: (filters?.education_subject?.length > 0 && filters?.education_subject) || undefined,
        city: filters?.city || undefined,
        country: filters?.country || undefined,
        grades: (filters?.grades?.length > 0 && filters?.grades) || undefined,
        degrees: (filters?.degrees?.length > 0 && filters?.degrees) || undefined,
        interests: (filters?.interests?.length > 0 && filters?.interests) || undefined,
        languages: (filters?.languages?.length > 0 && filters?.languages) || undefined,
        username: searchName,
      },
      limit: studentsCount,
    },
    fetchPolicy: 'standby',
  });

  const {data: dataWithMessage, refetch: refetchWithMessage} = useQuery(GET_WITH_MESSAGE_STUDENTS_IDS, {
    variables: {
      page: 1,
      limit: studentsCount,
      filters: {
        id: messageId,
        account_ids: selectedRowKeysWithMessages && selectedRowKeysWithMessages?.map((item: any) => parseInt(item)),
      },
    },
    fetchPolicy: 'standby',
  });

  const {data: dataLiked, refetch: refetchLiked} = useQuery(GET_LIKED_STUDENTS_IDS, {
    variables: {
      page: 1,
      limit: studentsCount,
      id: likedEntity.id,
      type: likedEntity.type,
    },
    fetchPolicy: 'standby',
  });

  const onChangeHandler = React.useCallback(
    (e: any) => {
      const refetch = async () => {
        if (e.target.checked) {
          if (messageId) {
            await refetchWithMessage();
          } else if (likedEntity?.id) {
            await refetchLiked();
          } else {
            await refetchAll();
          }
        } else {
          dispatch(setSelectedRowKeys([]));
        }
        setSelected(!selected);
      };

      refetch();
    },
    [refetchWithMessage, refetchLiked, refetchAll, dispatch, messageId, likedEntity, selected],
  );

  useEffect(() => {
    if (dataAll && selected) {
      dispatch(setSelectedRowKeys(dataAll.studentIds.result.map((item: any) => item.id)));
    }
  }, [dataAll, selected, dispatch]);

  useEffect(() => {
    if (dataWithMessage && selected) {
      dispatch(
        setSelectedRowKeys(dataWithMessage.studentsWithMessage.result.map((item: any) => item.account_id.toString())),
      );
    }
  }, [dataWithMessage, selected, dispatch]);

  useEffect(() => {
    if (dataLiked && selected) {
      dispatch(setSelectedRowKeys(dataLiked.studentsWhichLiked.result.map((item: any) => item.id)));
    }
  }, [dataLiked, selected, dispatch]);

  useEffect(() => {
    setSelected(selectedRowKeys.length === studentsCount);
  }, [selectedRowKeys.length, studentsCount]);

  return (
    <div className={styles.component}>
      <Checkbox disabled={!studentsCount} checked={selected} onChange={onChangeHandler} />
      <div className={styles.title}>Select All</div>
    </div>
  );
};

export default SelectAll;
