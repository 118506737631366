import {Dispatch} from 'redux';
import * as storage from 'utils/storage';
import {SessionActionTypes, SessionActions} from 'store/session/types';
import {Credentials} from 'api/models';
import {apiLogin} from 'api';
import {AxiosResponse, AxiosError} from 'axios';
import {saveSession} from 'utils/storage';

import {showErrorNotification} from 'utils/notifications';
import {uILoadingFinish, uILoadingStart} from 'store/ui/actions';
/**
 * Logs the user out and clears a stored data
 */
export function logout() {
  return (dispatch: Dispatch): void => {
    storage.removeSession();
    dispatch({type: SessionActionTypes.REMOVE_SESSION});
    window.open('/', '_self');
  };
}

/**
 * Sets session tokens
 * @param tokens new session tokens
 */
export function setToken(token: string): SessionActions {
  return {
    type: SessionActionTypes.SET_TOKENS,
    payload: token,
  };
}

export function login({email, password}: {email: string; password: string}): any {
  return async (dispatch: Dispatch) => {
    dispatch(uILoadingStart());
    const login: string = email.trim();
    dispatch({type: SessionActionTypes.LOGIN_START});

    const data: Credentials = {
      login,
      password,
    };
    try {
      const loginRes: AxiosResponse<any> = await apiLogin(data);
      if (loginRes && loginRes.data) {
        saveSession(loginRes.data.token);
        dispatch(setToken(loginRes.data.token));
        return loginRes.data;
      }
    } catch (err) {
      const error = err as AxiosError;

      showErrorNotification(error);
      return (error.response && error.response.data) || null;
    } finally {
      dispatch(uILoadingFinish());
    }
    return null;
  };
}

export function bootstrap(): any {
  return async (dispatch: any): Promise<void> => {
    const data: string = storage.getTokens();
    if (data) {
      dispatch(uILoadingStart());
      dispatch({type: SessionActionTypes.SESSION_LOADING_START});
      dispatch({type: SessionActionTypes.BOOTSTRAP_START});
      try {
        if (data !== null) {
          await dispatch(setToken(data));
          await dispatch({type: SessionActionTypes.BOOTSTRAP_SUCCESS});
        } else {
          dispatch({type: SessionActionTypes.BOOTSTRAP_REJECT});
          await dispatch(logout());
        }
      } catch (error) {
        dispatch({type: SessionActionTypes.BOOTSTRAP_FAIL});
        await dispatch(logout());
      } finally {
        dispatch(uILoadingFinish());
        dispatch({type: SessionActionTypes.SESSION_LOADING_FINISH});
      }
    }
  };
}
