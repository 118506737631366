import {Reducer} from 'redux';
import {produce} from 'immer';
import {CompaniesActionTypes, CompaniesActions, CompaniesState} from './types';

const initialState: CompaniesState = {
  companiesCount: 0,
  companies: [],
  currentPage: 1,
  limit: 20,
};

const companiesReducer: Reducer<CompaniesState, CompaniesActions> = (state = initialState, action): CompaniesState => {
  return produce(state, (draft: CompaniesState) => {
    switch (action.type) {
      case CompaniesActionTypes.COMPANIES_SET_COMPANIES:
        draft.companies = action.payload;
        return;

      case CompaniesActionTypes.COMPANIES_SET_COMPANIES_COUNT:
        draft.companiesCount = action.payload;
        return;

      case CompaniesActionTypes.COMPANIES_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case CompaniesActionTypes.COMPANIES_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case CompaniesActionTypes.COMPANIES_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case CompaniesActionTypes.COMPANIES_SET_SEARCH_NAME:
        draft.searchName = action.payload;
        return;
      case CompaniesActionTypes.COMPANIES_SET_FILTERS:
        draft.filters = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default companiesReducer;
