import React, {FC, useState} from 'react';
//GraphQl
import {useMutation} from '@apollo/client';
import {SYNC_EVENT, SYNC_COMPANY, SYNC_OPPORTUNITY, SYNC_STUDENT} from 'requests/mutations';
//Components
import Dialog from '@material-ui/core/Dialog';
import {Button, Form, Radio} from 'antd';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from './styles';
//Functions, utils
import {NotificationTypes, showNotification} from 'utils/notifications';
import SuccessModal from './SuccessModal';

interface Props {
  open: boolean;
  onClose: any;
  options: any;
  entity: string;
}

const ModalSynchronize: FC<Props> = ({onClose, open, options, entity}: Props) => {
  // Styling
  const styles = useStyles();
  const [form] = Form.useForm();

  const [isSuccessModal, setisSuccessModal] = useState(false);
  const [active, setActive] = useState('');
  const [syncOpportunity] = useMutation(SYNC_OPPORTUNITY);
  const [syncStudent] = useMutation(SYNC_STUDENT);
  const [syncCompany] = useMutation(SYNC_COMPANY);
  const [syncEvent] = useMutation(SYNC_EVENT);

  const onSubmit = async () => {
    if (active === '') {
      showNotification(NotificationTypes.info, 'Please, choose synchronization type');
    } else {
      switch (entity) {
        case 'students':
          {
            const response = await syncStudent({
              variables: {
                field: active,
              },
            });
            if (response.data.refreshStudentData) {
              setisSuccessModal(true);
            } else {
              showNotification(NotificationTypes.error, 'Something went wrong, data was not synchronized');

              onClose();
            }
          }
          break;
        case 'opportunities':
          {
            const response = await syncOpportunity({
              variables: {
                field: active,
              },
            });
            if (response.data.refreshOpportunityData) {
              setisSuccessModal(true);
            } else {
              showNotification(NotificationTypes.error, 'Something went wrong, data was not synchronized');
              onClose();
            }
          }
          break;
        case 'events':
          {
            const response = await syncEvent({
              variables: {
                field: active,
              },
            });
            if (response.data.refreshEventData) {
              setisSuccessModal(true);
            } else {
              showNotification(NotificationTypes.error, 'Something went wrong, data was not synchronized');
              onClose();
            }
          }
          break;
        default:
          {
            const response = await syncCompany({
              variables: {
                field: active,
              },
            });
            if (response.data.refreshCompanyData) {
              setisSuccessModal(true);
            } else {
              showNotification(NotificationTypes.error, 'Something went wrong, data was not synchronized');
              onClose();
            }
          }
          break;
      }
    }
  };
  const onChange1 = (e: any) => {
    setActive(e.target.value);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.modalSyncBlock}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <Radio.Group
          options={options}
          style={{margin: '1rem', display: 'flex', flex: '0 50%', flexWrap: 'wrap'}}
          onChange={onChange1}
          value={active}
        />

        <Form form={form} onFinish={onSubmit}>
          <div className={styles.buttonsWrapperFilters2}>
            <Button type="primary" className={styles.button1} htmlType="submit">
              Synchronize
            </Button>
          </div>
        </Form>
      </div>
      <SuccessModal
        onClose={() => {
          setisSuccessModal(false);
          onClose();
        }}
        open={isSuccessModal}
        messageKey="Data was synchronized"
      />
    </Dialog>
  );
};

export default ModalSynchronize;
