import React, {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
//Components
import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from '../styles';
import {CompaniesActionsRedux} from 'store/companies/actions';
import {setCurrentFilters} from 'containers/defaultFunc';
import SelectorWithSearch from 'components/UI/Selector';
import TreeSelector from 'components/UI/TreeSelector';
import UIButtonsWrapper from './UIButtonsWrapper';

interface Props {
  open: boolean;
  onClose: any;
  filters: any;
  optionsData: any;
  getCompany: any;
}

const ModalFiltersCompany: FC<Props> = ({onClose, open, filters, optionsData, getCompany}: Props) => {
  // Styling
  const styles = useStyles();
  const [filtersSelect, setfiltersSelect] = useState<any>([]);
  const dispatch = useDispatch();

  const {filters: reduxFilters}: any = useSelector((state: AppState) => state.companies);
  const onChange = (data: any, data1: any) => {
    setfiltersSelect({...filtersSelect, [data1]: data});
  };
  const onChangeSelectCity = (_a: any, data: any) => {
    if (data) {
      setfiltersSelect({...filtersSelect, city: data.children});
    } else {
      setfiltersSelect({...filtersSelect, city: undefined});
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <div className={styles.modalFilters}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={styles.borderAllFilters}>
          {filters.map((item: any, id: number) => {
            // if (id === 0) {
            //   return (
            //     <>
            //       <div style={{display: 'flex'}}>
            //         <SelectorWithSearch
            //           onChange={onChangeSelectCity}
            //           array={optionsData.cities}
            //           value={'id'}
            //           title={'name'}
            //           reduxFilters={reduxFilters?.city}
            //           label={'City'}
            //           styles={styles}
            //         />
            //       </div>
            //       <div key={id} className={styles.borderOneFilter}>
            //         <div className={styles.label}>{item.title}</div>
            //         <TreeSelector
            //           treeData={item.values}
            //           width={'25rem'}
            //           handleChange={(id: any) => onChange(id, item.keySave)}
            //           defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
            //         />
            //       </div>
            //     </>
            //   );
            // }

            return (
              <div key={id} className={styles.borderOneFilter}>
                <div className={styles.label}>{item.title}</div>
                <TreeSelector
                  treeData={item.values}
                  width={'26rem'}
                  handleChange={(id: any) => onChange(id, item.keySave)}
                  defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
                />
              </div>
            );
          })}
        </div>
        <UIButtonsWrapper
          styles={styles}
          onClickReset={() => {
            setCurrentFilters([], dispatch, CompaniesActionsRedux, onClose, getCompany);
            setfiltersSelect([]);
          }}
          onClickSetFilters={() => {
            setCurrentFilters(filtersSelect, dispatch, CompaniesActionsRedux, onClose, getCompany);
          }}
        />
      </div>
    </Dialog>
  );
};

export default ModalFiltersCompany;
