import { Reducer } from 'redux';
import { produce } from 'immer';
import { UIActionTypes, UIActions, UIState } from './types';

export const initialState: UIState = {
  isLoading: false,
  activeTab: '/',
  isDrawerOpened: false,
};

const loadingReducer: Reducer<UIState, UIActions> = (state = initialState, action): UIState => {
  return produce(state, (draft: UIState) => {
    switch (action.type) {
      case UIActionTypes.UI_LOADING_START:
        draft.isLoading = true;
        return;

      case UIActionTypes.UI_LOADING_FINISH:
        draft.isLoading = false;
        return;
      case UIActionTypes.UI_SET_ACTIVE_TAB:
        draft.activeTab = action.payload;
        return;
      case UIActionTypes.UI_TOGGLE_DRAWER:
        if (action.payload === undefined) {
          draft.isDrawerOpened = !state.isDrawerOpened;
        } else {
          draft.isDrawerOpened = action.payload || false;
        }
        return;
      default:
        return state;
    }
  });
};

export default loadingReducer;
