import * as yup from 'yup';

export const email = yup.string().email().required().label('Email');

export const loginParametersSchema = yup.object().shape({
  email: email,
  password: yup.string().required().label('Password'),
});

export const emailRegex = /^[a-zA-Z0-9.+^_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
