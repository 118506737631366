import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '25rem',
    maxWidth: '25rem',
    position: 'sticky',
    height: '100vh',
    background: '#F65532',
    '@media (max-width:920px)': {
      minWidth: '20rem',
      maxWidth: '20rem',
    },
  },

  menuButton: {
    transition: 'background 0.3s',
    appearance: 'none',
    background: 'none',
    border: 'none',
    display: 'flex',
    fontFamily: 'SF-Pro-Display-Regular',
    width: '100%',
    padding: '0 3rem',
    height: '6rem',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    fontSize: '1.6rem',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:last-child': {
      marginTop: 'auto',
      marginBottom: '2rem',
    },
  },
  activeButton: {
    background: theme.palette.primary.dark,
  },
  logo: {
    display: 'flex',
    padding: '2rem',
    paddingBottom: '5rem',
    justifyContent: 'center',
  },
}));
