export interface MessagesState {
  messages: Message[];
  messagesCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  filters: Filters;
  searchOpp?: string;
  searchEv?: string;
  searchCom?: string;
}

export interface Sorter {
  order: string;
  orderType: string;
}
interface Filters {
  is_automated: boolean;
  students?: any;
}

interface Message {
  id: number;
  name: string;
  company_name: string;
  logo: string;
  created: string;
  banner_img: string;
  message_type: string;
  entity_id: string;
  message_created: string;
  text: string;
}

export enum MessagesActionTypes {
  MESSAGES_SET_MESSAGES = 'MESSAGES_SET_MESSAGES',
  MESSAGES_SET_MESSAGES_COUNT = 'MESSAGES_SET_MESSAGES_COUNT',
  MESSAGES_SET_LIMIT = 'MESSAGES_SET_LIMIT',
  MESSAGES_SET_CURRENT_PAGE = 'MESSAGES_SET_CURRENT_PAGE',
  MESSAGES_SET_SORTING = 'MESSAGES_SET_SORTING',
  MESSAGES_SET_FILTERS = 'MESSAGES_SET_FILTERS',
  MESSAGES_SET_SEARCH_OPPORTUNITIES = 'MESSAGES_SET_SEARCH_OPPORTUNITIES',
  MESSAGES_SET_SEARCH_COMPANIES = 'MESSAGES_SET_SEARCH_COMPANIES',
  MESSAGES_SET_SEARCH_EVENTS = 'MESSAGES_SET_SEARCH_EVENTS',
}

export interface MessagesSetMessages {
  type: MessagesActionTypes.MESSAGES_SET_MESSAGES;
  payload: Message[];
}

export interface MessagesSetMessagesCount {
  type: MessagesActionTypes.MESSAGES_SET_MESSAGES_COUNT;
  payload: number;
}

export interface MessagesSetCount {
  type: MessagesActionTypes.MESSAGES_SET_LIMIT;
  payload: number;
}

export interface MessagesSetPage {
  type: MessagesActionTypes.MESSAGES_SET_CURRENT_PAGE;
  payload: number;
}

export interface MessagesSetSorting {
  type: MessagesActionTypes.MESSAGES_SET_SORTING;
  payload: Sorter | undefined;
}
export interface MessagesSetFilters {
  type: MessagesActionTypes.MESSAGES_SET_FILTERS;
  payload: Filters;
}
export interface MessagesSetSearchCompanies {
  type: MessagesActionTypes.MESSAGES_SET_SEARCH_COMPANIES;
  payload: string | undefined;
}
export interface MessagesSetSearchEvents {
  type: MessagesActionTypes.MESSAGES_SET_SEARCH_EVENTS;
  payload: string | undefined;
}
export interface MessagesSetSearchOpportunities {
  type: MessagesActionTypes.MESSAGES_SET_SEARCH_OPPORTUNITIES;
  payload: string | undefined;
}
export type MessagesActions =
  | MessagesSetMessages
  | MessagesSetMessagesCount
  | MessagesSetCount
  | MessagesSetPage
  | MessagesSetSorting
  | MessagesSetFilters
  | MessagesSetSearchCompanies
  | MessagesSetSearchEvents
  | MessagesSetSearchOpportunities;
