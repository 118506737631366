/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from 'store';
import {Input} from 'antd';
//Actions
import {StudentsActionsRedux, setSelectedRowKeys, setSelectedRowKeysOnDiffPages} from 'store/students/actions';
//GraphQl
import {useQuery} from '@apollo/client';
import {GET_AVAILABLE_FILTERS_FOR_STUDENTS, GET_AVAILABLE_SUBJECTS} from 'requests/queries';
//Components
import Dialog from '@material-ui/core/Dialog';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from '../styles';
import {setCurrentFilters} from 'containers/defaultFunc';
import SelectorWithSearch from 'components/UI/Selector';
import TreeSelector from 'components/UI/TreeSelector';
import UIButtonsWrapper from './UIButtonsWrapper';

interface Props {
  open: boolean;
  onClose: any;
  getStudents: any;
  listWithMessages: any;
}

const ModalFiltersStudents: FC<Props> = ({onClose, open, getStudents, listWithMessages}: Props) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const {filters: reduxFilters}: any = useSelector((state: AppState) => state.students);

  const [filtersSelect, setFiltersSelect] = useState<any>({});
  const [subjects, setSubjects] = useState<any>([]);
  const [filtersCurrent, setfiltersCurrent] = useState<any>([]);

  const [countryCity, setCountryCity] = useState<any>({
    countries: [],
    cities: [],
    institutes: [],
    grades: [],
    education_subject: [],
  });
  const [availableUnSubjects, setAvailableUnSubjects] = useState([]);
  const [universityID, setUniversityID] = useState(undefined);

  const {data: availableFiltersData} = useQuery(GET_AVAILABLE_FILTERS_FOR_STUDENTS, {
    skip: !open,
  });
  const {data: universitySubjectsData} = useQuery(GET_AVAILABLE_SUBJECTS, {
    variables: {institute_id: universityID},
    skip: universityID === undefined,
  });

  const cachedAvailableFiltersData = React.useMemo(() => availableFiltersData, [availableFiltersData]);
  const cachedUniversitySubjectsData = React.useMemo(() => universitySubjectsData, [universitySubjectsData]);

  useEffect(() => {
    if (cachedUniversitySubjectsData && universityID) {
      setAvailableUnSubjects(cachedUniversitySubjectsData.availableEducationalSubjects);
    }
  }, [cachedUniversitySubjectsData]);

  useEffect(() => {
    if (cachedAvailableFiltersData) {
      setfiltersCurrent([
        {
          title: 'Education levels',
          keySave: 'education_level',
          values: cachedAvailableFiltersData.availableEducationLevels.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Grades',
          keySave: 'grades',
          values: cachedAvailableFiltersData.availableEducationalGrades.map((item: any) => {
            return {title: item.name, key: item.name, id: item.name};
          }),
        },
        {
          title: 'Degrees',
          keySave: 'degrees',
          values: cachedAvailableFiltersData.availableDegrees.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Availability',
          keySave: 'availability',
          values: [],
        },
        {
          title: 'Activities',
          keySave: 'activities',
          values: cachedAvailableFiltersData.availableActivities.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Achievements',
          keySave: 'achievements',
          values: cachedAvailableFiltersData.availableAchievements.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'International experiences',
          keySave: 'international_experiences',
          values: cachedAvailableFiltersData.availableInternationalExperiences.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Work Experiences',
          keySave: 'work_experiences',
          values: cachedAvailableFiltersData.availableWorkingExperiences.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Archetype',
          keySave: 'archetype',
          values: cachedAvailableFiltersData.availableArchetypesOld.map((item: any) => {
            return {title: item.name, key: item.name, id: item.name};
          }),
        },
        {
          title: 'Interests',
          keySave: 'interests',
          values: cachedAvailableFiltersData.availableInterests.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Languages',
          keySave: 'languages',
          values: cachedAvailableFiltersData.availableLanguages.map((item: any) => {
            return {title: item.name, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Gender',
          keySave: 'sex',
          values: cachedAvailableFiltersData.availableSex.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
      ]);
      setCountryCity({
        countries: cachedAvailableFiltersData.availableCountries,
        cities: cachedAvailableFiltersData.availableCities,
        institutes: cachedAvailableFiltersData.availableInstitutes,
        grades: cachedAvailableFiltersData.availableEducationalGrades,
        degrees: cachedAvailableFiltersData.availableDegrees,
        education_subject:
          availableUnSubjects?.map((item: any) => {
            return {title: item.name, key: item.id, id: item.id};
          }) || [],
      });
    }
  }, [cachedAvailableFiltersData, availableUnSubjects]);

  const setSelectedRowKeysEmpty = React.useCallback(() => {
    // dispatch(setSelectedRowKeys([]));
    dispatch(setSelectedRowKeysOnDiffPages([]));
    listWithMessages.length > 0 ? dispatch(setSelectedRowKeys(listWithMessages)) : dispatch(setSelectedRowKeys([]));
  }, [listWithMessages]);
  const setInstitutes = React.useCallback(
    (institutes: any) => {
      if (!institutes) {
        setAvailableUnSubjects([]);
        setUniversityID(undefined);
        dispatch(StudentsActionsRedux.setFilters({...filtersCurrent, education_subject: undefined}));
        setCountryCity({...countryCity, education_subject: undefined});
      } else {
        setUniversityID(institutes);
      }
    },
    [StudentsActionsRedux, countryCity],
  );

  //Changing functions
  const onChange = React.useCallback(
    (data: any, data1: any) => {
      setFiltersSelect({...filtersSelect, [data1]: data});
    },
    [filtersSelect],
  );
  const onChangeSubjects = React.useCallback(
    (data: any, data1: any) => {
      setFiltersSelect({...filtersSelect, [data1]: data});
      setSubjects(data);
    },
    [filtersSelect],
  );
  const onChangeSelectOnClearingEntity = React.useCallback(
    (_a: any, data: any, entityTitle: string) => {
      if (data) {
        setFiltersSelect({...filtersSelect, [entityTitle]: data.children});
      } else {
        setFiltersSelect({...filtersSelect, [entityTitle]: undefined});
      }
    },
    [filtersSelect],
  );
  const onChangeSelectInstitute = React.useCallback(
    (data: any) => {
      if (data) {
        setFiltersSelect({...filtersSelect, institutes: data});
      } else {
        setFiltersSelect({...filtersSelect, institutes: undefined, education_subject: undefined});
        setSubjects([]);
      }
      setInstitutes(data);
    },
    [filtersSelect],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <div className={styles.modalFilters}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={styles.borderAllFilters}>
          {filtersCurrent.map((item: any, id: number) => {
            if (id === 1) {
              return (
                <React.Fragment key={item.title}>
                  <SelectorWithSearch
                    onChange={(_a: any, data: any) => onChangeSelectOnClearingEntity(_a, data, 'country')}
                    array={countryCity.countries}
                    reduxFilters={reduxFilters?.country}
                    value={'id'}
                    title={'name'}
                    label={'Country'}
                    styles={styles}
                  />
                  <SelectorWithSearch
                    onChange={(_a: any, data: any) => onChangeSelectOnClearingEntity(_a, data, 'city')}
                    array={countryCity.cities}
                    reduxFilters={reduxFilters?.city}
                    value={'id'}
                    title={'name'}
                    label={'City'}
                    styles={styles}
                  />
                  <SelectorWithSearch
                    onChange={onChangeSelectInstitute}
                    array={countryCity.institutes}
                    value={'id'}
                    title={'name'}
                    reduxFilters={reduxFilters?.institutes}
                    label={'University'}
                    styles={styles}
                  />
                  <div className={styles.borderOneFilter}>
                    <div className={styles.label}>Education subjects (depends by University)</div>
                    <TreeSelector
                      valueSelector={subjects}
                      treeData={countryCity.education_subject}
                      width={'26rem'}
                      handleChange={(id: any) => onChangeSubjects(id, 'education_subject')}
                      defaultValue={
                        reduxFilters && reduxFilters['education_subject'] && reduxFilters['education_subject']
                      }
                    />
                  </div>
                  <div key={id} className={styles.borderOneFilter}>
                    <div className={styles.label}>{item.title}</div>
                    <TreeSelector
                      treeData={item.values}
                      width={'26rem'}
                      handleChange={(id: any) => onChange(id, item.keySave)}
                      defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
                    />
                  </div>
                </React.Fragment>
              );
            }

            return (
              <div key={id} className={styles.borderOneFilter}>
                <div className={styles.label}>{item.title}</div>
                <TreeSelector
                  treeData={item.values}
                  width={'26rem'}
                  handleChange={(id: any) => onChange(id, item.keySave)}
                  defaultValue={reduxFilters && reduxFilters[item.keySave] && reduxFilters[item.keySave]}
                />
              </div>
            );
          })}

          <div className={styles.borderOneFilter}>
            <div className={styles.label}>Referral Code:</div>
            <Input
              placeholder="Referral Code"
              onChange={(e) => onChange(e.target.value.toUpperCase(), 'referrer')}
              className={styles.input}
              value={filtersSelect && filtersSelect['referrer'] && filtersSelect['referrer'].toUpperCase()}
              defaultValue={reduxFilters && reduxFilters['referrer'] && reduxFilters['referrer'].toUpperCase()}
            />
          </div>
          {/*<div className={styles.borderOneFilter}>*/}
          {/*<div className={styles.label}>Archetype</div>*/}
          {/*<Input type="text" value={filtersSelect['archetype']}*/}
          {/*onChange={(e) => onChange(e.target.value, 'archetype')}/>*/}
          {/*</div>*/}
        </div>
        <UIButtonsWrapper
          styles={styles}
          onClickReset={() => {
            setCurrentFilters([], dispatch, StudentsActionsRedux, onClose, getStudents);
            setFiltersSelect([]);
            setSubjects([]);
            setSelectedRowKeysEmpty();
          }}
          onClickSetFilters={() => {
            setCurrentFilters(filtersSelect, dispatch, StudentsActionsRedux, onClose, getStudents);
            setSelectedRowKeysEmpty();
          }}
        />
      </div>
    </Dialog>
  );
};

export default React.memo(ModalFiltersStudents);
