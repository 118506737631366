import React, {FC, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
//Actions
import {setDataCSV} from 'store/students/actions';
//Components
import PaginationComponent from 'components/Pagination';

import PageWithDrawer from 'components/PageWithDrawer';
import {ConfirmDeleteChanel, ModalSynchronize} from 'components/Modals';
import LoadingComponent from 'components/LoadingComponent';
import TableComponent from 'components/Tables';
import SearchAndFilters from 'components/SearchAndFilters';
import StatusBar from 'components/StatusBar';
//Styles
import {useStyles} from './defaultStylesListPage';
//Functions, utils
import {
  setIdOneEntity,
  setValueSearch,
  openModal,
  handleConfirmDeleteClose,
  setNumberPage,
} from 'containers/defaultFunc';
import {Button, Tooltip} from 'antd';

interface IProps {
  optionsSynchronize: any;
  EntityActionsRedux: any;
  getEntity: any;
  deleteEntity: any;
  entityTitle: string;
  entitiesTitle: any;
  SingleEntityActionsRedux: any;
  initialState: any;
  deleteNext?: any;
  columns: any;
  filterOpen: any;
  tooltipText: string;
}

const EntityList: FC<IProps> = ({
  optionsSynchronize,
  EntityActionsRedux,
  SingleEntityActionsRedux,
  getEntity,
  deleteEntity,
  deleteNext,
  entityTitle,
  entitiesTitle,
  initialState,
  columns,
  filterOpen,
  children,
  tooltipText,
}) => {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();

  const [isConfirmDeleteOpened, setConfirmDeleteOpened] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const [synchronizeWindow, setSynchronizeWindow] = useState(false);

  const entityData: any = useSelector((state: any) => state[`${entitiesTitle}`]);

  // useEffect(() => {
  //   dispatch(EntityActionsRedux.setSorting(undefined));
  //   dispatch(EntityActionsRedux.setFilters(undefined));
  //   dispatch(EntityActionsRedux.setSearchName(undefined));
  // }, [dispatch, EntityActionsRedux]);

  useEffect(() => {
    dispatch(setDataCSV(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (getEntity.data) {
      dispatch(EntityActionsRedux.setEntities(getEntity.data[entitiesTitle].result));
      dispatch(EntityActionsRedux.setEntityCount(getEntity.data[entitiesTitle].total));
    }
  }, [dispatch, getEntity.data, EntityActionsRedux, entitiesTitle]);

  const addNewEntity = React.useCallback(() => {
    dispatch(SingleEntityActionsRedux.setEntityId(''));
    dispatch(
      SingleEntityActionsRedux.setEntity({
        ...initialState,
      }),
    );
    history.push(`/create-${entityTitle}`);
  }, [dispatch, SingleEntityActionsRedux, history, entityTitle, initialState]);

  const {filters: reduxFilters}: any = useSelector((state: any) => state[entitiesTitle]);

  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer
        buttonTop={{onClick: addNewEntity, className: styles.customButton, title: `Create ${entityTitle}`}}>
        {getEntity.error && <LoadingComponent />}
        <StatusBar>
          <SearchAndFilters
            styles={styles}
            active={!!Object.keys(reduxFilters || {}).length}
            setValueSearch={setValueSearch({
              dispatch,
              setFunc: EntityActionsRedux.setSearchName,
              refetchEntity: getEntity,
              setPageFunc: EntityActionsRedux.setCurrentPage,
            })}
            setfilterOpen={filterOpen.setfilterOpen}
          />
          <Tooltip placement="topRight" title={tooltipText}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setSynchronizeWindow(true)}
              style={{marginLeft: '1rem'}}>
              Synchronize
            </Button>
          </Tooltip>
        </StatusBar>
        <div className={styles.borderTable}>
          <TableComponent
            onChangeTable={setValueSearch({
              dispatch,
              setFunc: EntityActionsRedux.setSorting,
              refetchEntity: getEntity,
              setPageFunc: EntityActionsRedux.setCurrentPage,
            })}
            deleteItem={openModal(setConfirmDeleteOpened, setDeletedId)}
            entityTitle={entitiesTitle}
            columns={columns}
            entity={entityData[entitiesTitle]}
            setId={setIdOneEntity(dispatch, SingleEntityActionsRedux.setEntityId)}
          />
          <div className={styles.borderPagination}>
            <PaginationComponent
              currentPage={entityData.currentPage}
              limit={entityData.limit}
              onChangeNumber={setNumberPage(
                dispatch,
                EntityActionsRedux.setCurrentPage,
                EntityActionsRedux.setLimit,
                getEntity,
              )}
              total={entityData[`${entitiesTitle}Count`]}
            />
          </div>
        </div>
        {children}
        <ModalSynchronize
          open={synchronizeWindow}
          onClose={() => setSynchronizeWindow(false)}
          options={optionsSynchronize}
          entity={entitiesTitle}
        />
        <ConfirmDeleteChanel
          open={isConfirmDeleteOpened}
          onClose={handleConfirmDeleteClose(deleteEntity, deletedId, setConfirmDeleteOpened, deleteNext)}
          message={`This ${entityTitle} will be deleted`}
        />
      </PageWithDrawer>
    </div>
  );
};

export default EntityList;
