import React, {FC} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useStyles} from './styles';
import {Button} from 'antd';

interface Props {
  open: boolean;
  onClose: (confirm: boolean) => any;
  message: string;
}

const ConfirmDeleteChanel: FC<Props> = ({onClose, open, message}: Props) => {
  // Styling
  const styles = useStyles();

  return (
    <Dialog open={open} onBackdropClick={(): void => onClose(false)}>
      <div className={styles.DisableProtectionModal}>
        <div className={styles.modalHeader}>{'Attention!'}</div>
        <div className={styles.modalMessage}>{message}</div>
        <div className={styles.buttonsWrapper}>
          <Button type="primary" className={styles.button} onClick={(): void => onClose(true)}>
            Confirm
          </Button>
          <Button type="primary" className={styles.button} onClick={(): void => onClose(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteChanel;
