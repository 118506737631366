import {Sorter} from 'store/classForListPage';

export interface EventsState {
  events: Event[];
  eventsCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  searchName?: string;
  filters?: Filters;
}

export interface Filters {
  types: string[];
  sectors: string[];
}
export interface Event {
  id: any;
  name: string;
  banner_img: string;
  company_id?: number;
  start_date: string;
  end_date: string;
  address: string;
  main_description_en: string;
  main_description_fr: string;
  main_description_nl: string;
  text_en: string;
  types: string[];
}

export enum EventsActionTypes {
  EVENTS_SET_EVENTS = 'EVENTS_SET_EVENTS',
  EVENTS_SET_EVENTS_COUNT = 'EVENTS_SET_EVENTS_COUNT',
  EVENTS_SET_LIMIT = 'EVENTS_SET_LIMIT',
  EVENTS_SET_CURRENT_PAGE = 'EVENTS_SET_CURRENT_PAGE',
  EVENTS_SET_SORTING = 'EVENTS_SET_SORTING',
  EVENTS_SET_SEARCH_NAME = 'EVENTS_SET_SEARCH_NAME',
  EVENTS_SET_FILTERS = 'EVENTS_SET_FILTERS',
}

export interface EventsSetEvents {
  type: EventsActionTypes.EVENTS_SET_EVENTS;
  payload: Event[];
}

export interface EventsSetEventsCount {
  type: EventsActionTypes.EVENTS_SET_EVENTS_COUNT;
  payload: number;
}

export interface EventsSetCount {
  type: EventsActionTypes.EVENTS_SET_LIMIT;
  payload: number;
}

export interface EventsSetPage {
  type: EventsActionTypes.EVENTS_SET_CURRENT_PAGE;
  payload: number;
}

export interface EventsSetSorting {
  type: EventsActionTypes.EVENTS_SET_SORTING;
  payload: Sorter | undefined;
}
export interface EventsSetSearchName {
  type: EventsActionTypes.EVENTS_SET_SEARCH_NAME;
  payload: string | undefined;
}
export interface EventsSetFilters {
  type: EventsActionTypes.EVENTS_SET_FILTERS;
  payload: Filters | undefined;
}
export type EventsActions =
  | EventsSetEvents
  | EventsSetEventsCount
  | EventsSetCount
  | EventsSetPage
  | EventsSetSorting
  | EventsSetSearchName
  | EventsSetFilters;
