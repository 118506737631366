import {makeStyles} from '@material-ui/styles';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles(() => ({
  mainWrapper: constStyles.mainWrapper,
  borderTable: {
    background: '#ffffff',
    width: '100%',
    marginBottom: '0',
    minWidth: '900px',
  },
  container: constStyles.container,
  left: {
    width: '50%',
  },
  right: {
    width: '50%',
  },
  input: {
    width: '100%',
    paddingRight: '1rem !important',
    '& label': {
      paddingRight: '1rem !important',
    },
  },
  customButton: {
    ...constStyles.customButton,
    right: '0',
    top: '1.5rem',
  },
  divButton: {
    position: 'relative',
    top: '3rem',
    height: '0',
    width: '14rem',
    right: '5rem',
    marginLeft: 'auto',
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    width: '100%',
    padding: '1rem 3rem ',
    color: 'rgba(0, 0, 0, 0.85)',
    background: '#ffffff',
  },
}));
