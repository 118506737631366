import React, {FC, ReactElement} from 'react';
import MDEditor, {commands} from '@uiw/react-md-editor';
import {Button, Form, Tooltip} from 'antd';
import {useStyles} from './styles';

interface Props {
  value: string;
  required?: boolean;
  labelKey: string;
  rule?: any;
  tooltipText?: any;
}

const RichText: FC<Props> = ({value, labelKey, required, rule, tooltipText}: Props): ReactElement => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.label} style={{display: 'flex', alignItems: 'center'}}>
          {labelKey}{' '}
          {tooltipText && (
            <Tooltip placement="top" title={tooltipText}>
              <Button
                type="text"
                style={{padding: '5px', display: 'flex', alignItems: 'center', height: '2rem', marginLeft: '1rem'}}>
                <span style={{fontSize: '10px'}}>ⓘ</span>
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <Form.Item
        name={value}
        className={styles.input}
        rules={[
          {
            required,
            message: `${labelKey} is required field`,
          },
        ]}>
        <MDEditor
          previewOptions={{escapeHtml: true}}
          commands={[
            commands.italic,
            commands.bold,

            commands.strikethrough,
            commands.hr,
            commands.title,
            commands.divider,

            commands.link,
            commands.image,
            commands.quote,
            commands.code,
            commands.divider,

            commands.orderedListCommand,
            commands.unorderedListCommand,
            commands.checkedListCommand,
            commands.divider,

            commands.codeEdit,
            commands.codeLive,
            commands.divider,

            commands.fullscreen,
          ]}
          height={200}
        />
      </Form.Item>
    </>
  );
};

export default RichText;
