/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment-timezone';
import {Referral} from 'store/referrals/types';

moment.tz.setDefault('Europe/Brussels');

export const columns = (deleteDrop: (id: string) => void) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: '250px',
    sorter: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '250px',
    sorter: true,
  },
  {
    title: 'Created at',
    dataIndex: 'created',
    key: 'created',
    width: '250px',
    render: (value: string) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    },
    sorter: true,
  },
  {
    title: 'Updated at',
    dataIndex: 'updated',
    key: 'updated',
    width: '250px',
    render: (value: string) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    },
    sorter: true,
  },
  {
    width: '9rem',
    title: '',
    dataIndex: 'operation',
    render: (_: any, record: Referral) => {
      return (
        <div
          style={{
            appearance: 'none',
            height: '3rem',
            border: 'none',
            display: 'flex',
            fontSize: '1.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F65532',
            color: 'white',
            width: '6rem',
            cursor: 'pointer',
            marginRight: '8px',
          }}
          onClick={() => {
            deleteDrop(record.id);
          }}>
          Delete
        </div>
      );
    },
  },
];
