import React, {FC} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useStyles} from './styles';
import Icon from 'components/UI/Icon';
import {Button} from 'antd';

interface Props {
  open: boolean;
  onClose: () => any;
  messageKey: string;
}

const SuccessModal: FC<Props> = ({onClose, open, messageKey}: Props) => {
  // Styling
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.infoModal}>
        <Icon name="roundedTick" size={50} />
        <div className={styles.modalInfoMessage}>{messageKey}</div>
        <div className={styles.buttonsWrapper}>
          <Button type="primary" className={styles.button} onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SuccessModal;
