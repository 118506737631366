import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
  query($limit: Float!, $page: Float!, $search: CompanyFilterInput!, $order: String, $orderDirection: String) {
    companies(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
        created
        cities
        sectors {
          id
          name_en
        }
        company_size {
          id
          name
        }
        emoji {
          id
          name
        }
        tags {
          id
          name
          value
        }
        events_total
        opportunities_total
        likes_total
        visits_total
        feed_appearances
      }
      page
      total
      limit
    }
  }
`;
export const GET_COMPANIES_IDNAME = gql`
  query($limit: Float!, $page: Float!, $search: CompanyFilterInput!, $order: String, $orderDirection: String) {
    companies(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
      }
      page
    }
  }
`;
export const GET_OPPORTUNITIES_IDNAME = gql`
  query($limit: Float!, $page: Float!, $search: OpportunityFilterInput!, $order: String, $orderDirection: String) {
    opportunities(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
        company_name
      }
      page
    }
  }
`;

export const GET_COMPANY = gql`
  query($id: String!) {
    company(id: $id) {
      id
      name
      website
      background_photo
      banner_photo
      logo
      cities
      company_size {
        id
        name
      }
      sectors {
        id
        name_en
      }
      socials {
        type
        value
      }
      emoji {
        id
        name
      }
      tags {
        id
        name
      }
      bio_en
      bio_fr
      bio_nl

      additional_info_en
      additional_info_fr
      additional_info_nl

      published_from
      published_to

      target_archetypes {
        id
        name_en
      }
      target_education_levels {
        id
        name_en
      }
      target_profile_interests {
        id
        name_en
      }
      target_degrees {
        id
        name_en
      }
      target_average_grades {
        id
        name
      }
      target_achievements {
        id
        name_en
      }
      target_international_experiences {
        id
        name_en
      }
      target_activities {
        id
        name_en
      }
      target_languages {
        id
        name
        is_primary
      }
      target_work_experiences {
        id
        name_en
      }

      bonus_feed_points
    }
  }
`;
export const GET_COMPANY_OPTIONS = gql`
  query {
    companyCompanySizes {
      id
      name
    }
    companyEmojis {
      id
      name
      value_en
    }
    companySectors {
      id
      name_en
      name_fr
      name_nl
    }
    availableCities {
      name
    }
    availableArchetypes {
      id
      name_en
    }
    availableEducationLevels {
      id
      name_en
    }
    availableInterests {
      id
      name_en
    }
    availableDegrees {
      id
      name_en
    }
    availableEducationalGrades {
      id
      name
    }
    availableAchievements {
      id
      name_en
    }
    availableInternationalExperiences {
      id
      name_en
    }
    availableActivities {
      id
      name_en
    }
    availableLanguages {
      id
      name
      is_primary
    }
    availableWorkingExperiences {
      id
      name_en
    }
  }
`;
export const GET_COMPANY_OPTIONS_FOR_FILTERS = gql`
  query {
    companyCompanySizes {
      id
      name
    }
    companyEmojis {
      id
      name
      value_en
    }
    companySectors {
      id
      name_en
    }
    availableCities {
      name
    }
  }
`;

export const GET_OPPORTUNITY_OPTIONS = gql`
  query {
    opportunityTypes {
      id
      name_en
    }
    opportunityDegrees {
      id
      name_en
    }
    opportunityExperiences {
      id
      name_en
    }
    opportunityLanguages {
      id
      name
      is_primary
    }
    availableCities {
      name
    }

    availableArchetypes {
      id
      name_en
    }
    availableEducationLevels {
      id
      name_en
    }
    availableInterests {
      id
      name_en
    }
    availableDegrees {
      id
      name_en
    }
    availableEducationalGrades {
      id
      name
    }
    availableAchievements {
      id
      name_en
    }
    availableInternationalExperiences {
      id
      name_en
    }
    availableActivities {
      id
      name_en
    }
    availableLanguages {
      id
      name
      is_primary
    }
    availableWorkingExperiences {
      id
      name_en
    }
  }
`;
export const GET_OPPORTUNITIES = gql`
  query($limit: Float!, $page: Float!, $search: OpportunityFilterInput!, $order: String, $orderDirection: String) {
    opportunities(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
        created
        company_name
        cities
        types {
          id
          name_en
        }

        experiences {
          id
          name_en
        }
        sectors {
          id
          name_en
        }

        languages {
          id
          name
          is_primary
        }
        degrees {
          id
          name_en
        }
        likes_total
        visits_total
        feed_appearances
      }
      page
      limit
      total
    }
  }
`;

export const GET_OPPORTUNITY_OPTIONS_FOR_FILTERS = gql`
  query {
    opportunityTypes {
      id
      name_en
    }
    companySectors {
      id
      name_en
    }
    opportunityExperiences {
      id
      name_en
    }
    opportunityLanguages {
      id
      name
      is_primary
    }
    availableCities {
      name
    }
    opportunityDegrees {
      id
      name_en
    }
  }
`;
export const GET_OPPORTUNITY = gql`
  query($id: Float!) {
    opportunity(id: $id) {
      id
      name
      created
      banner_img
      company_id
      company_name
      cities
      description_en
      description_fr
      description_nl
      main_description_en
      main_description_fr
      main_description_nl
      types {
        id
        name_en
        name_fr
        name_nl
      }
      degrees {
        id
        name_en
        name_fr
        name_nl
      }
      experiences {
        id
        name_en
      }
      languages {
        id
        name
        is_primary
      }
      published_from
      published_to

      target_archetypes {
        id
        name_en
      }
      target_education_levels {
        id
        name_en
      }
      target_profile_interests {
        id
        name_en
      }
      target_degrees {
        id
        name_en
      }
      target_average_grades {
        id
        name
      }
      target_achievements {
        id
        name_en
      }
      target_international_experiences {
        id
        name_en
      }
      target_activities {
        id
        name_en
      }
      target_languages {
        id
        name
        is_primary
      }
      target_work_experiences {
        id
        name_en
      }

      bonus_feed_points
      application {
        redirectUrl
        companyEmail
        buddyEmail
        messageTranslations {
          language
          translation
        }
      }
    }
  }
`;

export const GET_EVENTS = gql`
  query($limit: Float!, $page: Float!, $search: EventFilterInput!, $order: String, $orderDirection: String) {
    events(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
        created
        sectors {
          id
          name_en
        }
        company_name
        start_date
        end_date
        types {
          id
          name_en
        }
        likes_total
        visits_total
        feed_appearances
      }
      page
      limit
      total
    }
  }
`;
export const GET_EVENTS_OPTIONS_FOR_FILTERS = gql`
  query {
    eventTypes {
      id
      name_en
    }
    companySectors {
      id
      name_en
    }
  }
`;
export const GET_EVENT = gql`
  query($id: String!) {
    event(id: $id) {
      id
      name
      created
      banner_img
      company_id
      company_name
      start_date
      end_date
      address
      main_description_en
      main_description_fr
      main_description_nl
      description_en
      description_fr
      description_nl
      types {
        id
        name_en
        name_fr
        name_nl
      }
      published_from
      published_to

      target_archetypes {
        id
        name_en
      }
      target_education_levels {
        id
        name_en
      }
      target_profile_interests {
        id
        name_en
      }
      target_degrees {
        id
        name_en
      }
      target_average_grades {
        id
        name
      }
      target_achievements {
        id
        name_en
      }
      target_international_experiences {
        id
        name_en
      }
      target_activities {
        id
        name_en
      }
      target_languages {
        id
        name
        is_primary
      }
      target_work_experiences {
        id
        name_en
      }

      bonus_feed_points
      application {
        redirectUrl
        companyEmail
        buddyEmail
        messageTranslations {
          language
          translation
        }
      }
    }
  }
`;
export const GET_EVENT_OPTIONS = gql`
  query {
    eventTypes {
      id
      name_en
    }

    availableArchetypes {
      id
      name_en
    }
    availableEducationLevels {
      id
      name_en
    }
    availableInterests {
      id
      name_en
    }
    availableDegrees {
      id
      name_en
    }
    availableEducationalGrades {
      id
      name
    }
    availableAchievements {
      id
      name_en
    }
    availableInternationalExperiences {
      id
      name_en
    }
    availableActivities {
      id
      name_en
    }
    availableLanguages {
      id
      name
      is_primary
    }
    availableWorkingExperiences {
      id
      name_en
    }
  }
`;
export const GET_EVENTS_IDNAME = gql`
  query($limit: Float!, $page: Float!, $search: EventFilterInput!, $order: String, $orderDirection: String) {
    events(limit: $limit, page: $page, search: $search, order: $order, orderDirection: $orderDirection) {
      result {
        id
        name
        company_name
      }
      page
    }
  }
`;

export const GET_STUDENTS = gql`
  query($limit: Float!, $page: Float!, $filters: StudentFilterInput!, $order: String, $orderDirection: String) {
    students(limit: $limit, page: $page, filters: $filters, order: $order, orderDirection: $orderDirection) {
      result {
        id
        country
        city
        first_name
        username
        last_name
        created
        referrer
        institutes {
          name
          subject_name
          degree_name_en
          average_grades
          archetype
        }
        interests {
          id
          name_en
          name_fr
          name_nl
        }
        international_experiences {
          id
          name_en
        }
        work_experiences {
          id
          name_en
        }
        achievements {
          id
          name_en
        }
        education_level {
          id
          name_en
        }
        activities {
          id
          name_en
        }
        languages {
          id
          name
          is_primary
        }
      }
      page
      limit
      total
    }
  }
`;

export const GET_ALL_STUDENTS_IDS = gql`
  query($limit: Float!, $filters: StudentFilterInput!, $page: Float!) {
    studentIds(limit: $limit, filters: $filters, page: $page) {
      result {
        id
      }
    }
  }
`;

export const GET_LIKED_STUDENTS_IDS = gql`
  query($limit: Float!, $page: Float!, $id: String!, $type: String!) {
    studentsWhichLiked(limit: $limit, page: $page, id: $id, type: $type) {
      result {
        id
      }
      total
    }
  }
`;

export const GET_WITH_MESSAGE_STUDENTS_IDS = gql`
  query($limit: Float!, $page: Float!, $filters: StudentWithMessageFilterInput!) {
    studentsWithMessage(limit: $limit, page: $page, filters: $filters) {
      result {
        account_id
      }
      total
    }
  }
`;

export const GET_STUDENTS_ID = gql`
  query($limit: Float!, $page: Float!, $filters: StudentFilterInput!, $order: String, $orderDirection: String) {
    students(limit: $limit, page: $page, filters: $filters, order: $order, orderDirection: $orderDirection) {
      result {
        id
        first_name
        last_name
        username
      }
      page
      limit
      total
    }
  }
`;
export const GET_STUDENT = gql`
  query($id: String!) {
    student(id: $id) {
      id
      email
      phone_number
      referrer
      contact_email
      sex {
        id
        name
      }
      username
      first_name
      last_name
      birthday
      country
      city
      provider
      request_language
      institutes {
        id
        name
        subject_id
        subject_name
        degree_id
        degree_name_en
        graduation_date
        average_grades
      }
      international_experiences {
        id
        name_en
      }
      work_experiences {
        id
        name_en
      }
      education_level {
        id
        name_en
      }
      achievements {
        id
        name_en
      }
      activities {
        id
        name_en
      }

      languages {
        id
        name
        is_primary
      }
      created
      short_bio
      social_media {
        type
        url
      }
      image_url
      interests {
        id
        name_en
      }
    }
  }
`;

export const GET_LIKED_COMPANIES_OPPORTINITIES_EVENTS = gql`
  query($limit: Float!, $page: Float!, $order: String, $orderDirection: String, $studentId: String!) {
    likedCompanies(limit: $limit, page: $page, order: $order, orderDirection: $orderDirection, studentId: $studentId) {
      result {
        id
        name
      }
    }
    likedOpportunities(
      limit: $limit
      page: $page
      order: $order
      orderDirection: $orderDirection
      studentId: $studentId
    ) {
      result {
        id
        name
      }
    }
    likedEvents(limit: $limit, page: $page, order: $order, orderDirection: $orderDirection, studentId: $studentId) {
      result {
        id
        name
      }
    }
  }
`;
export const GET_STUDENTS_WITH_MESSAGES = gql`
  query($limit: Float, $page: Float, $filters: StudentWithMessageFilterInput!) {
    studentsWithMessage(limit: $limit, page: $page, filters: $filters) {
      result {
        account_id
        is_accepted
        country
        city
        first_name
        username
        last_name
        created
        institutes {
          name
          subject_name
          degree_name_en
          average_grades
        }
        international_experiences {
          id
          name_en
        }
        work_experiences {
          id
          name_en
        }
        achievements {
          id
          name_en
        }
        education_level {
          id
          name_en
        }
        activities {
          id
          name_en
        }
      }
      page
      limit
      total
    }
  }
`;
export const GET_STUDENTS_THAT_LIKED = gql`
  query($limit: Float, $page: Float, $id: String!, $type: String!, $order: String, $orderDirection: String) {
    studentsWhichLiked(
      limit: $limit
      page: $page
      id: $id
      type: $type
      order: $order
      orderDirection: $orderDirection
    ) {
      result {
        id
        like_id
        like_created
        country
        city
        first_name
        username
        last_name
        created
        institutes {
          name
          subject_name
          degree_name_en
          average_grades
        }
        international_experiences {
          id
          name_en
        }
        work_experiences {
          id
          name_en
        }
        achievements {
          id
          name_en
        }
        education_level {
          id
          name_en
        }
        activities {
          id
          name_en
        }
      }
      page
      limit
      total
    }
  }
`;
export const GET_STUDENT_WITH_MESSAGES = gql`
  query($limit: Float, $page: Float, $account_id: String!) {
    studentWithMessages(limit: $limit, page: $page, account_id: $account_id) {
      result {
        account_id
        name
        entity_id
        text
        is_accepted
        is_archived
        is_automated
        message_type
        message_created
      }
      page
      limit
      total
    }
  }
`;
export const GET_AVAILABLE_FILTERS_FOR_STUDENTS = gql`
  query {
    availableSex {
      id
      name_en
    }
    availableInternationalExperiences {
      id
      name_en
    }
    availableAchievements {
      id
      name_en
    }
    availableActivities {
      id
      name_en
    }
    availableWorkingExperiences {
      id
      name_en
    }
    availableInstitutes {
      id
      name
    }

    availableCountries {
      name
    }
    availableCities {
      name
    }
    availableEducationLevels {
      id
      name_en
    }
    availableEducationalGrades {
      id
      name
    }
    availableArchetypesOld {
      name
    }
    availableDegrees {
      id
      name_en
    }
    availableLanguages {
      id
      name
    }
    availableInterests {
      id
      name_en
    }
  }
`;
export const GET_AVAILABLE_SUBJECTS = gql`
  query($institute_id: String!) {
    availableEducationalSubjects(institute_id: $institute_id) {
      id
      name
    }
  }
`;

export const EXPORT_CSV = gql`
  query($ids: [String!]!) {
    exportCSV(ids: $ids)
  }
`;

export const GET_MESSAGES = gql`
  query($limit: Float, $page: Float, $filters: MessageFilterInput!) {
    messages(limit: $limit, page: $page, filters: $filters) {
      result {
        id
        name
        company_name
        logo
        message_created
        banner_img
        message_type
        entity_id
        text
        count
        account_ids
      }
      page
      limit
      total
    }
  }
`;

export const GET_REFERRALS = gql`
  query($limit: Float, $page: Float, $filters: ReferralFilter, $order: String, $orderDirection: String) {
    referrals(limit: $limit, page: $page, filters: $filters, order: $order, orderDirection: $orderDirection) {
      result {
        code
        id
        type
        created
        updated
      }
      page
      limit
      total
    }
  }
`;
