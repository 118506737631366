/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState, useCallback} from 'react';
import {Portal} from 'react-portal';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {AppState} from 'store';
//Actions
import {EventActionsRedux} from 'store/eventDetails/actions';
//GraphQl
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_EVENT, UPDATE_EVENT, UPLOAD_EVENT_BANNER} from 'requests/mutations';
import {GET_EVENT_OPTIONS, GET_COMPANIES_IDNAME, GET_EVENT} from 'requests/queries';
//Styling
import {useStyles} from '../defaultStylesSinglePage';
//Components
import {DetailsBlock, DetailsBlock2} from 'components/EventDetails';
import {UploadPhotos, TargetBlock} from 'components/CompanyDetails';
import PageWithDrawer from 'components/PageWithDrawer';
import Loader from 'components/UI/Loader';
import LoadingComponent from 'components/LoadingComponent';
import {SuccessModal} from 'components/Modals';
import {Form, Button} from 'antd';
//Functions, utils
import moment from 'moment-timezone';
import {NotificationTypes, showNotification} from 'utils/notifications';
import {urlTofile} from 'utils/urlTofile';
import {handleChangeImage, closeModal, catchError} from 'containers/defaultFunc';

import {dateFormat} from 'containers/defaultFunc';
import {setSearchCom} from 'store/messages/actions';

moment.tz.setDefault('Europe/Brussels');

const mapSelectors = (selectors: any) =>
  selectors?.map((item: any) => {
    return item.id;
  });

interface IMessageTranslation {
  language: 'en' | 'nl' | 'fr';
  translation: string;
}

const EventPage: FC = () => {
  const styles = useStyles();
  const history: any = useHistory();
  const states: any = history.location.state;
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addPromo, setAddPromo] = useState(false);
  const [startEndPromo, setstartEndPromo] = useState([moment(), moment()]);
  const [activePromo, setActivePromo] = useState(false);
  const [showRedirectUrl, setShowRedirectUrl] = useState(false);

  const [banner, setBanner] = useState<any>('');
  const [bannerCache, setBannerCache] = useState<any>('');
  const [isUpdatePage, setIsUpdatePage] = useState(false);

  const [archetypes, setArchetypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [profileInterests, setProfileInterests] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [grades, setGrades] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [internationalExperiences, setInternationalExperiences] = useState([]);
  const [activities, setActivities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [workingExperiences, setWorkingExperiences] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [allCompanies, setAllCompanies] = useState([]);
  const searchCom: string | undefined = useSelector((state: AppState) => state.messages).searchCom;
  const [types, setTypes] = useState([]);
  const [startEnd, setstartEnd] = useState([moment(), moment()]);
  const {data: eventOptionsData, error: errorOptions} = useQuery(GET_EVENT_OPTIONS);
  const [prevSearchCom, setPrevSearchCom] = useState<string>('');
  const {data: companiesData, error: errorCompanies, fetchMore} = useQuery(GET_COMPANIES_IDNAME, {
    variables: {
      limit: 20,
      page: companiesPage,
      orderDirection: 'asc',
      order: 'name',
      search: {name: searchCom},
    },
  });
  const idEvent: any = useSelector((state: AppState) => state.eventDetails.id) ?? '';
  const [id, setId] = useState(idEvent);

  const {data: eventData, error: errorEvent} = useQuery(GET_EVENT, {
    variables: {id: id.toString()},
    skip: id === null || id === '',
  });

  const fetchMoreCompanies = async () => {
    fetchMore({
      variables: {
        page: companiesPage + 1,
        limit: 20,
        search: {name: searchCom},
      },
    });
    setCompaniesPage(companiesPage + 1);
  };
  const [createEvent] = useMutation(CREATE_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT);

  useEffect(() => {
    if (eventData && eventData.event && eventData.event.application_flow === 'REDIRECT') {
      setShowRedirectUrl(true);
    }
  }, [eventData]);

  const [uploadBanner] = useMutation(UPLOAD_EVENT_BANNER);
  useEffect(() => {
    setIsUpdatePage(window.location.pathname === '/events/details');
  }, []);

  useEffect(() => {
    setAllCompanies([]);
    setCompaniesPage(1);
  }, [searchCom]);

  useEffect(() => {
    if (
      allCompanies.length &&
      companiesData?.companies?.result !== undefined &&
      (prevSearchCom === searchCom || searchCom === undefined)
    ) {
      setAllCompanies(allCompanies?.concat(companiesData?.companies?.result));
    } else if (companiesData?.companies?.result !== undefined) {
      setAllCompanies(companiesData?.companies?.result);
      if (prevSearchCom !== searchCom && searchCom) {
        setPrevSearchCom(searchCom);
      }
    }
  }, [companiesData]);
  useEffect(() => {
    if (isUpdatePage) {
      id && history.replace(history.location.pathname, {...history.location.state, id});
      setId(!!history.location.state && states.id);
    }
  }, [history, isUpdatePage]);

  useEffect(() => {
    if (eventOptionsData) {
      const newTypes = eventOptionsData.eventTypes.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });

      setTypes(newTypes);

      const newArchetypes = eventOptionsData.availableArchetypes?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setArchetypes(newArchetypes);
      const newEducationLevels = eventOptionsData.availableEducationLevels?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setEducationLevels(newEducationLevels);
      const newProfileInterests = eventOptionsData.availableInterests?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setProfileInterests(newProfileInterests);
      const newDegrees = eventOptionsData.availableDegrees?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setDegrees(newDegrees);
      const newGrades = eventOptionsData.availableEducationalGrades?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setGrades(newGrades);
      const newAchievements = eventOptionsData.availableAchievements?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setAchievements(newAchievements);
      const newInternationalExperiences = eventOptionsData.availableInternationalExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setInternationalExperiences(newInternationalExperiences);
      const newActivities = eventOptionsData.availableActivities?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setActivities(newActivities);
      const newLanguages = eventOptionsData.availableLanguages?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setLanguages(newLanguages);
      const newWorkingExperiences = eventOptionsData.availableWorkingExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setWorkingExperiences(newWorkingExperiences);
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [eventOptionsData]);
  useEffect(() => {
    if (isUpdatePage) {
      if (eventData) {
        dispatch(EventActionsRedux.setEntity(eventData.event));
        dispatch(EventActionsRedux.setEntityId(eventData.event.id));

        form.setFieldsValue({
          [`name`]: eventData.event.name,
          [`company_id`]: eventData.event.company_name,
          [`address`]: eventData.event.address,

          [`description_en`]: eventData.event.description_en,
          [`description_fr`]: eventData.event.description_fr ? eventData.event.description_fr : '',
          [`description_nl`]: eventData.event.description_nl ? eventData.event.description_nl : '',
          [`main_description_en`]: eventData.event.main_description_en ? eventData.event.main_description_en : '',
          [`main_description_fr`]: eventData.event.main_description_fr ? eventData.event.main_description_fr : '',
          [`main_description_nl`]: eventData.event.main_description_nl ? eventData.event.main_description_nl : '',
          [`target_archetypes`]: mapSelectors(eventData.event.target_archetypes),
          [`target_education_levels`]: mapSelectors(eventData.event.target_education_levels),
          [`target_profile_interests`]: mapSelectors(eventData.event.target_profile_interests),
          [`target_degrees`]: mapSelectors(eventData.event.target_degrees),
          [`target_average_grades`]: mapSelectors(eventData.event.target_average_grades),
          [`target_achievements`]: mapSelectors(eventData.event.target_achievements),
          [`target_international_experiences`]: mapSelectors(eventData.event.target_international_experiences),
          [`target_activities`]: mapSelectors(eventData.event.target_activities),
          [`target_languages`]: mapSelectors(eventData.event.target_languages),
          [`target_work_experiences`]: mapSelectors(eventData.event.target_work_experiences),
          [`bonus_feed_points`]: eventData.event.bonus_feed_points,
          [`message_en`]: eventData.event.application.messageTranslations.filter(
            (item: IMessageTranslation) => item.language === 'en',
          )[0].translation
            ? eventData.event.application.messageTranslations.filter(
                (item: IMessageTranslation) => item.language === 'en',
              )[0].translation
            : '',
          // [`message_nl`]: eventData.event.application.messageTranslations.filter(
          //   (item: IMessageTranslation) => item.language === 'nl',
          // )[0].translation
          //   ? eventData.event.application.messageTranslations.filter(
          //       (item: IMessageTranslation) => item.language === 'nl',
          //     )[0].translation
          //   : '',
          // [`message_fr`]: eventData.event.application.messageTranslations.filter(
          //   (item: IMessageTranslation) => item.language === 'fr',
          // )[0].translation
          //   ? eventData.event.application.messageTranslations.filter(
          //       (item: IMessageTranslation) => item.language === 'fr',
          //     )[0].translation
          //   : '',
          [`redirection_link`]: eventData.event.application.redirectUrl ? eventData.event.application.redirectUrl : '',
          [`company_email`]: eventData.event.application.companyEmail ? eventData.event.application.companyEmail : '',
          [`buddy_email`]: eventData.event.application.buddyEmail
            ? eventData.event.application.buddyEmail
            : 'buddy@launch.career',
          [`type`]: eventData.event.types.map((item: any) => {
            return item.id;
          }),
        });

        setstartEnd([moment(eventData.event.start_date), moment(eventData.event.end_date)]);

        setBanner(eventData.event.banner_img);

        if (eventData.event.published_from && eventData.event.published_to) {
          setstartEndPromo([moment(eventData.event.published_from).utc(), moment(eventData.event.published_to)]);
          setAddPromo(true);
          const newD = moment(moment.now()).format(dateFormat);
          const start = moment(moment(eventData.event.published_from).format(dateFormat)).isSameOrBefore(newD);
          const end = moment(moment(eventData.event.published_to).format(dateFormat)).isSameOrAfter(newD);
          if (start && end) {
            setActivePromo(true);
          } else {
            setActivePromo(false);
          }
        } else {
          setAddPromo(false);
        }
      }
    } else {
      form.setFieldsValue({
        [`application_flow`]: 'MESSAGE',
        [`buddy_email`]: 'buddy@launch.career',
      });
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [form, eventData, isUpdatePage]);
  const setBannerOp = (image: string) => {
    setBanner(image);
    setBannerCache(image);
  };

  const dataUpdateEvent = (data: any) => {
    const dataToSend: any = {
      name: data.name,
      company_id: typeof data.company_id === 'number' ? data.company_id : eventData.event.company_id,
      address: data.address,
      start_date: moment(startEnd[0]),
      end_date: moment(startEnd[1]),
      description_en: data.description_en,
      main_description_en: data.main_description_en,
      main_description_fr: data.main_description_fr || '',
      main_description_nl: data.main_description_nl || '',
      text_en: data.text_en,
      // text_message_fr: data.text_message_fr || '',
      // text_message_nl: data.text_message_nl || '',
      published_from: moment(startEndPromo[0]),
      published_to: moment(startEndPromo[1]),
      types: data.type,
      target_archetypes: data.target_archetypes,
      target_education_levels: data.target_education_levels,
      target_profile_interests: data.target_profile_interests,
      target_degrees: data.target_degrees,
      target_average_grades: data.target_average_grades,
      target_achievements: data.target_achievements,
      target_international_experiences: data.target_international_experiences,
      target_activities: data.target_activities,
      target_languages: data.target_languages?.map((i: number) => i.toString()),
      target_work_experiences: data.target_work_experiences,
      bonus_feed_points: Number(data.bonus_feed_points),
      application: {
        redirectUrl: data.redirection_link,
        buddyEmail: data.buddy_email,
        companyEmail: data.company_email,
        messageTranslations: [
          {
            language: 'en',
            translation: data.message_en,
          },
          // {
          //   language: 'fr',
          //   translation: data.message_fr ? data.message_fr : '',
          // },
          // {
          //   language: 'nl',
          //   translation: data.message_nl ? data.message_nl : '',
          // },
        ],
      },
    };
    if (data.description_fr) {
      dataToSend.description_fr = data.description_fr;
    }
    if (data.description_nl) {
      dataToSend.description_nl = data.description_nl;
    }

    return dataToSend;
  };

  const dataCreateEvent = (data: any) => {
    return {...dataUpdateEvent(data), company_id: data.company_id.toString()};
  };
  const onSubmit = async (data: any) => {
    const parseStr = data.text_en ? data.text_en.split('\n') : null;
    if (parseStr && parseStr.length > 0 && parseStr[0]?.length > 80) {
      showNotification(NotificationTypes.info, 'First line must contain text without markdown and max 80 characters');
    } else if (banner === '' || banner === null || banner.includes('data:')) {
      showNotification(NotificationTypes.info, 'Banner is required, please select image or crop');
    } else {
      try {
        setLoader(true);
        let response;
        if (isUpdatePage) {
          response = await updateEvent({
            variables: {
              id: id.toString(),
              ...dataUpdateEvent(data),
            },
          });
        } else {
          response = await createEvent({
            variables: {
              ...dataCreateEvent(data),
            },
          });
        }

        if (response.data) {
          if (!banner.startsWith('https://')) {
            const banner1 = await urlTofile(banner, `banner${data.name}${new Date().toISOString()}`);
            const responseLOGO = await uploadBanner({
              variables: {
                file: banner1,
                id: isUpdatePage ? response.data.updateEvent.toString() : response.data.createEvent.id.toString(),
              },
            });

            if (responseLOGO.data) {
              setLoader(false);
              setIsOpenModal(true);
            }
          } else {
            setLoader(false);
            setIsOpenModal(true);
          }
        }
      } catch (e) {
        catchError(setLoader, isUpdatePage, 'event', e);
      }
    }
  };

  async function onSearch(value: string | undefined) {
    dispatch(setSearchCom(value ? value : undefined));
  }
  const handleOnchangeCompany = () => {
    dispatch(setSearchCom(undefined));
  };
  const onCalendarChange = (dateString: any) => {
    if (dateString[0] === '' || dateString[1] === '') {
      setstartEnd([moment(), moment()]);
    } else {
      setstartEnd(dateString);
    }
  };

  const onCalendarChangePropmo = (dateString: any) => {
    if (dateString[0] === '' || dateString[1] === '') {
      setstartEndPromo([moment(), moment()]);
    } else {
      setstartEndPromo(dateString);
    }
  };

  const handleFormChange = useCallback((values) => {
    if (values && values.application_flow) {
      if (values.application_flow === 'REDIRECT') {
        setShowRedirectUrl(true);
      } else {
        setShowRedirectUrl(false);
      }
    }
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {errorCompanies || errorOptions || errorEvent ? (
          <LoadingComponent goToEntity="events" />
        ) : (
          <Form form={form} onFinish={onSubmit} style={{overflowX: 'scroll'}} onValuesChange={handleFormChange}>
            <div className={styles.divButton}>
              <Button type="primary" htmlType="submit" className={styles.customButton}>
                Save event
              </Button>
            </div>

            <div className={styles.borderTable}>
              <div className={styles.container}>
                <div className={styles.left}>
                  <div style={{width: '11.7rem'}}>
                    <UploadPhotos
                      hasCache={!!bannerCache}
                      onChange={handleChangeImage(setBanner)}
                      onLoad={handleChangeImage(setBannerOp)}
                      image={banner}
                      redoImage={() => setBanner(bannerCache)}
                      deleteImage={() => setBanner('')}
                      title={'Banner photo'}
                      aspectValue={5 / 2}
                      tooltipText={
                        'Photo to be used at the top of Event details page and as preview for Company cards.'
                      }
                    />
                  </div>

                  <DetailsBlock
                    optionsData={{
                      allCompanies,
                    }}
                    dataForSelectors={{
                      type: {data: types},
                    }}
                    fetchSearchCompanies={onSearch}
                    handleOnchangeCompany={handleOnchangeCompany}
                    fetchMoreCompanies={fetchMoreCompanies}
                    forCalendar={{
                      onCalendarChange: onCalendarChange,
                      startEnd: startEnd,
                    }}
                  />

                  <div style={{height: '11.2rem'}} />
                  <TargetBlock
                    dataForSelectors={{
                      archetypes,
                      educationLevels,
                      profileInterests,
                      degrees,
                      grades,
                      achievements,
                      internationalExperiences,
                      activities,
                      languages,
                      workingExperiences,
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <div style={{height: '13.5rem'}} />
                  <DetailsBlock2
                    richArea={{
                      en: {value: 'description_en'},
                      fr: {value: 'description_fr'},
                      nl: {value: 'description_nl'},
                    }}
                    promo={{addPromo, setAddPromo, activePromo}}
                    forCalendar={{
                      onCalendarChange: onCalendarChangePropmo,
                      startEnd: startEndPromo,
                    }}
                    showRedirectURLInput={showRedirectUrl}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
        <SuccessModal
          onClose={() => closeModal('events')}
          open={isOpenModal}
          messageKey={`Event was successful ${isUpdatePage ? 'updated' : 'created'}`}
        />
      </PageWithDrawer>
      {loader && (
        <Portal>
          <Loader loader={loader} />
        </Portal>
      )}
    </div>
  );
};

export default EventPage;
