import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles((theme: Theme) => ({
  PageWithDrawer: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  headerMain: {
    ...constStyles.headerMain,
    marginBottom: 0,
    justifyContent: 'space-between',
  },
  borderTable: constStyles.borderTable1,
  blockTable: constStyles.blockTable,
}));
