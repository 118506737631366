import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store';
//GraphQl
import {useQuery, useMutation} from '@apollo/client';
import {GET_OPPORTUNITIES, GET_OPPORTUNITY_OPTIONS_FOR_FILTERS, GET_COMPANIES_IDNAME} from 'requests/queries';
import {DELETE_OPPORTUNITY} from 'requests/mutations';
//Actions
import {OpportunitiesActionsRedux} from 'store/opportunities/actions';
import {initialState} from 'store/opportunityDetails/reducer';
import {OpportunityActionsRedux} from 'store/opportunityDetails/actions';
//Components

import {columns} from 'components/Tables/TableOpportunitiesComponent/columns';
import {ModalFiltersOpportunities} from 'components/Modals';

import EntityList from 'containers/classList';

const optionsSynchronize = [
  {label: 'Types', value: 'types'},
  {label: 'Experiences', value: 'experiences'},
  {label: 'Degrees', value: 'degrees'},
  {label: 'Languages', value: 'languages'},
];
const Opportunities: FC = () => {
  const [filterOpen, setfilterOpen] = useState(false);
  const [filtersCurrent, setfiltersCurrent] = useState<any>([]);
  const [locationCompaniesAvailable, setlocationCompaniesAvailable] = useState({locations: [], companies: []});

  const {limit, currentPage, sorting, searchName, filters}: any = useSelector((state: AppState) => state.opportunities);
  const {data: opportunityOptionsData} = useQuery(GET_OPPORTUNITY_OPTIONS_FOR_FILTERS, {
    skip: !filterOpen,
  });

  const {data: companiesData} = useQuery(GET_COMPANIES_IDNAME, {
    variables: {
      limit: 10000,
      page: 1,
      orderDirection: 'asc',
      order: 'name',
      search: {},
    },
    skip: !filterOpen,
  });

  const getOpportunities = useQuery(GET_OPPORTUNITIES, {
    variables: {
      limit,
      page: currentPage,
      orderDirection: sorting?.orderType,
      order: sorting?.order,
      search: {
        experiences: (filters?.experiences?.length > 0 && filters?.experiences) || undefined,
        name: searchName,
        types: (filters?.types?.length > 0 && filters?.types) || undefined,
        company_size: (filters?.company_size?.length > 0 && filters?.company_size) || undefined,
        sectors: (filters?.sectors?.length > 0 && filters?.sectors) || undefined,
        languages: (filters?.languages?.length > 0 && filters?.languages) || undefined,
        company_id: filters?.company_id || undefined,
        degrees: (filters?.degrees?.length > 0 && filters?.degrees) || undefined,
        cities: filters?.cities || undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [deleteOpportunity] = useMutation(DELETE_OPPORTUNITY);

  useEffect(() => {
    if (opportunityOptionsData) {
      setfiltersCurrent([
        {
          title: 'Cities',
          keySave: 'cities',
          values: opportunityOptionsData?.availableCities.map((item: any) => {
            return {title: item.name, key: item.name, id: item.name};
          }),
        },
        {
          title: 'Types',
          keySave: 'types',
          values: opportunityOptionsData?.opportunityTypes.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Sectors',
          keySave: 'sectors',
          values: opportunityOptionsData?.companySectors.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Experiences',
          keySave: 'experiences',
          values: opportunityOptionsData?.opportunityExperiences.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Languages',
          keySave: 'languages',
          values: opportunityOptionsData?.opportunityLanguages.map((item: any) => {
            return {title: item.name, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Degrees',
          keySave: 'degrees',
          values: opportunityOptionsData?.opportunityDegrees.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
      ]);
    }
  }, [opportunityOptionsData]);

  useEffect(() => {
    if (opportunityOptionsData) {
      if (companiesData) {
        setlocationCompaniesAvailable({
          locations: opportunityOptionsData?.availableCities,
          companies: companiesData.companies.result,
        });
      } else {
        setlocationCompaniesAvailable({
          locations: opportunityOptionsData?.availableCities,
          companies: [],
        });
      }
    }
  }, [opportunityOptionsData, companiesData]);

  return (
    <EntityList
      optionsSynchronize={optionsSynchronize}
      EntityActionsRedux={OpportunitiesActionsRedux}
      SingleEntityActionsRedux={OpportunityActionsRedux}
      getEntity={getOpportunities}
      deleteNext={getOpportunities.refetch}
      deleteEntity={deleteOpportunity}
      entityTitle={'opportunity'}
      entitiesTitle={'opportunities'}
      initialState={initialState.opportunity}
      filterOpen={{filterOpen, setfilterOpen}}
      columns={columns}
      tooltipText={`In case one of the following was changed in the database:
      ▪  types
      ▪  experiences
      ▪  degrees
      ▪  languages
For a change to take place for existing opportunities this button has to be pressed. 
Example: language label was changed from “Chinese“ to “简体中文“ No additional action needed if new language was added.`}>
      <ModalFiltersOpportunities
        open={filterOpen}
        onClose={() => setfilterOpen(false)}
        filters={filtersCurrent}
        optionsData={locationCompaniesAvailable}
        getOpportunities={getOpportunities}
      />
    </EntityList>
  );
};

export default Opportunities;
