import {Reducer} from 'redux';
import {produce} from 'immer';
import {EventsActionTypes, EventsActions, EventsState} from './types';

const initialState: EventsState = {
  eventsCount: 0,
  events: [],
  currentPage: 1,
  limit: 20,
};

const eventsReducer: Reducer<EventsState, EventsActions> = (state = initialState, action): EventsState => {
  return produce(state, (draft: EventsState) => {
    switch (action.type) {
      case EventsActionTypes.EVENTS_SET_EVENTS:
        draft.events = action.payload;
        return;

      case EventsActionTypes.EVENTS_SET_EVENTS_COUNT:
        draft.eventsCount = action.payload;
        return;

      case EventsActionTypes.EVENTS_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case EventsActionTypes.EVENTS_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case EventsActionTypes.EVENTS_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case EventsActionTypes.EVENTS_SET_SEARCH_NAME:
        draft.searchName = action.payload;
        return;
      case EventsActionTypes.EVENTS_SET_FILTERS:
        draft.filters = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default eventsReducer;
