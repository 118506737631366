import React, {FC} from 'react';

interface Props {
  goToEntity?: string;
}
const LoadingComponent: FC<Props> = ({goToEntity}) => {
  return (
    <div style={{textAlign: 'center'}}>
      <h2>Loading...</h2>
      <h2>If it takes a long time to load </h2>
      <h2>Please reload the page</h2>
      {goToEntity && (
        <h2
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => window.open(`/${goToEntity}`, '_self')}>
          Go to list of {goToEntity}
        </h2>
      )}
    </div>
  );
};
export default LoadingComponent;
