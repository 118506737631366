import {checkIsUrl} from './checkIsUrl';

export const createSocialUrl = (social: string, type: 'facebook' | 'twitter' | 'instagram' | 'linkedin'): string => {
  switch (type) {
    case 'facebook': {
      return checkIsUrl(social) ? social : `https://facebook.com/${social}`;
    }
    case 'instagram': {
      return checkIsUrl(social) ? social : `https://instagram.com/${social}`;
    }
    case 'twitter': {
      return checkIsUrl(social) ? social : `https://twitter.com/${social}`;
    }
    case 'linkedin': {
      return checkIsUrl(social) ? social : `https://linkedin.com/in/${social}`;
    }
    default: {
      return social;
    }
  }
};
