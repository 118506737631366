import React, {FC} from 'react';

import {useStyles} from '../styles';

//Functions, utils
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons';
import WithCalendar from '../WithCalendar';

interface Props {

  forCalendar: any;
  promo: any;
}

const PromoBlock: FC<Props> = ({forCalendar, promo}) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.title} style={{marginBottom: '1rem'}}>
        Publication
        {!promo.addPromo && (
          <PlusCircleOutlined
            onClick={() => promo.setAddPromo(!promo.addPromo)}
            size={48}
            style={{fontSize: '20px', width: '30px'}}
          />
        )}
        {promo.addPromo && (
          <MinusCircleOutlined
            onClick={() => promo.setAddPromo(!promo.addPromo)}
            size={48}
            style={{fontSize: '20px', width: '30px'}}
          />
        )}
        {promo.activePromo ? (
          <span style={{color: 'green'}}>Active</span>
        ) : (
          <span style={{color: 'red'}}>Inactive</span>
        )}
        {promo.addPromo && (
          <WithCalendar onCalendarChange={forCalendar.onCalendarChange} values={forCalendar.startEnd} />
        )}
      </div>
    </>
  );
};
export default PromoBlock;
