import React, {FC, ReactNode, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
import {uIToggleDrawer} from 'store/ui/actions';

import {Button} from 'antd';
import {useStyles} from './styles';
import Drawer from '@material-ui/core/Drawer';
import DrawerMenu from 'components/DrawerMenu';
import Icon from 'components/UI/Icon';

interface Props {
  children?: ReactNode;
  buttonTop?: any;
}

const PageWithDrawer: FC<Props> = ({children, buttonTop}) => {
  // Styling
  const styles = useStyles();
  
  // Redux
  const isDrawerOpened = useSelector((state: AppState) => state.ui.isDrawerOpened);
  
  const dispatch = useDispatch();
  
  const drawerContainer = useRef(null);
  
  const handleClose = (): void => {
    dispatch(uIToggleDrawer(false));
  };
  const handleClickMenuBurger = (): void => {
    dispatch(uIToggleDrawer());
  };
  return (
    <div ref={drawerContainer} className={styles.PageWithDrawer}>
      <Drawer
        open={isDrawerOpened}
        onClose={handleClose}
        variant="temporary"
        anchor="left"
        PaperProps={{style: {position: 'absolute'}}}
        BackdropProps={{style: {position: 'absolute'}}}
        ModalProps={drawerContainer.current ? {container: drawerContainer.current, style: {position: 'absolute'}} : {}}>
        <DrawerMenu/>
      </Drawer>
      <div className={styles.blockTable}>
        <div className={styles.headerMain}>
          <div onClick={() => handleClickMenuBurger()}>
            <div style={{cursor: 'pointer'}}>
              <Icon name="menu"/>
            </div>
          </div>
          {buttonTop ? (
            buttonTop.onClick ? (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => buttonTop.onClick()}
                className={buttonTop.className}>
                {buttonTop.title}
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" className={buttonTop.className}>
                {buttonTop.title}
              </Button>
            )
          ) : (
            <></>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageWithDrawer;
