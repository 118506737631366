import React, {FC} from 'react';
import {Button, Input} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from 'store';
import {EventsActionsRedux} from 'store/events/actions';
import {CompaniesActionsRedux} from 'store/companies/actions';
import {OpportunitiesActionsRedux} from 'store/opportunities/actions';
import {StudentsActionsRedux} from 'store/students/actions';
import {ReferralsActionsRedux} from 'store/referrals/actions';

const {Search} = Input;

interface IProps {
  styles: any;
  setValueSearch: any;
  setfilterOpen: any;
  active?: boolean;
  placeholder?: string;
  formatValue?: (v: string) => string;
}
const SearchAndFilters: FC<IProps> = ({styles, setfilterOpen, setValueSearch, active, placeholder, formatValue}) => {
  const dispatch = useDispatch();
  const {activeTab} = useSelector((state: AppState) => state.ui);
  const currentEntity = activeTab.split('/')[1];
  const currentTabState = useSelector((state: any) => state[currentEntity]);
  const entities: any = {
    events: EventsActionsRedux,
    companies: CompaniesActionsRedux,
    opportunities: OpportunitiesActionsRedux,
    students: StudentsActionsRedux,
    referrals: ReferralsActionsRedux,
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Search
        placeholder={placeholder || 'Search by name'}
        allowClear={true}
        onSearch={(value: string) => {
          const formattedValue = formatValue ? formatValue(value) : value;
          setValueSearch(formattedValue);
          dispatch(entities[currentEntity].setSearchName(formattedValue));
        }}
        className={styles.searchInput}
        defaultValue={currentTabState?.searchName}
      />
      <Button
        type="primary"
        style={{marginLeft: '4rem', backgroundColor: active ? 'blue' : undefined}}
        onClick={() => setfilterOpen(true)}>
        Set filters
      </Button>
    </div>
  );
};

export default SearchAndFilters;
