import React, {FC, useState} from 'react';
import {Table} from 'antd';
import {ModalViewMessage} from 'components/Modals';

interface Props {
  columns: any;
  entity: any;
}
const TableMessagesComponentInStudent: FC<Props> = ({columns, entity}) => {
  const [openModal, setOpenModal] = useState(false);
  const [textinModal, settextinModal] = useState('');

  const openPopupMessage = (text: any) => {
    setOpenModal(true);
    settextinModal(text);
  };
  return (
    <>
      <Table
        columns={columns(openPopupMessage)}
        dataSource={entity}
        pagination={false}
        scroll={{y: '300px'}}
        style={{marginBottom: '2rem', background:'#ffffff'}}
      />
      <ModalViewMessage open={openModal} onClose={() => setOpenModal(false)} text={textinModal} />
    </>
  );
};
export default TableMessagesComponentInStudent;
