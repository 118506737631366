import React, {FC, ReactElement} from 'react';
import {useStyles} from './styles';
import {Button} from 'antd';

interface Props {
  active: string;
  setActive: any;
}

const Switcher: FC<Props> = ({active, setActive}): ReactElement => {
  const styles = useStyles();

  return (
    <div className={styles.divSwitcher}>
      <Button type={active === 'company' ? 'primary' : 'default'} onClick={() => setActive('company')}>
        Company
      </Button>
      <Button type={active === 'opportunity' ? 'primary' : 'default'} onClick={() => setActive('opportunity')}>
        Opportunity
      </Button>
      <Button type={active === 'event' ? 'primary' : 'default'} onClick={() => setActive('event')}>
        Event
      </Button>
    </div>
  );
};

export default Switcher;
