import {makeStyles} from '@material-ui/styles';
import {theme} from '../materialThemes';

export const useStyles = makeStyles(() => {
  return {
    component: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      background: 'white',
      height: '3rem',
      padding: '0.5rem 1.5rem',
      borderRadius: '1.5rem'
    },
    remove: {
      color: theme.palette.primary.main,
      fontSize: '1.2rem',
      marginLeft: '2rem',
      cursor: 'pointer'
    }
    }
  }
);
