import React, {FC} from 'react';
import {Select} from 'antd';
 

const {Option} = Select;

interface Props {
  array: any;
  value: string;
  title: string;
  reduxFilters: any;
  onChange: any;
  styles: any;
  label: string;
}

const SelectorWithSearchAndTitle: FC<Props> = ({array, value, title, reduxFilters, onChange, label, styles}) => {
  return (
    <div className={styles.borderOneFilter}>
      <div className={styles.label}>{label}</div>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        allowClear={true}
        style={{width: '100%', paddingRight: '1rem', margin: '0.2rem 0px'}}
        onChange={onChange}
        defaultValue={reduxFilters}>
        {array?.map((item: any, index: number) => (
          <Option key={index} value={item[value]}>
            {item[title]}
          </Option>
        ))}
      </Select>
    </div>
  );
};
export default SelectorWithSearchAndTitle;
