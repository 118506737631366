/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ApolloHooksProvider} from '@apollo/react-hooks';
import {ApolloClient, InMemoryCache, defaultDataIdFromObject} from '@apollo/client';

import {useSelector} from 'react-redux';
import * as storage from 'utils/storage';
import {AppState} from 'store';
import {baseURL} from '../api/axios';
import {createUploadLink} from 'apollo-upload-client';

interface IProps {
  children: any;
}

const WithApollo = (props: IProps) => {
  const {children} = props;
  const isAuthed = useSelector((state: AppState) => state.session.isAuthed);
  const [token, setToken] = useState('');

  const link: any = useCallback(
    (token: string) =>
      createUploadLink({
        uri: baseURL + '/graphql', // TODO: use API_URL
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    [token],
  );

  const cache = useMemo(
    () =>
      new InMemoryCache({
        dataIdFromObject(responseObject) {
          switch (responseObject.__typename) {
            case 'AdminCompanies':
              return `AdminCompanies:${responseObject.limit}:${responseObject.page}:${responseObject.total}:${
                responseObject.result
              }:${Date.now()}`;
            case 'AdminCompany':
              return `AdminCompany:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'GeneralCategory':
              return `GeneralCategory:${responseObject.id}:${responseObject.name_en}:${Date.now()}`;
            case 'CompanySizeType':
              return `CompanySizeType:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'ValueType':
              return `ValueType:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'EmojieType':
              return `EmojieType:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'SocialsType':
              return `SocialsType:${responseObject.type}:${responseObject.value}:${Date.now()}`;
            case 'GeneralType':
              return `GeneralType:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'AdminOpportunities':
              return `AdminOpportunities:${responseObject.limit}:${responseObject.page}:${responseObject.total}:${
                responseObject.result
              }:${Date.now()}`;
            case 'AdminOpportunity':
              return `AdminOpportunity:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'Language':
              return `Language:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'AdminEvents':
              return `AdminEvents:${responseObject.limit}:${responseObject.page}:${responseObject.total}:${
                responseObject.result
              }:${Date.now()}`;
            case 'AdminEvent':
              return `AdminEvent:${responseObject.id}:${responseObject.name}:${Date.now()}`;
            case 'AdminStudents':
              return `AdminStudents:${responseObject.limit}:${responseObject.page}:${responseObject.total}:${
                responseObject.result
              }:${Date.now()}`;
            case 'AdminStudent':
              return `AdminStudent:${responseObject.id}:${responseObject.username}:${Date.now()}`;
            case 'AdminInstitute':
              return `AdminInstitute:${responseObject.name}:${responseObject.average_grades}:${
                responseObject.degree_name_en
              }:${Date.now()}`;
            case 'Message':
              return `Message:${responseObject.id}:${responseObject.name}:${responseObject.entity_id}:${Date.now()}`;
            case 'Messages':
              return `Messages:${responseObject.limit}:${responseObject.page}:${responseObject.total}:${
                responseObject.result
              }:${Date.now()}`;
            case 'SocialMedia':
              return `SocialMedia:${responseObject.type}:${responseObject.url}:${Date.now()}`;
            default:
              return defaultDataIdFromObject(responseObject);
          }
        },
      }),
    [token],
  );

  const client: any = new ApolloClient({
    link: link(token),
    cache,
  });

  useEffect(() => {
    setToken(storage.getTokens());
  }, [isAuthed]);

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default WithApollo;
