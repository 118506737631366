import {Event} from 'store/events/types';

export interface EventState {
  event: Event;
  id: any;
}

export enum EventActionTypes {
  EVENT_SET = 'EVENT_SET',
  EVENT_SET_ID = 'EVENT_SET_ID',
}

export interface EventSet {
  type: EventActionTypes.EVENT_SET;
  payload: any;
}

export interface EventSetID {
  type: EventActionTypes.EVENT_SET_ID;
  payload: any;
}

export type EventDetailsActions = EventSet | EventSetID;
