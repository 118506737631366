import {Reducer} from 'redux';
import {produce} from 'immer';
import {ReferralsActionTypes, ReferralsActions, ReferralsState} from './types';

const initialState: ReferralsState = {
  referralsCount: 0,
  referrals: [],
  currentPage: 1,
  limit: 20,
};

const referralsReducer: Reducer<ReferralsState, ReferralsActions> = (state = initialState, action): ReferralsState => {
  return produce(state, (draft: ReferralsState) => {
    switch (action.type) {
      case ReferralsActionTypes.REFERRALS_SET_REFERRALS:
        draft.referrals = action.payload;
        return;

      case ReferralsActionTypes.REFERRALS_SET_REFERRALS_COUNT:
        draft.referralsCount = action.payload;
        return;

      case ReferralsActionTypes.REFERRALS_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case ReferralsActionTypes.REFERRALS_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case ReferralsActionTypes.REFERRALS_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case ReferralsActionTypes.REFERRALS_SET_SEARCH_NAME:
        draft.searchName = action.payload;
        return;
      case ReferralsActionTypes.REFERRALS_SET_FILTERS:
        draft.filters = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default referralsReducer;
