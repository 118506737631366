import React, {FC} from 'react';
import {Button} from 'antd';

interface Props {
  styles: any;
  onClickReset: any;
  onClickSetFilters: any;
}

const UIButtonsWrapper: FC<Props> = ({styles, onClickSetFilters, onClickReset}: Props) => {
  // Styling

  return (
    <div className={styles.buttonsWrapperFilters}>
      <Button type="primary" className={styles.button} style={{width: '100px', marginLeft: '4rem'}} onClick={onClickReset}>
        Reset
      </Button>
      <Button type="primary" style={{width: '200px'}} onClick={onClickSetFilters}>
        Set filters
      </Button>
    </div>
  );
};

export default UIButtonsWrapper;
