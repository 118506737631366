import React, {FC} from 'react';

interface Props {
  styles: any;
  array: any;
  title: string;
}

const OneLikeComponent: FC<Props> = ({styles, array, title}) => {
  return (
    <div className={styles.divLiked}>
      <div className={styles.titleLiked}>Liked {title}</div>
      {array?.map((item: any, id: number) => {
        return (
          <div key={id}>
            {id + 1}
            {'. '}
            <span style={{wordWrap: 'break-word'}}>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default OneLikeComponent;
