import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes change': {
    '0%': {
      opacity: 0.2,
      transform: 'scale(1, 1)',
    },

    '30%': {
      opacity: 1,
      transform: 'scale(1.1, 1.1)',
    },

    '100%': {
      opacity: 0.2,
      transform: 'scale(1, 1)',
    },
  },

  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.tooltip,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  buttonLoader: {
    '& circle': {
      animationName: '$change',
      animationDuration: '1.75s',
      animationTimingFunction: 'both',
      animationIterationCount: 'infinite',

      '&:nth-child(2)': {
        animationDelay: '0.29167s',
      },

      '&:nth-child(3)': {
        animationDelay: '0.58334s',
      },

      '&:nth-child(4)': {
        animationDelay: '0.87501s',
      },
    },
  },
}));
