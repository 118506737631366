import React, {FC} from 'react';
//Components
import {Input} from 'antd';
import FormInput from 'components/UI/FormInput';
//Styling
import {useStyles} from '../styles';
import Selector from 'components/UI/Selector/SelectorFormInput';
import TreeSelector from 'components/UI/TreeSelector/TreeSelectorFormInput';

const {TextArea} = Input;

interface Props {
  optionsData: any;
}

const DetailsBlock: FC<Props> = ({optionsData}) => {
  const styles = useStyles();

  return (
    <>
      <FormInput
        name={`name`}
        className={styles.input}
        labelKey="Company Name:"
        message="Company Name is required field"
        rule={{message: 'Company Name should contain max 64 characters', max: 64}}>
        <Input placeholder="Company Name" />
      </FormInput>
      <FormInput
        name={`website`}
        className={styles.input}
        labelKey="Website:"
        rules={[
          {
            required: true,
            message: 'This field is required.',
          },
          {
            type: 'url',
            validator: async (rule: any, value: string) => {
              const res = value.match(
                /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&%'\(\)\*\+,;=.]+$/,
              );

              if (!res) {
                throw new Error('Must be a valid url');
              }
            },
          },
        ]}>
        <Input placeholder="Website" />
      </FormInput>
      {/* <div style={{display: 'flex'}}> */}
      <TreeSelector array={optionsData.availableCities} name={'cities'} label={'Cities'} />
      {/* <Selector
          array={optionsData.availableCities}
          value={'name'}
          title={'name'}
          styles={styles.input}
          name={`city`}
          label={'City'}
        /> */}
      <Selector
        array={optionsData.companyCompanySizes}
        value={'id'}
        title={'name'}
        styles={styles.input}
        name={`company_size`}
        label={'Company Size'}
      />
      {/* <FormInput
          name={`tags`}
          className={styles.input}
          labelKey="Company Tag:"
          message="Company Tag is required field">
          <Selector value={'id'} title={'name'} array={optionsData.companyTags} />
        </FormInput> */}
      {/* </div> */}

      <FormInput
        name={`bio_en`}
        message="Company Bio EN is required field"
        className={styles.input}
        labelKey="Company Bio EN:"
        tooltipText={
          'No markdown input here. This is a topmost entry for a Company which lies under Read more section.'
        }>
        <TextArea placeholder="Company Bio" style={{resize: 'none', height: '20rem'}} />
      </FormInput>
      <FormInput
        name={`bio_fr`}
        message="Company Bio FR is required field"
        tooltipText={
          'No markdown input here. This is a topmost entry for a Company which lies under Read more section.'
        }
        className={styles.input}
        labelKey="Company Bio FR:">
        <TextArea placeholder="Company Bio" style={{resize: 'none', height: '20rem'}} />
      </FormInput>
      <FormInput
        name={`bio_nl`}
        message="Company Bio NL is required field"
        tooltipText={
          'No markdown input here. This is a topmost entry for a Company which lies under Read more section.'
        }
        className={styles.input}
        labelKey="Company Bio NL:">
        <TextArea placeholder="Company Bio" style={{resize: 'none', height: '20rem'}} />
      </FormInput>
    </>
  );
};
export default DetailsBlock;
