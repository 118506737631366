import React, {FC} from 'react';
import {DatePicker} from 'antd';
import {useStyles} from './styles';

import moment from 'moment-timezone';
import {dateFormat} from 'containers/defaultFunc';

moment.tz.setDefault('Europe/Brussels');

const {RangePicker} = DatePicker;

interface Props {
  onCalendarChange: any;
  values: any;
}

const Calendar: FC<Props> = ({onCalendarChange, values}) => {
  const styles = useStyles();
  return (
    <div className={styles.block}>
      <div style={{width: '100%', paddingRight: '1rem'}}>
        <div className={styles.block}>
          <div className={styles.label}>Start date :</div>
          <div className={styles.label}>End date :</div>
        </div>
        <RangePicker
          showTime={{
            format: 'HH:mm',
          }}
          value={[moment(values[0], dateFormat), moment(values[1], dateFormat)]}
          format={dateFormat}
          onChange={(value) => {
            onCalendarChange(value);
          }}
          style={{margin: '0.4rem 0 0.4rem', width: '100%'}}
        />
      </div>
    </div>
  );
};
export default Calendar;
