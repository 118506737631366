export interface Sorter {
  order: string;
  orderType: string;
}
export class ListEntities {
  actionTypes: any;
  title: string;

  constructor(actionTypes: any, title: string) {
    this.actionTypes = actionTypes;
    this.title = title;
  }

  setEntityCount = (entitiesCount: number) => {
    return {
      type: this.actionTypes[`${this.title}_SET_${this.title}_COUNT`],
      payload: entitiesCount,
    };
  };
  setEntities = (entity: any) => {
    return {
      type: this.actionTypes[`${this.title}_SET_${this.title}`],
      payload: entity,
    };
  };
  setCurrentPage = (page: number) => {
    return {
      type: this.actionTypes[`${this.title}_SET_CURRENT_PAGE`],
      payload: page,
    };
  };
  setLimit = (limit: number) => {
    return {
      type: this.actionTypes[`${this.title}_SET_LIMIT`],
      payload: limit,
    };
  };
  setSorting = (sorting?: Sorter) => {
    return {
      type: this.actionTypes[`${this.title}_SET_SORTING`],
      payload: sorting,
    };
  };
  setSearchName = (searchName?: string) => {
    return {
      type: this.actionTypes[`${this.title}_SET_SEARCH_NAME`],
      payload: searchName,
    };
  };
  setFilters = (filters?: any) => {
    return {
      type: this.actionTypes[`${this.title}_SET_FILTERS`],
      payload: filters,
    };
  };
}
