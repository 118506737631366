import {Reducer} from 'redux';
import {produce} from 'immer';
import {CompanyActionTypes, CompanyDetailsActions, CompanyState} from './types';

export const initialState: CompanyState = {
  company: {
    id: '',
    name: '',
    website: '',
    created: '',
    city: '',
    logo: '',
    background_photo: '',
    bio_en: '',
    bio_fr: '',
    bio_nl: '',
    additional_info_en: '',
    additional_info_fr: '',
    additional_info_nl: '',
    emoji: [],
    tags: [],
    socials: [],
    sector: [],
    company_size: '',
  },
  id: null,
};

const companyDetailsReducer: Reducer<CompanyState, CompanyDetailsActions> = (
  state = initialState,
  action,
): CompanyState => {
  return produce(state, (draft: CompanyState) => {
    switch (action.type) {
      case CompanyActionTypes.COMPANY_SET:
        draft.company = action.payload;
        return;

      case CompanyActionTypes.COMPANY_SET_ID:
        draft.id = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default companyDetailsReducer;
