import gql from 'graphql-tag';

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $website: String!
    $cities: [String!]
    $bio_en: String!
    $bio_fr: String
    $bio_nl: String
    $additional_info_en: String
    $additional_info_fr: String
    $additional_info_nl: String
    $emoji: [String!]
    $socials: [SocialsInput!]
    $sectors: [String!]
    $company_size: String
    $target_archetypes: [String!]!
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
  ) {
    createCompany(
      company: {
        name: $name
        website: $website
        cities: $cities
        bio_en: $bio_en
        bio_fr: $bio_fr
        bio_nl: $bio_nl
        additional_info_en: $additional_info_en
        additional_info_fr: $additional_info_fr
        additional_info_nl: $additional_info_nl
        emoji: $emoji
        socials: $socials
        sectors: $sectors
        company_size: $company_size
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
      }
    ) {
      id
    }
  }
`;
export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: String!) {
    deleteCompany(id: $id)
  }
`;
export const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $name: String
    $website: String
    $cities: [String!]
    $bio_en: String
    $bio_fr: String
    $bio_nl: String
    $additional_info_en: String
    $additional_info_fr: String
    $additional_info_nl: String
    $emoji: [String!]
    $socials: [SocialsInput!]
    $sectors: [String!]
    $company_size: String
    $id: String!
    $target_archetypes: [String!]
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
  ) {
    updateCompany(
      company: {
        name: $name
        website: $website
        cities: $cities
        bio_en: $bio_en
        bio_fr: $bio_fr
        bio_nl: $bio_nl
        additional_info_en: $additional_info_en
        additional_info_fr: $additional_info_fr
        additional_info_nl: $additional_info_nl
        emoji: $emoji
        socials: $socials
        sectors: $sectors
        company_size: $company_size
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
      }
      id: $id
    )
  }
`;

export const UPLOAD_LOGO = gql`
  mutation UploadLogo($file: Upload!, $id: String!) {
    uploadLogo(file: $file, id: $id)
  }
`;
export const UPLOAD_BACKGROUND = gql`
  mutation UploadBackground($file: Upload!, $id: String!) {
    uploadBackground(file: $file, id: $id)
  }
`;
export const UPLOAD_BANNER = gql`
  mutation UploadBanner($file: Upload!, $id: String!) {
    uploadCompanyBanner(file: $file, id: $id)
  }
`;

export const CREATE_OPPORTUNITY = gql`
  mutation CreateOpportunity(
    $name: String!
    $company_id: String!
    $banner_img: String
    $description_en: String!
    $description_fr: String
    $description_nl: String
    $main_description_en: String
    $main_description_fr: String
    $main_description_nl: String
    $cities: [String!]
    $types: [String!]!
    $degrees: [String!]!
    $experiences: [String!]!
    $languages: [String!]!
    $published_from: DateTime
    $published_to: DateTime
    $target_archetypes: [String!]
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
    $application: AdminApplicationInputType!
  ) {
    createOpportunity(
      opportunity: {
        name: $name
        company_id: $company_id
        banner_img: $banner_img
        cities: $cities
        description_en: $description_en
        description_fr: $description_fr
        description_nl: $description_nl
        main_description_en: $main_description_en
        main_description_fr: $main_description_fr
        main_description_nl: $main_description_nl
        types: $types
        degrees: $degrees
        experiences: $experiences
        languages: $languages
        published_from: $published_from
        published_to: $published_to
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
        application: $application
      }
    ) {
      id
    }
  }
`;
export const DELETE_OPPORTUNITY = gql`
  mutation DeleteOpportunity($id: String!) {
    deleteOpportunity(id: $id)
  }
`;
export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity(
    $name: String
    $company_id: Float
    $banner_img: String
    $cities: [String!]
    $description_en: String
    $description_fr: String
    $description_nl: String
    $main_description_en: String
    $main_description_fr: String
    $main_description_nl: String
    $types: [String!]
    $degrees: [String!]
    $experiences: [String!]
    $languages: [String!]
    $id: String!
    $published_from: DateTime
    $published_to: DateTime
    $target_archetypes: [String!]
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
    $application: AdminApplicationUpdateInputType
  ) {
    updateOpportunity(
      opportunity: {
        name: $name
        company_id: $company_id
        banner_img: $banner_img
        cities: $cities
        description_en: $description_en
        description_fr: $description_fr
        description_nl: $description_nl
        main_description_en: $main_description_en
        main_description_fr: $main_description_fr
        main_description_nl: $main_description_nl
        types: $types
        degrees: $degrees
        experiences: $experiences
        languages: $languages
        published_from: $published_from
        published_to: $published_to
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
        application: $application
      }
      id: $id
    )
  }
`;

export const UPLOAD_OPPORTUNITY_BANNER = gql`
  mutation UploadBanner($file: Upload!, $id: String!) {
    uploadBanner(file: $file, id: $id)
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent(
    $name: String!
    $company_id: String!
    $start_date: DateTime!
    $end_date: DateTime!
    $banner_img: String
    $description_en: String!
    $description_fr: String
    $description_nl: String
    $main_description_en: String!
    $main_description_fr: String
    $main_description_nl: String
    $text_en: String
    $address: String!
    $types: [String!]!
    $published_from: DateTime
    $published_to: DateTime
    $target_archetypes: [String!]
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
    $application: AdminApplicationInputType!
  ) {
    createEvent(
      event: {
        name: $name
        company_id: $company_id
        banner_img: $banner_img
        start_date: $start_date
        end_date: $end_date
        description_en: $description_en
        description_fr: $description_fr
        description_nl: $description_nl
        main_description_en: $main_description_en
        main_description_fr: $main_description_fr
        main_description_nl: $main_description_nl
        text_en: $text_en
        types: $types
        address: $address
        published_from: $published_from
        published_to: $published_to
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
        application: $application
      }
    ) {
      id
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: String!) {
    deleteEvent(id: $id)
  }
`;
export const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $name: String
    $company_id: Float
    $banner_img: String
    $description_en: String
    $description_fr: String
    $description_nl: String
    $main_description_en: String
    $main_description_fr: String
    $main_description_nl: String
    $text_en: String
    $types: [String!]
    $start_date: DateTime
    $end_date: DateTime
    $address: String
    $id: String!
    $published_from: DateTime
    $published_to: DateTime
    $target_archetypes: [String!]
    $target_education_levels: [String!]
    $target_profile_interests: [String!]
    $target_degrees: [String!]
    $target_average_grades: [String!]
    $target_achievements: [String!]
    $target_international_experiences: [String!]
    $target_activities: [String!]
    $target_languages: [String!]
    $target_work_experiences: [String!]
    $bonus_feed_points: Float
    $application: AdminApplicationUpdateInputType
  ) {
    updateEvent(
      event: {
        name: $name
        company_id: $company_id
        banner_img: $banner_img
        main_description_en: $main_description_en
        main_description_fr: $main_description_fr
        main_description_nl: $main_description_nl
        description_en: $description_en
        description_fr: $description_fr
        description_nl: $description_nl
        text_en: $text_en
        types: $types
        start_date: $start_date
        end_date: $end_date
        address: $address
        published_from: $published_from
        published_to: $published_to
        target_archetypes: $target_archetypes
        target_education_levels: $target_education_levels
        target_profile_interests: $target_profile_interests
        target_degrees: $target_degrees
        target_average_grades: $target_average_grades
        target_achievements: $target_achievements
        target_international_experiences: $target_international_experiences
        target_activities: $target_activities
        target_languages: $target_languages
        target_work_experiences: $target_work_experiences
        bonus_feed_points: $bonus_feed_points
        application: $application
      }
      id: $id
    )
  }
`;

export const UPLOAD_EVENT_BANNER = gql`
  mutation UploadEventBanner($file: Upload!, $id: String!) {
    uploadEventBanner(file: $file, id: $id)
  }
`;

export const DELETE_STUDENT = gql`
  mutation DeleteStudent($id: String!) {
    deleteStudent(id: $id)
  }
`;

export const SEND_FROM_OPPORTUNITY = gql`
  mutation OpportunitySendMessageToStudents($text: String!, $students: [String!]!, $id: String!) {
    opportunitySendMessageToStudents(text: $text, students: $students, id: $id)
  }
`;
export const SEND_FROM_COMPANY = gql`
  mutation CompanySendMessageToStudents($text: String!, $students: [String!]!, $id: String!) {
    companySendMessageToStudents(text: $text, students: $students, id: $id)
  }
`;
export const SEND_FROM_EVENT = gql`
  mutation EventSendMessageToStudents($text: String!, $students: [String!]!, $id: String!) {
    eventSendMessageToStudents(text: $text, students: $students, id: $id)
  }
`;

export const SYNC_EVENT = gql`
  mutation RefreshEventData($field: String!) {
    refreshEventData(field: $field)
  }
`;
export const SYNC_COMPANY = gql`
  mutation RefreshCompanyData($field: String!) {
    refreshCompanyData(field: $field)
  }
`;
export const SYNC_OPPORTUNITY = gql`
  mutation RefreshOpportunityData($field: String!) {
    refreshOpportunityData(field: $field)
  }
`;
export const SYNC_STUDENT = gql`
  mutation RefreshStudentData($field: String!) {
    refreshStudentData(field: $field)
  }
`;
export const COMPANY_PROMOTION = gql`
  mutation CompanyPublication($publication: CompanyPublication!, $id: String!) {
    companyPublication(publication: $publication, id: $id)
  }
`;
export const CREATE_REFERRAL = gql`
  mutation CreateReferral($referral: ReferralInputType!) {
    createReferral(referral: $referral) {
      code
      id
      type
      created
      updated
    }
  }
`;
export const DELETE_REFERRAL = gql`
  mutation DeleteReferral($id: Float!) {
    deleteReferral(id: $id)
  }
`;

export const UPDATE_STUDENT_REFERRAL = gql`
  mutation updateStudentReferral($referralCode: String!, $studentId: Float!) {
    updateStudentReferral(referralCode: $referralCode, studentId: $studentId)
  }
`;
