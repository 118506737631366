import React, {FC} from 'react';
//Components
import {Input} from 'antd';
import FormInput from 'components/UI/FormInput';
//Styling
import {useStyles} from '../styles';

//Functions, utils
import TreeSelector from 'components/UI/TreeSelector/TreeSelectorFormInput';

interface Props {
  dataForSelectors: {
    archetypes: any[];
    educationLevels: any[];
    profileInterests: any[];
    degrees: any[];
    grades: any[];
    achievements: any[];
    internationalExperiences: any[];
    activities: any[];
    languages: any[];
    workingExperiences: any[];
  };
}

const TargetBlock: FC<Props> = ({dataForSelectors}) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.title}>Feed targeting</div>
      <TreeSelector array={dataForSelectors.archetypes} name={'target_archetypes'} label={'Target Archetypes'} />
      <TreeSelector
        optional
        array={dataForSelectors.educationLevels}
        name={'target_education_levels'}
        label={'Target Persona'}
      />
      <TreeSelector
        optional
        array={dataForSelectors.profileInterests}
        name={'target_profile_interests'}
        label={'Target Interests'}
      />
      <TreeSelector optional array={dataForSelectors.degrees} name={'target_degrees'} label={'Target Degrees'} />
      <TreeSelector optional array={dataForSelectors.grades} name={'target_average_grades'} label={'Target Grades'} />
      <TreeSelector
        optional
        array={dataForSelectors.achievements}
        name={'target_achievements'}
        label={'Target Specific Skills'}
      />
      <TreeSelector
        optional
        array={dataForSelectors.internationalExperiences}
        name={'target_international_experiences'}
        label={'Target International Experience'}
      />
      <TreeSelector
        optional
        array={dataForSelectors.activities}
        name={'target_activities'}
        label={'Target Activities'}
      />
      <TreeSelector optional array={dataForSelectors.languages} name={'target_languages'} label={'Target Languages'} />
      <TreeSelector
        optional
        array={dataForSelectors.workingExperiences}
        name={'target_work_experiences'}
        label={'Target Work Experience'}
      />
      <FormInput name={`bonus_feed_points`} className={styles.input} labelKey={`Bonus feed points:`}>
        <Input type="number" placeholder={`Points`} />
      </FormInput>
    </>
  );
};
export default TargetBlock;
