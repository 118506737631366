/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC} from 'react';
//Components
import Dialog from '@material-ui/core/Dialog';
import MDEditor, {commands} from '@uiw/react-md-editor';
import Icon from 'components/UI/Icon';
//Styling
import {useStyles} from './styles';

interface Props {
  open: boolean;
  onClose: any;
  text: string;
}

const ModalViewMessage: FC<Props> = ({onClose, open, text}: Props) => {
  // Styling
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.modalMessageBlock}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={styles.modalHeader2}>Text of message</div>

        <MDEditor
          style={{width: '100%'}}
          previewOptions={{escapeHtml: true}}
          textareaProps={{readOnly: true}}
          commands={[commands.codeLive, commands.divider, commands.fullscreen]}
          height={200}
          value={text}
        />
        {/* <div>{text}</div> */}
      </div>
    </Dialog>
  );
};

export default ModalViewMessage;
