/* eslint-disable react/display-name */
import React from 'react';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Brussels');

export const columns = (goToCompany: any, deleteDrop: any, studentsLiked: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200px',
    sorter: true,
    render: (text: string, record: any) => {
      return (
        <div
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            goToCompany(record.id);
          }}>
          {text}
        </div>
      );
    },
  },
  {
    title: 'Cities',
    dataIndex: 'cities',
    key: 'cities',
    width: '150px',
    sorter: true,
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item}</div>;
      });
    },
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: '250px',
    sorter: true,
    render: (value: any) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    },
  },
  {
    title: 'Type',
    dataIndex: 'types',
    key: 'types',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item.name_en}</div>;
      });
    },
  },
  {
    title: 'Sectors',
    dataIndex: 'sectors',
    key: 'sectors',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item.name_en}</div>;
      });
    },
  },
  {
    title: 'Experience',
    dataIndex: 'experiences',
    key: 'experiences',
    width: '150px',
    sorter: true,
    render: (value: any) => {
      return value.map((item: any, id: any) => {
        return <div key={id}>{item.name}</div>;
      });
    },
  },
  {
    title: 'Languages required',
    dataIndex: 'languages',
    key: 'languages',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name}</div>;
      });
    },
  },
  {
    title: 'Company name',
    dataIndex: 'company_name',
    key: 'company_name',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Number of likes',
    dataIndex: 'likes_total',
    key: 'likes_total',
    width: '150px',
    sorter: true,
    render: (text: string, record: any) => {
      return (
        <div
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            studentsLiked({id: '' + record.id, type: '2'});
          }}>
          {text}
        </div>
      );
    },
  },
  {
    title: 'Number of visits',
    dataIndex: 'visits_total',
    key: 'visits_total',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Feed appearances',
    dataIndex: 'feed_appearances',
    key: 'feed_appearances',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Degrees',
    dataIndex: 'degrees',
    key: 'degrees',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    width: '10rem',
    title: '',
    dataIndex: 'operation',
    render: (_: any, record: any) => {
      return (
        <div
          style={{
            appearance: 'none',
            height: '3rem',
            border: 'none',
            display: 'flex',
            fontSize: '1.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F65532',
            color: 'white',
            width: '6rem',
            cursor: 'pointer',
            marginRight: '8px',
          }}
          onClick={() => {
            deleteDrop(record.id);
          }}>
          Delete
        </div>
      );
    },
  },
];
