import {createMuiTheme} from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {
    fontFamily: ['Roboto', '"SF-Pro-Display-Regular"'].join(','),
  },
  palette: {
    primary: {
      main: '#F65532',
      dark: '#ea372a',
    },
    error: {
      contrastText: '#fff',
      dark: '#d32f2f',
      light: '#f95e4b',
      main: '#D9001B',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    background: {
      default: '#F0F2F5',
      paper: '#FFFFFF',
    },
  },
});

export const constStyles: any = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  borderTable1: {
    background: '#ffffff',
    width: '100%',
    marginBottom: '40px',
  },
  container: {
    padding: '1rem 3rem 3rem',
    display: 'flex',
    width: '100%',
  },
  customButton: {
    appearance: 'none',
    height: '3rem',
    border: 'none',
    display: 'flex',
    fontSize: '1.4rem',
    //width: '14rem',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
    marginRight: '8px',
    padding: '0 2.5rem',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:focus': {
      background: theme.palette.primary.dark,
    },
  },
  blockTable: {
    width: '100%',
  },
  headerMain: {
    height: '6rem',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    padding: '2rem',
    marginBottom: '2rem',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.7rem',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  labelDetails: {
    fontSize: '1.4rem',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.85)',
  },
};
