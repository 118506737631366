/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {useHistory} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from 'store';
//Actions
import {OpportunityActionsRedux} from 'store/opportunityDetails/actions';
//GraphQl
import {useMutation, useQuery} from '@apollo/client';
import {CREATE_OPPORTUNITY, UPLOAD_OPPORTUNITY_BANNER, UPDATE_OPPORTUNITY} from 'requests/mutations';
import {GET_OPPORTUNITY_OPTIONS, GET_OPPORTUNITY, GET_COMPANIES_IDNAME} from 'requests/queries';
//Styling
import {useStyles} from '../defaultStylesSinglePage';
//Components
import {DetailsBlock, DetailsBlock2} from 'components/OpportunityDetails';
import {UploadPhotos, TargetBlock} from 'components/CompanyDetails';
import PageWithDrawer from 'components/PageWithDrawer';
import {SuccessModal} from 'components/Modals';
import LoadingComponent from 'components/LoadingComponent';
import Loader from 'components/UI/Loader';
import {Form, Button} from 'antd';
//Functions, utils
import {urlTofile} from 'utils/urlTofile';
import {NotificationTypes, showNotification} from 'utils/notifications';
import {handleChangeImage, closeModal, catchError} from 'containers/defaultFunc';
import moment from 'moment-timezone';
import {dateFormat} from 'containers/defaultFunc';
import {setSearchCom} from 'store/messages/actions';

moment.tz.setDefault('Europe/Brussels');

const mapSelectors = (selectors: any) =>
  selectors?.map((item: any) => {
    return item.id;
  });
interface IMessageTranslation {
  language: 'en' | 'nl' | 'fr';
  translation: string;
}
const OpportunityPage: FC = () => {
  const styles = useStyles();

  const history: any = useHistory();
  const states: any = history.location.state;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const idOpportunity: any = useSelector((state: AppState) => state.opportunityDetails.id) ?? '';

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const isUpdatePage = React.useMemo(() => window.location.pathname === '/opportunities/details', []);
  const id = React.useMemo(() => (!!history.location.state && states.id) || '', []);

  const [banner, setBanner] = useState<any>('');
  const [bannerCache, setBannerCache] = useState<any>('');
  const [addPromo, setAddPromo] = useState(false);
  const [startEndPromo, setstartEndPromo] = useState([moment(), moment()]);
  const [activePromo, setActivePromo] = useState(false);
  const [showRedirectUrl, setShowRedirectUrl] = useState(false);

  const [types, setTypes] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [cities, setCities] = useState([]);

  const [archetypes, setArchetypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [profileInterests, setProfileInterests] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [grades, setGrades] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [internationalExperiences, setInternationalExperiences] = useState([]);
  const [activities, setActivities] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [workingExperiences, setWorkingExperiences] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [allCompanies, setAllCompanies] = useState([]);
  const searchCom: string | undefined = useSelector((state: AppState) => state.messages).searchCom;
  const [prevSearchCom, setPrevSearchCom] = useState<string>('');

  const [forSetOpportunityOptionsData, setOpportunityOptionsData] = useState({
    companies: [],
    availableCities: [],
  });

  const {data: opportunityOptionsData, error: errorOpportunityOptions} = useQuery(GET_OPPORTUNITY_OPTIONS);
  const {data: companiesData, error: errorCompanies, fetchMore} = useQuery(GET_COMPANIES_IDNAME, {
    variables: {
      limit: 20,
      page: companiesPage,
      orderDirection: 'asc',
      order: 'name',
      search: {name: searchCom},
    },
  });

  const {data: opportunityMainData, error: errorMainOpportunities} = useQuery(GET_OPPORTUNITY, {
    variables: {id: parseInt(id)},
    skip: id === null || id === '',
  });

  const fetchMoreCompanies = async () => {
    fetchMore({
      variables: {
        page: companiesPage + 1,
        limit: 20,
        search: {name: searchCom},
      },
    });
    setCompaniesPage(companiesPage + 1);
  };

  useEffect(() => {
    if (
      opportunityMainData &&
      opportunityMainData.opportunity &&
      opportunityMainData.opportunity.application_flow === 'REDIRECT'
    ) {
      setShowRedirectUrl(true);
    }
  }, [opportunityMainData]);

  useEffect(() => {
    setAllCompanies([]);
    setCompaniesPage(1);
  }, [searchCom]);

  useEffect(() => {
    if (
      allCompanies.length &&
      companiesData?.companies?.result !== undefined &&
      (prevSearchCom === searchCom || searchCom === undefined)
    ) {
      setAllCompanies(allCompanies?.concat(companiesData?.companies?.result));
    } else if (companiesData?.companies?.result !== undefined) {
      setAllCompanies(companiesData?.companies?.result);
      if (prevSearchCom !== searchCom && searchCom) {
        setPrevSearchCom(searchCom);
      }
    }
  }, [companiesData]);
  const cachedOpportunityOptionsData = React.useMemo(() => opportunityOptionsData, [opportunityOptionsData]);
  const cachedCompaniesData = React.useMemo(() => companiesData, [companiesData]);
  const cachedOpportunityMainData = React.useMemo(() => opportunityMainData, [opportunityMainData]);

  const [createOpportunity] = useMutation(CREATE_OPPORTUNITY);
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);

  const [uploadBanner] = useMutation(UPLOAD_OPPORTUNITY_BANNER);

  useEffect(() => {
    if (isUpdatePage) {
      id && history.replace(history.location.pathname, {...history.location.state, id});
    }
  }, [isUpdatePage, id]);

  useEffect(() => {
    if (cachedOpportunityOptionsData) {
      const newTypes = cachedOpportunityOptionsData.opportunityTypes?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      const newExperiences = cachedOpportunityOptionsData.opportunityExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });

      setTypes(newTypes);
      setExperiences(newExperiences);

      const newArchetypes = cachedOpportunityOptionsData.availableArchetypes?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setArchetypes(newArchetypes);
      const newEducationLevels = cachedOpportunityOptionsData.availableEducationLevels?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setEducationLevels(newEducationLevels);
      const newProfileInterests = cachedOpportunityOptionsData.availableInterests?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setProfileInterests(newProfileInterests);
      const newDegrees = cachedOpportunityOptionsData.availableDegrees?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setDegrees(newDegrees);
      const newGrades = cachedOpportunityOptionsData.availableEducationalGrades?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setGrades(newGrades);
      const newAchievements = cachedOpportunityOptionsData.availableAchievements?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setAchievements(newAchievements);
      const newInternationalExperiences = cachedOpportunityOptionsData.availableInternationalExperiences?.map(
        (item: any) => {
          return {title: item.name_en, value: item.id};
        },
      );
      setInternationalExperiences(newInternationalExperiences);
      const newActivities = cachedOpportunityOptionsData.availableActivities?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setActivities(newActivities);
      const newLanguages = cachedOpportunityOptionsData.availableLanguages?.map((item: any) => {
        return {title: item.name, value: item.id};
      });
      setLanguages(newLanguages);
      const newWorkingExperiences = cachedOpportunityOptionsData.availableWorkingExperiences?.map((item: any) => {
        return {title: item.name_en, value: item.id};
      });
      setWorkingExperiences(newWorkingExperiences);

      const newCities = cachedOpportunityOptionsData.availableCities?.map((item: any) => {
        return {title: item.name, value: item.name};
      });
      setCities(newCities);

      if (cachedCompaniesData) {
        setOpportunityOptionsData({
          companies: allCompanies,
          availableCities: cachedOpportunityOptionsData.availableCities,
        });
      } else {
        setOpportunityOptionsData({
          ...forSetOpportunityOptionsData,
          availableCities: cachedOpportunityOptionsData.availableCities,
        });
      }
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [cachedOpportunityOptionsData, allCompanies]);
  useEffect(() => {
    if (isUpdatePage) {
      if (cachedOpportunityMainData) {
        const {opportunity} = cachedOpportunityMainData;
        dispatch(OpportunityActionsRedux.setEntity(opportunity));
        dispatch(OpportunityActionsRedux.setEntityId(opportunity.id));

        form.setFieldsValue({
          [`name`]: opportunity.name,
          [`company_id`]: opportunity.company_name,
          [`cities`]: opportunity.cities,
          [`description_en`]: opportunity.description_en ? opportunity.description_en : '',
          [`description_fr`]: opportunity.description_fr ? opportunity.description_fr : '',
          [`description_nl`]: opportunity.description_nl ? opportunity.description_nl : '',

          [`main_description_en`]: opportunity.main_description_en ? opportunity.main_description_en : '',
          [`main_description_fr`]: opportunity.main_description_fr ? opportunity.main_description_fr : '',
          [`main_description_nl`]: opportunity.main_description_nl ? opportunity.main_description_nl : '',

          [`text_en`]: opportunity.text_en ? opportunity.text_en : '',
          // [`text_message_fr`]: opportunity.text_message_fr,
          // [`text_message_nl`]: opportunity.text_message_nl,

          [`types`]: opportunity.types?.map((item: any) => {
            return item.id || item;
          }),
          [`experiences`]: opportunity.experiences?.map((item: any) => {
            return item.id || item;
          }),
          [`languages`]: opportunity.languages?.map((item: any) => {
            return item.id || item;
          }),
          [`degrees`]: opportunity.degrees?.map((item: any) => {
            return item.id || item;
          }),
          [`target_archetypes`]: mapSelectors(opportunity.target_archetypes),
          [`target_education_levels`]: mapSelectors(opportunity.target_education_levels),
          [`target_profile_interests`]: mapSelectors(opportunity.target_profile_interests),
          [`target_degrees`]: mapSelectors(opportunity.target_degrees),
          [`target_average_grades`]: mapSelectors(opportunity.target_average_grades),
          [`target_achievements`]: mapSelectors(opportunity.target_achievements),
          [`target_international_experiences`]: mapSelectors(opportunity.target_international_experiences),
          [`target_activities`]: mapSelectors(opportunity.target_activities),
          [`target_languages`]: mapSelectors(opportunity.target_languages),
          [`target_work_experiences`]: mapSelectors(opportunity.target_work_experiences),
          [`bonus_feed_points`]: opportunity.bonus_feed_points,
          [`message_en`]: opportunity.application.messageTranslations.filter(
            (item: IMessageTranslation) => item.language === 'en',
          )[0].translation
            ? opportunity.application.messageTranslations.filter(
                (item: IMessageTranslation) => item.language === 'en',
              )[0].translation
            : '',
          // [`message_nl`]: opportunity.application.messageTranslations.filter(
          //   (item: IMessageTranslation) => item.language === 'nl',
          // )[0].translation
          //   ? opportunity.application.messageTranslations.filter(
          //       (item: IMessageTranslation) => item.language === 'nl',
          //     )[0].translation
          //   : '',
          // [`message_fr`]: opportunity.application.messageTranslations.filter(
          //   (item: IMessageTranslation) => item.language === 'fr',
          // )[0].translation
          //   ? opportunity.application.messageTranslations.filter(
          //       (item: IMessageTranslation) => item.language === 'fr',
          //     )[0].translation
          //   : '',
          [`redirection_link`]: opportunity.application.redirectUrl ? opportunity.application.redirectUrl : '',
          [`company_email`]: opportunity.application.companyEmail ? opportunity.application.companyEmail : '',
          [`buddy_email`]: opportunity.application.buddyEmail
            ? opportunity.application.buddyEmail
            : 'buddy@launch.career',
        });

        setBanner(opportunity.banner_img);

        if (opportunity.published_from && opportunity.published_to) {
          setstartEndPromo([moment(opportunity.published_from), moment(opportunity.published_to)]);
          setAddPromo(true);
          const newD = moment(moment.now()).format(dateFormat);
          const start = moment(moment(opportunity.published_from).format(dateFormat)).isSameOrBefore(newD);
          const end = moment(moment(opportunity.published_to).format(dateFormat)).isSameOrAfter(newD);
          if (start && end) {
            setActivePromo(true);
          } else {
            setActivePromo(false);
          }
        } else {
          setAddPromo(false);
        }
      }
    } else {
      form.setFieldsValue({
        [`application_flow`]: 'MESSAGE',
        [`buddy_email`]: 'buddy@launch.career',
      });
    }
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [cachedOpportunityMainData, isUpdatePage]);

  // const setBannerOp = (image: string) => {
  //   setBanner(image);
  //   setBannerCache(image);
  // };

  const dataUpdateCreateOpportunity = React.useCallback(
    (data: any) => {
      return {
        cities: data.cities,
        name: data.name,

        description_en: data.description_en,
        description_fr: data.description_fr || '',
        description_nl: data.description_nl || '',

        main_description_en: data.main_description_en,
        main_description_fr: data.main_description_fr || '',
        main_description_nl: data.main_description_nl || '',

        text_en: data.text_en,
        // text_message_fr: data.text_message_fr || '',
        // text_message_nl: data.text_message_nl || '',

        degrees: typeof data.degrees === 'string' ? Array(data.degrees) : data.degrees,
        types: data.types,
        languages: data.languages?.map((item: any) => {
          return item.key || item.toString();
        }),
        published_from: moment(startEndPromo[0]),
        published_to: moment(startEndPromo[1]),
        experiences: typeof data.experiences === 'string' ? Array(data.experiences) : data.experiences,
        target_archetypes: data.target_archetypes,
        target_education_levels: data.target_education_levels,
        target_profile_interests: data.target_profile_interests,
        target_degrees: data.target_degrees,
        target_average_grades: data.target_average_grades,
        target_achievements: data.target_achievements,
        target_international_experiences: data.target_international_experiences,
        target_activities: data.target_activities,
        target_languages: data.target_languages?.map((i: number) => i.toString()),
        target_work_experiences: data.target_work_experiences,
        bonus_feed_points: Number(data.bonus_feed_points),
        application: {
          redirectUrl: data.redirection_link,
          buddyEmail: data.buddy_email,
          companyEmail: data.company_email,
          messageTranslations: [
            {
              language: 'en',
              translation: data.message_en,
            },
            // {
            //   language: 'fr',
            //   translation: data.message_fr ? data.message_fr : '',
            // },
            // {
            //   language: 'nl',
            //   translation: data.message_nl ? data.message_nl : '',
            // },
          ],
        },
      };
    },
    [moment(startEndPromo[0]), moment(startEndPromo[1])],
  );

  async function onSearch(value: string | undefined) {
    dispatch(setSearchCom(value ? value : undefined));
  }
  const handleOnchangeCompany = () => {
    dispatch(setSearchCom(undefined));
  };
  const onSubmit = React.useCallback(
    (data: any) => {
      const submit = async () => {
        const parseStr = data.text_en ? data.text_en.split('\n') : null;
        if (parseStr && parseStr.length > 0 && parseStr[0]?.length > 80) {
          showNotification(
            NotificationTypes.info,
            'First line of Text message EN must contain text without markdown and max 80 characters',
          );
        } else if (banner === '' || banner === null || banner.includes('data:')) {
          showNotification(NotificationTypes.info, 'Banner is required, please select image or crop');
        } else {
          try {
            setLoader(true);
            let response;
            if (isUpdatePage) {
              response = await updateOpportunity({
                variables: {
                  company_id:
                    typeof data.company_id === 'number'
                      ? data.company_id
                      : cachedOpportunityMainData.opportunity.company_id,
                  id: id.toString(),
                  ...dataUpdateCreateOpportunity(data),
                },
              });
            } else {
              response = await createOpportunity({
                variables: {
                  company_id: data.company_id.toString(),
                  ...dataUpdateCreateOpportunity(data),
                },
              });
            }
            if (response.data) {
              if (!banner.startsWith('https://')) {
                const banner1 = await urlTofile(banner, `banner${data.name}${new Date().toISOString()}`);
                const responseLOGO = await uploadBanner({
                  variables: {
                    file: banner1,
                    id: isUpdatePage
                      ? response.data.updateOpportunity.toString()
                      : response.data.createOpportunity.id.toString(),
                  },
                });
                if (responseLOGO.data) {
                  setLoader(false);
                  setIsOpenModal(true);
                }
              } else {
                setLoader(false);
                setIsOpenModal(true);
              }
            }
          } catch (e) {
            catchError(setLoader, isUpdatePage, 'opportunity', e);
          }
        }
      };

      submit();
    },
    [dataUpdateCreateOpportunity, banner, isUpdatePage],
  );

  const onCalendarChangePropmo = React.useCallback((dateString: any) => {
    if (dateString[0] === '' || dateString[1] === '') {
      setstartEndPromo([moment(), moment()]);
    } else {
      setstartEndPromo(dateString);
    }
  }, []);

  const handleFormChange = React.useCallback((values) => {
    if (values && values.application_flow) {
      if (values.application_flow === 'REDIRECT') {
        setShowRedirectUrl(true);
      } else {
        setShowRedirectUrl(false);
      }
    }
  }, []);
  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {errorOpportunityOptions || errorCompanies || errorMainOpportunities ? (
          <LoadingComponent goToEntity="opportunities" />
        ) : (
          <Form form={form} onFinish={onSubmit} style={{overflowX: 'scroll'}} onValuesChange={handleFormChange}>
            <div className={styles.divButton}>
              <Button type="primary" htmlType="submit" className={styles.customButton}>
                Save opportunity
              </Button>
            </div>

            <div className={styles.borderTable}>
              <div className={styles.container}>
                <div className={styles.left}>
                  <div style={{width: '11.7rem'}}>
                    <UploadPhotos
                      hasCache={!!bannerCache}
                      onChange={handleChangeImage(setBanner)}
                      onLoad={handleChangeImage(setBanner)}
                      image={banner}
                      redoImage={() => setBanner(bannerCache)}
                      deleteImage={() => setBanner('')}
                      title={'Banner photo'}
                      aspectValue={5 / 2}
                      tooltipText={
                        'Photo to be used at the top of Opportunity details page and as preview for Company cards.'
                      }
                    />
                  </div>
                  <DetailsBlock
                    optionsData={{
                      listOfCompanies: forSetOpportunityOptionsData.companies,
                      availableCities: cities,
                    }}
                    handleOnchangeCompany={handleOnchangeCompany}
                    fetchSearchCompanies={onSearch}
                    fetchMoreCompanies={fetchMoreCompanies}
                    dataForSelectors={{
                      type: {data: types},
                      degree: {data: degrees},
                      experience: {data: experiences},
                      language: {data: languages},
                    }}
                  />

                  <div style={{height: '11.2rem'}} />
                  <TargetBlock
                    dataForSelectors={{
                      archetypes,
                      educationLevels,
                      profileInterests,
                      degrees,
                      grades,
                      achievements,
                      internationalExperiences,
                      activities,
                      languages,
                      workingExperiences,
                    }}
                  />
                </div>
                <div className={styles.right}>
                  <div style={{height: '13.5rem'}} />
                  <DetailsBlock2
                    promo={{addPromo, setAddPromo, activePromo}}
                    forCalendar={{
                      onCalendarChange: onCalendarChangePropmo,
                      startEnd: startEndPromo,
                    }}
                    showRedirectURLInput={showRedirectUrl}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
        <SuccessModal
          onClose={() => closeModal('opportunities')}
          open={isOpenModal}
          messageKey={`Opportunity was successful ${isUpdatePage ? 'updated' : 'created'}`}
        />
      </PageWithDrawer>
      {loader && (
        <Portal>
          <Loader loader={loader} />
        </Portal>
      )}
    </div>
  );
};

export default React.memo(OpportunityPage);
