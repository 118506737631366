import React, {ReactElement, memo} from 'react';
import calculateSideByRatio from 'utils/calculateSideByRatio';

import {ReactNode} from 'react';

export interface Props {
  name: string;
  color?: string;
  children?: ReactNode;
  size?: number;
  styles?: any;
}

function renderIcon(name: string, color?: string, styles?: any, size?: any): JSX.Element {
  switch (name) {
    case 'close':
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" style={styles} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.41406 0L0 1.41406L7.29297 8.70703L0 16L1.41406 17.4141L8.70703 10.1211L16 17.4141L17.4141 16L10.1211 8.70703L17.4141 1.41406L16 0L8.70703 7.29297L1.41406 0Z"
            fill="#666666"
          />
        </svg>
      );

    case 'logout':
      return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" style={styles} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.4793 13.7012H15.3384C15.2494 13.7012 15.1751 13.7754 15.1751 13.8645V15.175H1.82212V1.81984H15.1773V3.13033C15.1773 3.2194 15.2515 3.29362 15.3405 3.29362H16.4814C16.5704 3.29362 16.6447 3.22152 16.6447 3.13033V1.00556C16.6447 0.645067 16.3542 0.354553 15.9937 0.354553H1.00783C0.64734 0.354553 0.356827 0.645067 0.356827 1.00556V15.9893C0.356827 16.3498 0.64734 16.6403 1.00783 16.6403H15.9915C16.352 16.6403 16.6425 16.3498 16.6425 15.9893V13.8645C16.6425 13.7733 16.5683 13.7012 16.4793 13.7012ZM16.8737 8.36382L13.8646 5.98882C13.7523 5.89975 13.589 5.98033 13.589 6.12241V7.73402H6.93049C6.83719 7.73402 6.76085 7.81036 6.76085 7.90366V9.09116C6.76085 9.18446 6.83719 9.2608 6.93049 9.2608H13.589V10.8724C13.589 11.0145 13.7544 11.0951 13.8646 11.006L16.8737 8.631C16.894 8.61514 16.9104 8.59486 16.9216 8.57171C16.9329 8.54857 16.9388 8.52316 16.9388 8.49741C16.9388 8.47166 16.9329 8.44625 16.9216 8.42311C16.9104 8.39996 16.894 8.37969 16.8737 8.36382Z"
            fill="white"
          />
        </svg>
      );

    case 'menu': {
      return (
        <svg width="46" height="23" viewBox="0 0 46 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.43753 2.87494H44.5627C45.3563 2.87494 46.0002 2.23089 46.0002 1.43747C46.0002 0.643935 45.3562 0 44.5627 0H1.43753C0.643996 0 6.10352e-05 0.644055 6.10352e-05 1.43747C6.10352e-05 2.23089 0.644115 2.87494 1.43753 2.87494Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M44.5627 10.0625H1.43747C0.643935 10.0625 0 10.7066 0 11.5C0 12.2934 0.644054 12.9375 1.43747 12.9375H44.5627C45.3562 12.9375 46.0002 12.2934 46.0002 11.5C46.0002 10.7066 45.3562 10.0625 44.5627 10.0625Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M44.5626 20.1251H1.4374C0.643862 20.1251 -7.24792e-05 20.7691 -7.24792e-05 21.5625C-7.24792e-05 22.356 0.643982 23 1.4374 23H44.5626C45.3562 23 46.0001 22.356 46.0001 21.5625C46.0002 20.769 45.3562 20.1251 44.5626 20.1251Z"
            fill="url(#paint2_linear)"
          />
          <defs>
            <linearGradient id="paint0_linear" x1="-2.5" y1="23" x2="46" y2="1" gradientUnits="userSpaceOnUse">
              <stop offset="0.0558073" stopColor="#FF2F70" />
              <stop offset="1" stopColor="#FE5205" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="-2.5" y1="23" x2="46" y2="1" gradientUnits="userSpaceOnUse">
              <stop offset="0.0558073" stopColor="#FF2F70" />
              <stop offset="1" stopColor="#FE5205" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="-2.5" y1="23" x2="46" y2="1" gradientUnits="userSpaceOnUse">
              <stop offset="0.0558073" stopColor="#FF2F70" />
              <stop offset="1" stopColor="#FE5205" />
            </linearGradient>
          </defs>
        </svg>
      );
    }
    case 'roundedTick':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={calculateSideByRatio(size || 100, 1.1578)}
          height={size || 100}
          viewBox="0 0 300 300">
          <g>
            <g>
              <path
                className={styles.primaryColor}
                d="M150,0C67.5,0,0,67.5,0,150s67.5,150,150,150s150-67.5,150-150S232.5,0,150,0z M150,270c-66,0-120-54-120-120    S84,30,150,30s120,54,120,120S216,270,150,270z"
              />
              <path className={styles.primaryColor} d="M219,90l-99,99l-39-39l-21,21l60,60l120-120L219,90z" />
            </g>
          </g>
        </svg>
      );

    default:
      return <></>;
  }
}

const Icon: React.FC<Props> = ({name, color, styles = {}, size}: Props): ReactElement => {
  return renderIcon(name, color, styles, size);
};

export default memo(Icon);
