import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  component: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem'
  },
  title: {
    fontSize: '1.4rem',
    marginLeft: '2rem'
  }
}));
