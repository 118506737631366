export interface UIState {
  isLoading: boolean;
  activeTab: string;
  isDrawerOpened: boolean;
}

export enum UIActionTypes {
  UI_LOADING_START = 'UI_LOADING_START',
  UI_LOADING_FINISH = 'UI_LOADING_FINISH',
  UI_SET_ACTIVE_TAB = 'UI_SET_ACTIVE_TAB',
  UI_TOGGLE_DRAWER = 'UI_TOGGLE_DRAWER',
}

interface UILoadingStartAction {
  type: UIActionTypes.UI_LOADING_START;
}

interface UILoadingFinishAction {
  type: UIActionTypes.UI_LOADING_FINISH;
}
interface UILoadingSetActiveTabAction {
  type: UIActionTypes.UI_SET_ACTIVE_TAB;
  payload: string;
}
interface UIToggleDrawerAction {
  type: UIActionTypes.UI_TOGGLE_DRAWER;
  payload?: boolean | undefined;
}
export type UIActions =
  | UILoadingStartAction
  | UILoadingFinishAction
  | UILoadingSetActiveTabAction
  | UIToggleDrawerAction;
