import React from 'react';
import {Table} from 'antd';
import {Referral} from 'store/referrals/types';

interface Props {
  deleteItem: (id: string) => void;
  columns: any;
  entity: Referral[];
  onChangeTable: (arg: any) => void;
}

const SCROLL = {y: '72vh'};

const TableReferralsComponent: React.FC<Props> = ({deleteItem, columns, entity, onChangeTable}) => {
  const sorting = React.useCallback(
    (_a: any, _b: any, sorter: any) => {
      const newOrder =
        sorter.order === undefined
          ? {order: undefined, orderType: undefined}
          : sorter.order === 'descend'
          ? {order: sorter.columnKey, orderType: 'desc'}
          : {order: sorter.columnKey, orderType: 'asc'};

      onChangeTable(newOrder);
    },
    [onChangeTable],
  );

  const columnsRes = React.useMemo(() => columns(deleteItem), [columns, deleteItem]);

  return <Table columns={columnsRes} onChange={sorting} dataSource={entity} pagination={false} scroll={SCROLL} />;
};

export default React.memo(TableReferralsComponent);
