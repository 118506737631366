import { UIActionTypes, UIActions } from './types';

/**
 * Starts loading
 */
export function uILoadingStart(): UIActions {
  return {
    type: UIActionTypes.UI_LOADING_START,
  };
}

/**
 * Finishes loading
 */
export function uILoadingFinish(): UIActions {
  return {
    type: UIActionTypes.UI_LOADING_FINISH,
  };
}

export function uISetActiveTab(activeTab: string): UIActions {
  return {
    type: UIActionTypes.UI_SET_ACTIVE_TAB,
    payload: activeTab,
  };
}

export function uIToggleDrawer(isDrawerOpened?: boolean): UIActions {
  return {
    type: UIActionTypes.UI_TOGGLE_DRAWER,
    payload: isDrawerOpened,
  };
}
