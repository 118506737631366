import {ListEntities} from 'store/classForListPage';
import {MessagesActionTypes, MessagesActions} from './types';

export const MessagesActionsRedux = new ListEntities(MessagesActionTypes, 'MESSAGES');

export const setSearchCom = (search?: string): MessagesActions => {
  return {
    type: MessagesActionTypes.MESSAGES_SET_SEARCH_COMPANIES,
    payload: search,
  };
};

export const setSearchEv = (search?: string): MessagesActions => {
  return {
    type: MessagesActionTypes.MESSAGES_SET_SEARCH_EVENTS,
    payload: search,
  };
};
export const setSearchOpp = (search?: string): MessagesActions => {
  return {
    type: MessagesActionTypes.MESSAGES_SET_SEARCH_OPPORTUNITIES,
    payload: search,
  };
};
