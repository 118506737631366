/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {setSearchCom, setSearchEv, setSearchOpp} from 'store/messages/actions';
//GraphQl
import {GET_OPPORTUNITIES_IDNAME, GET_EVENTS_IDNAME, GET_COMPANIES_IDNAME} from 'requests/queries';
import {useQuery, useMutation} from '@apollo/client';
import {SEND_FROM_OPPORTUNITY, SEND_FROM_COMPANY, SEND_FROM_EVENT} from 'requests/mutations';
//Components
import Dialog from '@material-ui/core/Dialog';
import {Button, Form, Select} from 'antd';
import Icon from 'components/UI/Icon';
import FormInput from 'components/UI/FormInput';
import RichText from 'components/UI/RichText';
import Switcher from 'components/UI/Switcher';
//Styling
import {useStyles} from './styles';
//Functions, utils
import {NotificationTypes, showNotification} from 'utils/notifications';
import {AppState} from 'store';
import {useDispatch, useSelector} from 'react-redux';

const {Option} = Select;

interface Props {
  open: boolean;
  onClose: any;
  selectedStudents: any;
  setLoader: any;
  setisSuccessModal: any;
}

const ModalMessage: FC<Props> = ({onClose, open, selectedStudents, setLoader, setisSuccessModal}: Props) => {
  // Styling
  const styles = useStyles();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [active, setActive] = useState('company');

  const [activeEntities, setactiveEntities] = useState([]);
  const [companies, setcompanies] = useState([]);
  const [opportunities, setopportunities] = useState([]);
  const [events, setevents] = useState([]);
  const {searchOpp, searchEv, searchCom}: any = useSelector((state: AppState) => state.messages);

  // const [searchOpp, setsearchOpp] = useState(undefined);
  // const [searchEv, setsearchEv] = useState(undefined);
  // const [searchCom, setsearchCom] = useState(undefined);

  const [sendFromOpportunity] = useMutation(SEND_FROM_OPPORTUNITY);
  const [sendFromCompany] = useMutation(SEND_FROM_COMPANY);
  const [sendFromEvent] = useMutation(SEND_FROM_EVENT);

  const {data: companiesData} = useQuery(GET_COMPANIES_IDNAME, {
    variables: {
      limit: 10,
      page: 1,
      orderDirection: 'asc',
      order: 'name',
      search: {name: searchCom},
    },
    skip: !open,
  });
  const {data: opportunitiesData} = useQuery(GET_OPPORTUNITIES_IDNAME, {
    variables: {
      limit: 10,
      page: 1,
      orderDirection: 'asc',
      order: 'name',
      search: {name: searchOpp},
    },
    skip: !open,
  });
  const {data: eventsData} = useQuery(GET_EVENTS_IDNAME, {
    variables: {
      limit: 10,
      page: 1,
      orderDirection: 'asc',
      order: 'name',
      search: {name: searchEv},
    },
    skip: !open,
  });

  useEffect(() => {
    if (companiesData) {
      setcompanies(companiesData.companies.result);
      if (active === 'company') {
        setactiveEntities(companiesData.companies.result);
      }
    }
  }, [companiesData]);

  useEffect(() => {
    if (opportunitiesData) {
      setopportunities(opportunitiesData.opportunities.result);
      if (active === 'opportunity') {
        setactiveEntities(opportunitiesData.opportunities.result);
      }
    }
  }, [opportunitiesData]);

  useEffect(() => {
    if (eventsData) {
      setevents(eventsData.events.result);
      if (active === 'event') {
        setactiveEntities(eventsData.events.result);
      }
    }
  }, [eventsData]);

  useEffect(() => {
    switch (active) {
      case 'opportunity':
        setactiveEntities(opportunities);
        break;
      case 'event':
        setactiveEntities(events);
        break;
      default:
        setactiveEntities(companies);
        break;
    }
  }, [active]);
  const resetSuccessOptions = () => {
    onClose();
    setisSuccessModal(true);
    setLoader(false);
    form.setFieldsValue({
      [`company`]: '',
      [`opportunity`]: '',
      [`event`]: '',
      [`text`]: '',
    });
  };
  const onSubmit = async (data: any) => {
    const parseStr = data.text.split('\n');
    if (parseStr.length > 0 && parseStr[0]?.length > 80) {
      showNotification(NotificationTypes.info, 'First line must contain text without markdown and max 80 characters');
    } else setLoader(true);
    switch (active) {
      case 'opportunity':
        {
          const response = await sendFromOpportunity({
            variables: {
              id: String(data.opportunity),
              text: data.text,
              students: selectedStudents,
            },
          });
          if (response?.data?.opportunitySendMessageToStudents) {
            resetSuccessOptions();
          } else {
            showNotification(NotificationTypes.error, 'Something went wrong, message was not sent');
            setLoader(false);
          }
        }
        break;
      case 'event':
        {
          const response = await sendFromEvent({
            variables: {
              id: String(data.event),
              text: data.text,
              students: selectedStudents,
            },
          });
          if (response?.data?.eventSendMessageToStudents) {
            resetSuccessOptions();
          } else {
            showNotification(NotificationTypes.error, 'Something went wrong, message was not sent');
            setLoader(false);
          }
        }
        break;
      default:
        {
          const response = await sendFromCompany({
            variables: {
              id: String(data.company),
              text: data.text,
              students: selectedStudents,
            },
          });
          if (response?.data?.companySendMessageToStudents) {
            resetSuccessOptions();
          } else {
            showNotification(NotificationTypes.error, 'Something went wrong, message was not sent');
            setLoader(false);
          }
        }
        break;
    }
  };

  async function onSearch(value: any) {
    switch (active) {
      case 'opportunity':
        dispatch(setSearchOpp(value ? value : undefined));
        break;
      case 'event':
        dispatch(setSearchEv(value ? value : undefined));
        break;
      default:
        dispatch(setSearchCom(value ? value : undefined));
        break;
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={styles.modalMessageBlock}>
        <div style={{display: 'flex', alignSelf: 'flex-end', cursor: 'pointer'}} onClick={onClose}>
          <Icon name="close" />
        </div>
        <Switcher active={active} setActive={setActive} />
        <Form form={form} onFinish={onSubmit}>
          <div className={styles.borderAllFilters2}>
            <FormInput
              name={active}
              labelKey={`${active.charAt(0).toUpperCase() + active.slice(1)}:`}
              message={`${active.charAt(0).toUpperCase() + active.slice(1)} is required field`}>
              <Select
                showSearch
                filterOption={false}
                placeholder={`Input title of ${active} (displayed only first 10 values)`}
                onSearch={onSearch}
                allowClear={true}
                style={{width: '100%'}}>
                {activeEntities.map((item: any, index: number) => (
                  <Option key={index} value={item.id}>
                    {active === 'company' ? item.name : `${item.name} (${item.company_name})`}
                  </Option>
                ))}
              </Select>
            </FormInput>

            <RichText
              value={'text'}
              labelKey="Message text markdown"
              required={true}
              rule={{message: 'Text message EN should contain max 500 characters', max: 501}}
            />
          </div>
          <span>
            Messaging support the following placeholders:
            <br />
            <b>{'{{student.first_name}}'}</b>
            <br />
            <b>{'{{student.last_name}}'}</b>
            <br />
            <b>{'{{student.city}}'}</b>
            <br />
            <div>
              <b>{'{{student.persona}} '}</b>&nbsp;&nbsp;(English version only)
            </div>
            <b>{'{{student.university}}'}</b>
            <br />
            <div>
              <b>{'{{student.referral}} '}</b>&nbsp;&nbsp;(empty if no referral)
            </div>
          </span>
          <div className={styles.buttonsWrapperFilters2}>
            <Button type="primary" className={styles.button1} htmlType="submit">
              Send message
            </Button>
          </div>
        </Form>
      </div>
    </Dialog>
  );
};

export default ModalMessage;
