import React, {FC} from 'react';
//Components
import FormInput from 'components/UI/FormInput';
import {WithCalendar} from 'components/EventDetails';
import {Input} from 'antd';
//Styling
import {useStyles} from '../styles';

import Selector from 'components/UI/Selector/SelectorFormInput';

const {TextArea} = Input;

interface Props {
  optionsData: any;
  dataForSelectors: any;
  forCalendar: any;
  fetchMoreCompanies: () => void;
  fetchSearchCompanies?: (value: any) => Promise<void>;
  handleOnchangeCompany: () => void;
}

const DetailsBlock: FC<Props> = ({
  optionsData,
  dataForSelectors,
  forCalendar,
  fetchMoreCompanies,
  fetchSearchCompanies,
  handleOnchangeCompany,
}) => {
  const styles = useStyles();

  return (
    <>
      <FormInput
        name={`name`}
        className={styles.input2}
        labelKey="Event Name:"
        message="Event Name is required field"
        rule={{message: 'Event Name should contain max 64 characters', max: 64}}>
        <Input placeholder="Event Name" />
      </FormInput>
      <Selector
        array={optionsData.allCompanies}
        value={'id'}
        title={'name'}
        styles={styles.input2}
        name={`company_id`}
        label={'Associated company'}
        handleScroll={fetchMoreCompanies}
        handleSearch={fetchSearchCompanies}
        handleOnchange={handleOnchangeCompany}
      />
      <Selector
        array={dataForSelectors.type.data}
        value={'value'}
        title={'title'}
        styles={styles.input2}
        name={`type`}
        label={'Type'}
      />
      {/* <TreeSelector array={dataForSelectors.type.data} name={'type'} label={'Type'} /> */}
      <WithCalendar onCalendarChange={forCalendar.onCalendarChange} values={forCalendar.startEnd} />
      <FormInput
        name={`address`}
        className={styles.input2}
        labelKey="Address:"
        message="Address is required field"
        rule={{message: 'Address should contain max 64 characters'}}>
        <Input placeholder="Address" />
      </FormInput>
      <FormInput
        name={`main_description_en`}
        className={styles.input2}
        tooltipText="No markdown input here. This is a topmost entry for an Event which lies under Read more section."
        labelKey="Main description EN:"
        message="Main description EN is required field">
        <TextArea placeholder="Main description EN" />
      </FormInput>
      <FormInput
        name={`main_description_fr`}
        tooltipText="No markdown input here. This is a topmost entry for an Event which lies under Read more section."
        className={styles.input2}
        labelKey="Main description FR:"
        message="Main description FR is required field">
        <TextArea placeholder="Main description FR" />
      </FormInput>
      <FormInput
        name={`main_description_nl`}
        tooltipText="No markdown input here. This is a topmost entry for an Event which lies under Read more section."
        className={styles.input2}
        labelKey="Main description NL:"
        message="Main description NL is required field">
        <TextArea placeholder="Main description NL" />
      </FormInput>
    </>
  );
};
export default DetailsBlock;
