import {makeStyles} from '@material-ui/styles';
import {constStyles} from 'components/UI/materialThemes';

export const useStyles = makeStyles(() => ({
  mainWrapper: constStyles.mainWrapper,
  headerMain: constStyles.headerMain,
  borderTable: constStyles.borderTable1,
  blockTable: constStyles.blockTable,
  customButton: constStyles.customButton,
  borderPagination: {
    width: '100%',
    background: 'white',
    position: 'fixed',
    bottom: '0.5rem',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  searchInput: {
    width: '400px',
    '@media (max-width:500px)': {
      width: '100%',
    },
    '@media (max-width:1400px)': {
      width: '250px',
    },
  },
}));
