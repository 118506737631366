import React, {FC, useEffect, useState} from 'react';
//GraphQl
import {useQuery} from 'react-apollo';
import {GET_LIKED_COMPANIES_OPPORTINITIES_EVENTS} from 'requests/queries';
//Components
import OneLikeComponent from './oneLike';
//Styling
import {useStyles} from '../styles';

interface Props {
  id: any;
}

const BlockForLikes: FC<Props> = ({id}) => {
  const styles = useStyles();

  const [likedState, setlikedState] = useState({
    likedCompanies: [],
    likedEvents: [],
    likedOpportunities: [],
  });

  const {data: likedItems} = useQuery(GET_LIKED_COMPANIES_OPPORTINITIES_EVENTS, {
    variables: {
      studentId: id.toString(),
      limit: 10000,
      page: 1,
      order: undefined,
      orderDirection: undefined,
    },
  });

  useEffect(() => {
    if (likedItems) {
      setlikedState({
        likedCompanies: likedItems.likedCompanies.result,
        likedEvents: likedItems.likedEvents.result,
        likedOpportunities: likedItems.likedOpportunities.result,
      });
    }
  }, [likedItems]);
  return (
    <div className={styles.container}>
      <OneLikeComponent styles={styles} title={'Companies'} array={likedState.likedCompanies} />
      <OneLikeComponent styles={styles} title={'Opportunities'} array={likedState.likedOpportunities} />
      <OneLikeComponent styles={styles} title={'Events'} array={likedState.likedEvents} />
    </div>
  );
};
export default BlockForLikes;
