import {Sorter} from 'store/classForListPage';

export interface CompaniesState {
  companies: Company[];
  companiesCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  searchName?: string;
  filters?: Filters;
}

interface SocialsType {
  type: string;
  value: string;
}

export interface Company {
  id: any;
  name: string;
  website: string;
  created: string;
  city: string;
  logo: string;
  background_photo: string;
  bio_en: string;
  bio_fr: string;
  bio_nl: string;
  additional_info_en: string;
  additional_info_fr: string;
  additional_info_nl: string;
  emoji: string[];
  tags: string[];
  sector: string[];
  socials: SocialsType[];
  company_size: string;
}
export interface Filters {
  sectors: string[];
  company_sizes: string[];
  city: string;
  emoji: string[];
}
export enum CompaniesActionTypes {
  COMPANIES_SET_COMPANIES = 'COMPANIES_SET_COMPANIES',
  COMPANIES_SET_COMPANIES_COUNT = 'COMPANIES_SET_COMPANIES_COUNT',
  COMPANIES_SET_LIMIT = 'COMPANIES_SET_LIMIT',
  COMPANIES_SET_CURRENT_PAGE = 'COMPANIES_SET_CURRENT_PAGE',
  COMPANIES_SET_SORTING = 'COMPANIES_SET_SORTING',
  COMPANIES_SET_SEARCH_NAME = 'COMPANIES_SET_SEARCH_NAME',
  COMPANIES_SET_FILTERS = 'COMPANIES_SET_FILTERS',
}

export interface CompaniesSetCompanies {
  type: CompaniesActionTypes.COMPANIES_SET_COMPANIES;
  payload: Company[];
}

export interface CompaniesSetCompaniesCount {
  type: CompaniesActionTypes.COMPANIES_SET_COMPANIES_COUNT;
  payload: number;
}

export interface CompaniesSetCount {
  type: CompaniesActionTypes.COMPANIES_SET_LIMIT;
  payload: number;
}

export interface CompaniesSetPage {
  type: CompaniesActionTypes.COMPANIES_SET_CURRENT_PAGE;
  payload: number;
}

export interface CompaniesSetSorting {
  type: CompaniesActionTypes.COMPANIES_SET_SORTING;
  payload: Sorter | undefined;
}
export interface CompaniesSetSearchName {
  type: CompaniesActionTypes.COMPANIES_SET_SEARCH_NAME;
  payload: string | undefined;
}
export interface CompaniesSetFilters {
  type: CompaniesActionTypes.COMPANIES_SET_FILTERS;
  payload: Filters | undefined;
}
export type CompaniesActions =
  | CompaniesSetCompanies
  | CompaniesSetCompaniesCount
  | CompaniesSetCount
  | CompaniesSetPage
  | CompaniesSetSorting
  | CompaniesSetSearchName
  | CompaniesSetFilters;
