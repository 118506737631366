import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Portal} from 'react-portal';
import {AppState} from 'store';
//GraphQl
import {useQuery, useMutation} from '@apollo/client';
import {GET_REFERRALS} from 'requests/queries';
import {CREATE_REFERRAL, DELETE_REFERRAL} from 'requests/mutations';
//Actions
import {ReferralsActionsRedux} from 'store/referrals/actions';
import {Filters} from 'store/referrals/types';
//Components
import PaginationComponent from 'components/Pagination';
import PageWithDrawer from 'components/PageWithDrawer';
import LoadingComponent from 'components/LoadingComponent';
import {ModalReferrals, ConfirmDeleteChanel} from 'components/Modals';
import TableReferralsComponent from 'components/Tables/TableReferralsComponent';
import {columns} from 'components/Tables/TableReferralsComponent/columns';
import Loader from 'components/UI/Loader';
import StatusBar from 'components/StatusBar';
import SearchAndFilters from 'components/SearchAndFilters';

//Styles
import {useStyles} from '../defaultStylesListPage';
//Functions, utils
import {setValueSearch, setNumberPage, openModal, catchError, setCurrentFilters} from 'containers/defaultFunc';

const ReferralCodes: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [isCreateOpen, setCreateOpen] = useState(false);
  const toggleCreateOpen = () => setCreateOpen((isOpen) => !isOpen);

  const [filterOpen, setfilterOpen] = useState(false);
  const toggleFilterOpen = () => setfilterOpen((isOpen) => !isOpen);

  const [loader, setLoader] = useState(false);

  const {referrals, referralsCount, limit, currentPage, sorting, searchName, filters}: any = useSelector(
    (state: AppState) => state.referrals,
  );

  const getReferrals = useQuery(GET_REFERRALS, {
    variables: {
      limit,
      page: currentPage,
      order: sorting?.order,
      orderDirection: sorting?.orderType,
      filters: {code: searchName, ...filters},
    },
  });

  const [deleteReferral] = useMutation(DELETE_REFERRAL);
  const [createReferral] = useMutation(CREATE_REFERRAL);

  const [isConfirmDeleteOpened, setConfirmDeleteOpened] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  useEffect(() => {
    if (getReferrals.data) {
      dispatch(ReferralsActionsRedux.setEntities(getReferrals.data.referrals.result));
      dispatch(ReferralsActionsRedux.setEntityCount(getReferrals.data.referrals.total));
    }
  }, [getReferrals]);

  const handleCreateReferral = async (data: any) => {
    try {
      setLoader(true);
      await createReferral({
        variables: {
          referral: {...data},
        },
      });
      setLoader(false);
      toggleCreateOpen();
      getReferrals.refetch();
    } catch (e) {
      catchError(setLoader, false, 'referral code', e);
    }
  };

  const handleConfirmDelete = async (confirm: boolean) => {
    if (confirm) {
      const response = await deleteReferral({
        variables: {
          id: deletedId,
        },
      });
      if (response.data) getReferrals.refetch();
    }
    setConfirmDeleteOpened(false);
  };

  const handleSetFilters = async (filters: Filters) => {
    setLoader(true);
    await setCurrentFilters(filters, dispatch, ReferralsActionsRedux, toggleFilterOpen, getReferrals);
    setLoader(false);
  };

  const handleResetFilters = async () => {
    setCurrentFilters([], dispatch, ReferralsActionsRedux, toggleFilterOpen, getReferrals);
  };

  const formatValue = (value: string) => value.replace(/\s+/g, '')?.toUpperCase();

  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer buttonTop={{onClick: toggleCreateOpen, className: styles.customButton, title: `Create Referral`}}>
        {getReferrals.error && <LoadingComponent />}
        <StatusBar>
          <SearchAndFilters
            styles={styles}
            active={!!Object.keys(filters || {}).length}
            setValueSearch={setValueSearch({
              dispatch,
              setFunc: ReferralsActionsRedux.setSearchName,
              refetchEntity: getReferrals,
              setPageFunc: ReferralsActionsRedux.setCurrentPage,
            })}
            setfilterOpen={setfilterOpen}
            formatValue={formatValue}
            placeholder="Search by code"
          />
        </StatusBar>

        <TableReferralsComponent
          onChangeTable={setValueSearch({
            dispatch,
            setFunc: ReferralsActionsRedux.setSorting,
            setPageFunc: ReferralsActionsRedux.setCurrentPage,
            refetchEntity: getReferrals,
          })}
          deleteItem={openModal(setConfirmDeleteOpened, setDeletedId)}
          columns={columns}
          entity={referrals}
        />

        <div className={styles.borderPagination}>
          <PaginationComponent
            currentPage={currentPage}
            limit={limit}
            onChangeNumber={setNumberPage(
              dispatch,
              ReferralsActionsRedux.setCurrentPage,
              ReferralsActionsRedux.setLimit,
              getReferrals,
            )}
            total={referralsCount}
          />
        </div>
      </PageWithDrawer>
      <ConfirmDeleteChanel
        open={isConfirmDeleteOpened}
        onClose={handleConfirmDelete}
        message={`This referral code will be deleted`}
      />
      <ModalReferrals open={isCreateOpen} onClose={toggleCreateOpen} onSubmit={handleCreateReferral} />
      <ModalReferrals
        open={filterOpen}
        onSubmit={handleSetFilters}
        onClose={toggleFilterOpen}
        onResetFilters={handleResetFilters}
        filters={filters}
        isFilter
      />

      {loader && (
        <Portal>
          <Loader loader={loader} />
        </Portal>
      )}
    </div>
  );
};

export default ReferralCodes;
