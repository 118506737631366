import React, {FC} from 'react';

import {Pagination} from 'antd';

interface Props {
  currentPage: number;
  limit: number;
  onChangeNumber: any;
  total: number;
}

const PaginationComponent: FC<Props> = ({currentPage, limit, onChangeNumber, total}) => {
  return (
    <Pagination
      current={currentPage}
      pageSize={limit}
      showSizeChanger
      onChange={onChangeNumber}
      total={total}
      style={{margin: '0.5rem'}}
    />
  );
};
export default PaginationComponent;
