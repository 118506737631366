export enum SessionActionTypes {
  SESSION_LOADING_START = 'SESSION_LOADING_START',
  SESSION_LOADING_FINISH = 'SESSION_LOADING_FINISH',
  SET_TOKENS = 'SET_TOKENS',
  LOGIN_START = 'LOGIN_START',
  SET_IS_AUTHED = 'SET_IS_AUTHED',
  REMOVE_SESSION = 'REMOVE_SESSION',
  BOOTSTRAP_START = 'BOOTSTRAP_START',
  BOOTSTRAP_SUCCESS = 'BOOTSTRAP_SUCCESS',
  BOOTSTRAP_REJECT = 'BOOTSTRAP_REJECT',
  BOOTSTRAP_FAIL = 'BOOTSTRAP_FAIL',
}
interface SessionLoadingStartAction {
  type: SessionActionTypes.SESSION_LOADING_START;
}

interface SessionLoadingFinishAction {
  type: SessionActionTypes.SESSION_LOADING_FINISH;
}

interface LoginStartAction {
  type: SessionActionTypes.LOGIN_START;
}

interface SetTokensAction {
  type: SessionActionTypes.SET_TOKENS;
  payload: string;
}

interface RemoveSessionAction {
  type: SessionActionTypes.REMOVE_SESSION;
}
interface AuthedSessionAction {
  type: SessionActionTypes.SET_IS_AUTHED;
  payload: boolean;
}

export interface SessionState {
  token: string;
  loading: boolean;
  sessionLoading: boolean;
  isAuthed: boolean;
}

export type SessionActions =
  | SessionLoadingStartAction
  | SessionLoadingFinishAction
  | LoginStartAction
  | SetTokensAction
  | RemoveSessionAction
  | AuthedSessionAction;
