import React, {FC} from 'react';
import {Select} from 'antd';
import FormInput from '../FormInput';

const {Option} = Select;

interface Props {
  array: any;
  value: string;
  title: string;
  styles: any;
  name: string;
  label: string;
  handleScroll?: () => void;
  handleSearch?: (e: any) => void;
  handleOnchange?: () => void;
}

const Selector: FC<Props> = ({
  array,
  value,
  title,
  styles,
  name,
  label,
  handleScroll,
  handleSearch,
  handleOnchange,
}) => {
  return (
    <FormInput name={name} className={styles} labelKey={`${label}:`} message={`${label} is required field`}>
      <Select
        showSearch
        filterOption={false}
        allowClear={true}
        onChange={handleOnchange}
        onSearch={(e) => handleSearch && handleSearch(e)}
        // optionFilterProp="children"
        onPopupScroll={(e) => {
          if (handleScroll && (e.target as any).scrollHeight - (e.target as any).scrollTop < 257) {
            handleScroll();
          }
        }}>
        {array?.map((item: any, index: number) => (
          <Option key={index} value={item[value]}>
            {item[title]}
          </Option>
        ))}
      </Select>
    </FormInput>
  );
};
export default Selector;
