import {NotificationTypes, showNotification} from 'utils/notifications';

export const setIdOneEntity = (dispatch: any, setEntityId: any) => {
  return (id: string) => dispatch(setEntityId(id));
};

/**
 * @param {any} dispatch
 * @param {any} setFunc - action from store as search or filter
 * @param {any} refetchEntity - function from query graphql and can be refetched
 * **/
export const setValueSearch = ({dispatch, setFunc, refetchEntity, setPageFunc}: any) => {
  return async (valueSearch: string) => {
    dispatch(setFunc(valueSearch));
    dispatch(setPageFunc(1));
    await refetchEntity.refetch();
  };
};

/**
 * @param {any} setDeletedId - useState function for setting id what entity we want to delete
 * @param {any} setConfirmDeleteOpened - useState function set ConfirmDelete modal
 * **/
export const openModal = (setConfirmDeleteOpened: any, setDeletedId: any) => {
  return (id: string) => {
    setConfirmDeleteOpened(true);
    setDeletedId(id);
  };
};

/**
 * @param {any} deleteFunc - function action from store as deleteOpportunity/deleteCompany/deleteEvent
 * @param {string} deletedId - id what entity we want to delete
 * @param {any} setConfirmDeleteOpened - useState function set ConfirmDelete modal
 * **/
export const handleConfirmDeleteClose = (
  deleteFunc: any,
  deletedId: string,
  setConfirmDeleteOpened: any,
  next?: any,
) => {
  return async (confirm: boolean) => {
    if (confirm) {
      const response = await deleteFunc({
        variables: {
          id: deletedId.toString(),
        },
      });
      if (response.data) {
        if (next) {
          next();
        } else {
          window.location.reload();
        }
      }
    }
    setConfirmDeleteOpened(false);
  };
};

/**
 * @param {any} setFunc - function from useState as setLogo/setBanner/setBackground
 * @param {any} image- value was setted as logo and background
 * **/
export const handleChangeImage = (setFunc: any) => {
  return (image: any) => {
    setFunc(image);
  };
};

/**
 * @param {string} entity - value for descripe what page we go
 * **/
export const closeModal = (entity: string) => {
  window.open(`/${entity}`, '_self');
};

/**
 * @param {any} setLoader - function that setting false Loader
 * @param {boolean}  isUpdatePage - value for describe error on what is page was error(updating/creating)
 * @param {string}  entityTitle - value for descripe what page
 * @error - error what was catched
 * **/
export const catchError = (setLoader: any, isUpdatePage: boolean, entityTitle: string, error: any) => {
  setLoader(false);
  document.getElementsByTagName('body')[0].style.overflow = 'auto';
  const err = `Unable to ${isUpdatePage ? 'update' : 'create'} ${entityTitle} data`;
  showNotification(NotificationTypes.error, err);
  console.log(`%c onSubmit->e=>`, 'color: orange', error);
};

export const setNumberPage = (dispatch: any, setCurrentPage: any, setLimit: any, refetchEntity: any) => {
  return async (page: any, count: any) => {
    dispatch(setCurrentPage(page));
    dispatch(setLimit(count));
    //    await refetchEntity.refetch();
  };
};

export const setCurrentFilters = async (
  filters: any,
  dispatch: any,
  entityActionRedux: any,
  onClose: any,
  getEntity: any,
) => {
  await dispatch(entityActionRedux.setFilters(filters));
  await dispatch(entityActionRedux.setCurrentPage(1));

  onClose();

  await getEntity.refetch();
};

export const dateFormat = 'YYYY/MM/DD HH:mm';
