import axios, {AxiosRequestConfig} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig => {
  return request;
};

export const baseURL = `${API_URL}`;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));

export {axiosInstance};
