import React from 'react';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Brussels');

export const columns = (goToCompany: any, deleteDrop: any) => [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    width: '150px',
    sorter: true,
    render: (text: string, record: any) => {
      return (
        <div
          style={{textDecoration: 'underline', cursor: 'pointer'}}
          onClick={() => {
            goToCompany(record.id);
          }}>
          {text}
        </div>
      );
    },
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Education level',
    dataIndex: 'education_level',
    sorter: true,
    key: 'education_level',
    width: '150px',
    render: (value: any) => {
      return <div>{value?.name_en}</div>;
    },
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    width: '150px',
    sorter: true,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    width: '150px',
    sorter: true,
  },
  {
    title: 'Archetype',
    dataIndex: 'institutes',
    key: 'institutes',
    width: '150px',
    sorter: true,
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.archetype}</div>;
      });
    },
  },
  {
    title: 'University',
    dataIndex: 'institutes',
    key: 'institutes',
    width: '250px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name}</div>;
      });
    },
  },
  {
    title: 'Education Subject',
    dataIndex: 'institutes',
    key: 'institutes',
    width: '250px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.subject_name}</div>;
      });
    },
  },
  {
    title: 'Degrees',
    dataIndex: 'institutes',
    key: 'institutes',
    width: '150px',
    sorter: true,

    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.degree_name_en}</div>;
      });
    },
  },
  {
    title: 'Grades',
    dataIndex: 'institutes',
    key: 'institutes',
    width: '150px',
    sorter: true,

    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.average_grades}</div>;
      });
    },
  },
  {
    title: 'Activities',
    dataIndex: 'activities',
    key: 'activities',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    title: 'Achievements',
    dataIndex: 'achievements',
    key: 'achievements',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    title: 'Interests',
    dataIndex: 'interests',
    key: 'interests',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    title: 'International Experiences',
    dataIndex: 'international_experiences',
    key: 'international_experiences',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
  },
  {
    title: 'Work Experiences',
    dataIndex: 'work_experiences',
    key: 'work_experiences',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name_en}</div>;
      });
    },
    sorter: true,
  },
  {
    title: 'Languages',
    dataIndex: 'languages',
    key: 'languages',
    width: '150px',
    render: (value: any) => {
      return value?.map((item: any, id: any) => {
        return <div key={id}>{item?.name}</div>;
      });
    },
  },
  {
    title: 'Registration date',
    dataIndex: 'created',
    key: 'created',
    width: '250px',
    sorter: true,
    render: (value: any) => {
      const date = (value && Number(value)) || value;

      return date ? <div>{moment(date).format('YYYY-MM-DD HH:mm')}</div> : null;
    },
  },
  {
    title: 'Referral code',
    dataIndex: 'referrer',
    key: 'referrer',
    width: '250px',
  },
  {
    width: '9rem',
    title: '',
    dataIndex: 'operation',
    render: (_: any, record: any) => {
      return (
        <div
          style={{
            appearance: 'none',
            height: '3rem',
            border: 'none',
            display: 'flex',
            fontSize: '1.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#F65532',
            color: 'white',
            width: '6rem',
            cursor: 'pointer',
            marginRight: '8px',
          }}
          onClick={() => {
            deleteDrop(record.id);
          }}>
          Delete
        </div>
      );
    },
  },
];
