import React from 'react';

interface IUploadButtonProps {
  onChange: (e: any) => void;
}

const UploadButton: React.FunctionComponent<IUploadButtonProps> = ({onChange}) => {
  return (
    <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
      <label className="ant-upload">
        <input style={{display: 'none'}} type="file" name="file" accept=".jpg, .jpeg, .png" onChange={onChange} />
        <div>
          <span role="img" aria-label="plus" className="anticon anticon-plus">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              className=""
              data-icon="plus"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <defs>
                <style></style>
              </defs>
              <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path>
              <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path>
            </svg>
          </span>
          <div className="ant-upload-text">Upload</div>
        </div>
      </label>
    </div>
  );
};

export default React.memo(UploadButton);
