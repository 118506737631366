export function removeSession(): void {
  localStorage.removeItem('token');
}

export function saveSession(token: string): void {
  localStorage.setItem('token', token);
}

export function getTokens(): string {
  return localStorage.getItem('token') || '';
}
