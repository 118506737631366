import React, {FC} from 'react';
import {useHistory} from 'react-router';
import {Table} from 'antd';
import {columns} from './columns';
import {expandedColumns} from './expandedColumns';

interface Props {
  deleteItem: any;
  entityTitle: string;
  entity: any;
  onChangeTable: any;
  setId: any;
  onSelectChange: any;
  selectedRowKeys: any;
  extendedV?: any;
}
const TableComponent: FC<Props> = ({
  deleteItem,
  entityTitle,
  extendedV,
  entity,
  onChangeTable,
  setId,
  selectedRowKeys,
  onSelectChange,
}) => {
  const history = useHistory();

  const goToItem = (id: string) => {
    setId(id);
    history.push(`${entityTitle}/details`, {id});
  };

  const sorting = (_a: any, _b: any, sorter: any) => {
    const newOrder =
      sorter.order === undefined
        ? {order: undefined, orderType: undefined}
        : sorter.order === 'descend'
        ? {order: sorter.columnKey, orderType: 'desc'}
        : {order: sorter.columnKey, orderType: 'asc'};

    onChangeTable(newOrder);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Table
      columns={!extendedV ? columns(goToItem, deleteItem) : expandedColumns(goToItem, deleteItem)}
      onChange={sorting}
      dataSource={entity}
      pagination={false}
      scroll={{y: '72vh'}}
      rowSelection={rowSelection}
      rowClassName={(record: any) => {
        return '';
      }}
    />
  );
};
export default TableComponent;
