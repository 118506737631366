/* eslint-disable react/display-name */
import React from 'react';
import {Checkbox} from 'antd';
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Brussels');

export const columns = (openPopupMessage: any) => [
  {
    title: 'Accepted',
    dataIndex: 'is_accepted',
    key: 'is_accepted',
    width: '100px',

    render: (value: any) => {
      return <div>{value && <Checkbox disabled checked={true} />}</div>;
    },
  },
  {
    title: 'Rejected',
    dataIndex: 'is_accepted',
    key: 'is_accepted',
    width: '100px',

    render: (value: any) => {
      return <div>{value === false && <Checkbox disabled checked={true} />}</div>;
    },
  },
  {
    title: 'No response',
    dataIndex: 'is_accepted',
    key: 'is_accepted',
    width: '100px',

    render: (value: any) => {
      return <div>{value === null && <Checkbox disabled checked={true} />}</div>;
    },
  },
  {
    title: 'Type of message',
    dataIndex: 'is_automated',
    key: 'is_automated',
    width: '100px',

    render: (value: any) => {
      return <div>{value ? 'Automated' : 'Manual'}</div>;
    },
  },
  {
    title: 'Date sent',
    dataIndex: 'message_created',
    key: 'message_created',
    width: '230px',
    render: (value: any) => {
      return <div>{moment(value).format('YYYY-MM-DD HH:mm')}</div>;
    },
  },

  {
    title: 'Message content',
    dataIndex: 'text',
    key: 'text',
    width: '250px',
    render: (value: any) => {
      return (
        <div
          onClick={() => openPopupMessage(value)}
          style={{
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}>
          {value}
        </div>
      );
    },
  },
  {
    title: 'Sent from',
    dataIndex: 'name',
    key: 'name',
    width: '150px',
  },

  {
    title: 'Type of message',
    dataIndex: 'message_type',
    key: 'message_type',
    width: '150px',
    render: (value: any) => {
      switch (value) {
        case '1':
          return <div>Company</div>;
        case '2':
          return <div>Opportunity</div>;
        case '3':
          return <div>Event</div>;
      }
    },
  },
];
