import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  DisableProtectionModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
    height: '20rem',
    padding: '2rem 3rem 1rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  infoModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
    height: '20rem',
    padding: '2rem 3rem 2rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  modalFilters: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    padding: '2rem 3rem 2rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  modalMessageBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: 'auto',
    padding: '2rem 3rem 2rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  borderAllFilters: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  borderAllFilters2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-select-arrow': {
      right: '20px !important',
    },
    '& .ant-select-clear': {
      right: '40px !important',
    },
  },
  borderOneFilter: {
    marginRight: '3rem',
    marginBottom: '1rem',
    width: '26rem',
    '& .ant-select-arrow': {
      right: '20px !important',
    },
    '& .ant-select-clear': {
      right: '40px !important',
    },
  },
  modalHeader: {
    fontSize: '2.4rem',
    fontWeight: 700,
  },
  modalHeader2: {
    fontSize: '1.8rem',
    fontWeight: 700,
    marginBottom: '1rem',
  },
  label: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    width: '100%',
    margin: '0.5rem 0',
    color: 'rgba(0, 0, 0, 0.85)',
    wordWrap: 'break-word',

    '& div': {
      fontWeight: 'initial',
      wordWrap: 'break-word',
    },
  },
  modalMessage: {
    color: '#666666',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 400,
    whiteSpace: 'break-spaces',
  },
  modalInfoMessage: {
    margin: '1rem 0',
    color: '#666666',
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  buttonsWrapper: {
    width: '100%',
    margin: '3rem 0 0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsWrapperFilters: {
    width: '100%',
    margin: '3rem 0 0 1rem',
    justifyContent: 'space-between',
    display: 'flex',
  },
  buttonsWrapperFilters2: {
    width: '100%',
    margin: '3rem 0 0 1rem',
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    marginBottom: '1rem',
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  button1: {
    marginBottom: '1rem',
    marginTop: '2.5rem',
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
  },
  modalSyncBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: 'auto',
    padding: '2rem 3rem 2rem 3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    '& .ant-radio-wrapper': {
      width: '47%',
    },
  },
  input: {
    width: '25rem',
    '@media (max-width:500px)': {
      width: '100%',
    },
    '@media (max-width:1400px)': {
      width: '250px',
    },
  },
  input1: {
    width: '100%',
  },
}));
