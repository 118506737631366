import {ListEntities} from 'store/classForListPage';
import {StudentsActionTypes, StudentsActions} from './types';

export const StudentsActionsRedux = new ListEntities(StudentsActionTypes, 'STUDENTS');

export const setDataCSV = (dataForCSV?: string[]): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_DATA_FOR_CSV,
    payload: dataForCSV,
  };
};

export const setSelectedRowKeys = (selectedRowKeys: string[]): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS,
    payload: selectedRowKeys,
  };
};
export const setSelectedRowKeysWithMessages = (selectedRowKeysWithMessages: string[]): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_WITH_MESSAGES,
    payload: selectedRowKeysWithMessages,
  };
};
export const setSelectedRowKeysOnDiffPages = (selectedRowKeys: any): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_SELECTED_ROW_KEYS_ON_DIFF_PAGES,
    payload: selectedRowKeys,
  };
};
export const setMessageId = (messageId?: string): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_MESSAGE_ID,
    payload: messageId,
  };
};
export const setEntityLiked = (entity: any): StudentsActions => {
  return {
    type: StudentsActionTypes.STUDENTS_SET_LIKED_ENTITY,
    payload: entity
  }
};