import {Company} from 'store/companies/types';

export interface CompanyState {
  company: Company;
  id: any;
}

export enum CompanyActionTypes {
  COMPANY_SET = 'COMPANY_SET',
  COMPANY_SET_ID = 'COMPANY_SET_ID',
}

export interface CompanySet {
  type: CompanyActionTypes.COMPANY_SET;
  payload: any;
}

export interface CompanySetID {
  type: CompanyActionTypes.COMPANY_SET_ID;
  payload: any;
}

export type CompanyDetailsActions = CompanySet | CompanySetID;
