import {AnyAction, Dispatch, combineReducers} from 'redux';
import UIReducer from './ui/reducer';
import SessionReducer from './session/reducer';
import CompaniesReducer from './companies/reducer';
import CompanyDetailsReducer from './companyDetails/reducer';
import OpportunityDetailsReducer from './opportunityDetails/reducer';
import OpportunitiesReducer from './opportunities/reducer';
import EventDetailsReducer from './eventDetails/reducer';
import EventsReducer from './events/reducer';
import StudentDetailsReducer from './studentDetails/reducer';
import StudentsReducer from './students/reducer';
import MessagesReducer from './messages/reducer';
import ReferralsReducer from './referrals/reducer';

const rootReducer = combineReducers({
  ui: UIReducer,
  companies: CompaniesReducer,
  companyDetails: CompanyDetailsReducer,
  session: SessionReducer,
  opportunityDetails: OpportunityDetailsReducer,
  opportunities: OpportunitiesReducer,
  eventDetails: EventDetailsReducer,
  events: EventsReducer,
  students: StudentsReducer,
  studentDetails: StudentDetailsReducer,
  messages: MessagesReducer,
  referrals: ReferralsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type Dispatcher<T> = (dispatch: Dispatch<AnyAction>) => Promise<T>;

export default rootReducer;
