import {Reducer} from 'redux';
import {produce} from 'immer';

import {SessionActionTypes, SessionActions, SessionState} from './types';

const initialState: SessionState = {
  token: '',
  loading: false,
  sessionLoading: false,
  isAuthed: false,
};

const sessionReducer: Reducer<SessionState, SessionActions> = (state = initialState, action): SessionState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SessionActionTypes.SESSION_LOADING_START:
        draft.loading = true;
        return;

      case SessionActionTypes.SESSION_LOADING_FINISH:
        draft.loading = false;
        return;

      case SessionActionTypes.SET_TOKENS:
        draft.token = action.payload;
        return;
      case SessionActionTypes.SET_IS_AUTHED:
        draft.isAuthed = action.payload;
        return;

      case SessionActionTypes.REMOVE_SESSION:
        return {
          ...initialState,
        };

      default:
        return state;
    }
  });
};

export default sessionReducer;
