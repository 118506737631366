import React, {memo, useEffect} from 'react';

import {useStyles} from './styles';

export interface LoaderProps {
  color?: string;
  size?: string;
  loader?: boolean;
}

const Loader: React.FC<LoaderProps> = ({color = '#fff', size = '8', loader}: LoaderProps) => {
  const styles = useStyles();
  useEffect(() => {
    if (loader) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }, [loader]);
  return (
    <div className={styles.loader}>
      <svg className={styles.buttonLoader} width="100" height="26" viewBox="0 0 92 26">
        <circle cx="9" cy="13" r={size} fill={color} />
        <circle cx="32" cy="13" r={size} fill={color} />
        <circle cx="55" cy="13" r={size} fill={color} />
        <circle cx="78" cy="13" r={size} fill={color} />
      </svg>
    </div>
  );
};

export default memo(Loader);
