import {Reducer} from 'redux';
import {produce} from 'immer';
import {OpportunitiesActionTypes, OpportunitiesActions, OpportunitiesState} from './types';

const initialState: OpportunitiesState = {
  opportunitiesCount: 0,
  opportunities: [],
  currentPage: 1,
  limit: 20,
};

const opportunitiesReducer: Reducer<OpportunitiesState, OpportunitiesActions> = (
  state = initialState,
  action,
): OpportunitiesState => {
  return produce(state, (draft: OpportunitiesState) => {
    switch (action.type) {
      case OpportunitiesActionTypes.OPPORTUNITIES_SET_OPPORTUNITIES:
        draft.opportunities = action.payload;
        return;

      case OpportunitiesActionTypes.OPPORTUNITIES_SET_OPPORTUNITIES_COUNT:
        draft.opportunitiesCount = action.payload;
        return;

      case OpportunitiesActionTypes.OPPORTUNITIES_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case OpportunitiesActionTypes.OPPORTUNITIES_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case OpportunitiesActionTypes.OPPORTUNITIES_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case OpportunitiesActionTypes.OPPORTUNITIES_SET_SEARCH_NAME:
        draft.searchName = action.payload;
        return;
      case OpportunitiesActionTypes.OPPORTUNITIES_SET_FILTERS:
        draft.filters = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default opportunitiesReducer;
