import {Student} from 'store/students/types';

export interface StudentState {
  student: Student;
  id: any;
}

export enum StudentActionTypes {
  STUDENT_SET = 'STUDENT_SET',
  STUDENT_SET_ID = 'STUDENT_SET_ID',
}

export interface StudentSet {
  type: StudentActionTypes.STUDENT_SET;
  payload: any;
}

export interface StudentSetID {
  type: StudentActionTypes.STUDENT_SET_ID;
  payload: any;
}

export type StudentDetailsActions = StudentSet | StudentSetID;
