/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {useHistory} from 'react-router';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from 'store';
//Actions
import {setDataCSV} from 'store/students/actions';
import {StudentActionsRedux} from 'store/studentDetails/actions';
import {ReferralsActionsRedux} from 'store/referrals/actions';
import {ReferralsActionTypes} from 'store/referrals/types';

//GraphQl
import {GET_STUDENT, GET_STUDENT_WITH_MESSAGES, GET_REFERRALS} from 'requests/queries';
import {UPDATE_STUDENT_REFERRAL} from 'requests/mutations';
import {useQuery, useMutation} from '@apollo/client';
//Styling
import {useStyles} from '../defaultStylesSinglePage';
//Components
import TableComponent from 'components/Tables/TableMessagesComponentInStudent';
import {columns} from 'components/Tables/TableMessagesComponentInStudent/columns';
import {DetailsBlock, DetailsBlock2, BlockForLikes} from 'components/StudentDetails';
import PageWithDrawer from 'components/PageWithDrawer';
import LoadingComponent from 'components/LoadingComponent';
import {ModalMessage, SuccessModal} from 'components/Modals';
import Loader from 'components/UI/Loader';
import {apiExportCV} from '../../api';
import {Form, Button} from 'antd';

import {getTokens} from 'utils/storage';
//Functions, utils
import {catchError} from 'containers/defaultFunc';

const API_URL = process.env.REACT_APP_API_URL;

const StudentDetails: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  //History
  const history: any = useHistory();
  const states: any = history.location.state;

  const idStudent: any = useSelector((state: AppState) => state.studentDetails.id) ?? '';
  const student = useSelector((state: AppState) => state.studentDetails.student);
  //State
  const [messageWindow, setmessageWindow] = useState(false);
  const [isSuccessModal, setisSuccessModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [listMessages, setlistMessages] = useState([]);

  const [referralsSearch, setRreferralsSearch] = useState('');
  const [referralsPage, setReferralsPage] = useState(1);
  const [allReferrals, setAllReferrals] = useState([]);

  const [id, setId] = useState(idStudent);
  //QUERY, MUTATIONS
  const {data: studentMain, error, refetch: refetchStudent} = useQuery(GET_STUDENT, {
    variables: {id: id ? id : id.toString()},
  });
  const getStudents2 = useQuery(GET_STUDENT_WITH_MESSAGES, {
    variables: {
      account_id: id,
      limit: 10000,
      page: 1,
    },
    skip: !id,
  });
  // const {referrals, referralsCount, limit, currentPage, sorting, searchName, filters}: any = useSelector(
  //   (state: AppState) => state.referrals,
  // );
  const {data: referralsData, fetchMore} = useQuery(GET_REFERRALS, {
    variables: {
      page: 1,
      limit: 20,
      order: 'code',
      orderDirection: 'asc',
      filters: {code: referralsSearch},
    },
  });

  const fetchMoreReferrals = async () => {
    fetchMore({
      variables: {
        page: referralsPage + 1,
        limit: 20,
        order: 'code',
        orderDirection: 'asc',
        filters: {code: referralsSearch},
      },
    });
    setReferralsPage(referralsPage + 1);
  };
  useEffect(() => {
    setAllReferrals(referralsData?.referrals?.result);
  }, [referralsData]);

  const [updateStudentReferral] = useMutation(UPDATE_STUDENT_REFERRAL);

  useEffect(() => {
    if (getStudents2.data) {
      setlistMessages(getStudents2.data.studentWithMessages.result);
    }
  }, [getStudents2]);
  //Preconditions
  useEffect(() => {
    dispatch(setDataCSV(undefined));
  }, [dispatch]);

  useEffect(() => {
    id && history.replace(history.location.pathname, {...history.location.state, id});
    setId(!!history.location.state && states.id);
  }, [history]);

  useEffect(() => {
    if (studentMain) {
      const {student} = studentMain;

      dispatch(StudentActionsRedux.setEntity(student));
      dispatch(StudentActionsRedux.setEntityId(student.id));
    }
  }, [dispatch, studentMain]);

  const handleUpdateReferral = async (value: string) => {
    try {
      setLoader(true);
      await updateStudentReferral({
        variables: {
          referralCode: value,
          studentId: Number(id),
        },
      });
      setLoader(false);
      refetchStudent();
    } catch (e) {
      catchError(setLoader, false, 'referral code', e);
    }
  };
  const handleSearchReferral = (event: any) => {
    const formattedValue = event.target.value.replace(/\s+/g, '')?.toUpperCase();
    setRreferralsSearch(formattedValue);
  };
  const handleClearReferral = () => {
    setRreferralsSearch('');
    handleUpdateReferral('');
  };

  const onExportCV = async () => {
    setLoader(true);

    try {
      await apiExportCV([idStudent]);
    } catch (err) {
      //
    }

    setLoader(false);
  };

  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {error ? (
          <LoadingComponent goToEntity="students" />
        ) : (
          <>
            <div style={{display: 'flex'}}>
              <Button
                type="primary"
                className={styles.customButton}
                style={{marginLeft: 'auto', top: 0}}
                onClick={onExportCV}>
                Export CV
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setmessageWindow(true)}
                className={styles.customButton}
                style={{marginLeft: '1rem', top: 0}}>
                Send message
              </Button>
            </div>
            <div style={{overflowX: 'scroll'}}>
              <div className={styles.borderTable}>
                <div className={styles.container}>
                  <div className={styles.left}>
                    <Form form={form} onChange={handleSearchReferral}>
                      <DetailsBlock
                        student={student}
                        referrals={allReferrals}
                        fetchMoreReferrals={fetchMoreReferrals}
                        updateReferral={handleUpdateReferral}
                        clearReferral={handleClearReferral}
                      />
                    </Form>
                  </div>
                  <div className={styles.right}>
                    <DetailsBlock2 student={student} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <BlockForLikes id={id} />
        <div className={styles.label}>List messages</div>
        <TableComponent columns={columns} entity={listMessages} />
        <ModalMessage
          open={messageWindow}
          onClose={() => setmessageWindow(false)}
          selectedStudents={[id]}
          setLoader={setLoader}
          setisSuccessModal={setisSuccessModal}
        />
        <SuccessModal onClose={() => setisSuccessModal(false)} open={isSuccessModal} messageKey="Message was sent" />
        {loader && (
          <Portal>
            <Loader loader={loader} />
          </Portal>
        )}
      </PageWithDrawer>
    </div>
  );
};

export default StudentDetails;
