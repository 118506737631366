import React, {FC} from 'react';

import RichText from 'components/UI/RichText';
import PromoBlock from 'components/CompanyDetails/PromoBlock';
import FormInput from 'components/UI/FormInput';
import {Input} from 'antd';

import {useStyles} from '../styles';
import {emailRegex} from 'utils/validation';

type RichAreaType = {
  en: {value: string};
  fr: {value: string};
  nl: {value: string};
};

interface IProps {
  richArea: RichAreaType;
  promo: any;
  forCalendar: any;
  showRedirectURLInput?: boolean;
}
const DetailsBlock2: FC<IProps> = ({richArea, promo, forCalendar}) => {
  const styles = useStyles();

  return (
    <>
      <PromoBlock forCalendar={forCalendar} promo={promo} />
      <RichText
        value={richArea.en.value}
        labelKey="Additional Info EN"
        required={true}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
      <RichText
        value={richArea.fr.value}
        labelKey="Additional Info FR"
        required={false}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
      <RichText
        value={richArea.nl.value}
        labelKey="Additional Info NL"
        required={false}
        tooltipText={`Accordion markdown input here. To create new accordion item - Use '#' starting new line. Text on this line would signify subtitle text for the accordion item.`}
      />
      <div style={{marginBottom: '10px'}}>
        <FormInput
          name={`redirection_link`}
          className={styles.input2}
          labelKey="Redirection Link (optional)"
          message="Redirection Link is required field"
          rules={[
            {
              type: 'url',
              validator: async (rule: any, value: string) => {
                const res = value?.match(
                  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&%'\(\)\*\+,;=.]+$/,
                );

                if (!res && value) {
                  throw new Error('Must be a valid url');
                }
              },
            },
          ]}>
          <Input placeholder="Redirection Link" />
        </FormInput>
      </div>
      <RichText
        value="message_en"
        labelKey="Text message EN"
        required={true}
        rule={{
          required: true,
          message: 'This field is required.',
        }}
        tooltipText={
          <span>
            Messaging support the following placeholders:
            <br />
            <b>{'{{student.first_name}}'}</b>
            <br />
            <b>{'{{student.last_name}}'}</b>
            <br />
            <b>{'{{student.city}}'}</b>
            <br />
            <div>
              <b>{'{{student.persona}} '}</b>&nbsp;&nbsp;(English version only)
            </div>
            <b>{'{{student.university}}'}</b>
            <br />
            <div>
              <b>{'{{student.referral}} '}</b>&nbsp;&nbsp;(empty if no referral)
            </div>
          </span>
        }
      />
      <div style={{marginBottom: '10px'}}>
        <FormInput
          name={`company_email`}
          className={styles.input2}
          labelKey="Company HR email (optional)"
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              validator: async (rule: any, value: string) => {
                const res = value?.match(emailRegex);

                if (!res && value) {
                  throw new Error('Must be a valid email');
                }
              },
            },
          ]}>
          <Input placeholder="Company HR email" />
        </FormInput>
      </div>
      <div style={{marginBottom: '10px'}}>
        <FormInput
          name={`buddy_email`}
          className={styles.input2}
          labelKey="Launch buddy email (optional)"
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              validator: async (rule: any, value: string) => {
                const res = value?.match(emailRegex);

                if (!res && value) {
                  throw new Error('Must be a valid email');
                }
              },
            },
          ]}>
          <Input placeholder="buddy@launch.career" />
        </FormInput>
      </div>

      {/* <RichText value="text_message_fr" labelKey="Text message FR" />
      <RichText value="text_message_nl" labelKey="Text message NL" /> */}
    </>
  );
};
export default DetailsBlock2;
