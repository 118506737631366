import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store';
//GraphQl
import {useQuery, useMutation} from '@apollo/client';
import {GET_COMPANIES, GET_COMPANY_OPTIONS_FOR_FILTERS} from 'requests/queries';
//Actions
import {CompaniesActionsRedux} from 'store/companies/actions';
import {initialState} from 'store/companyDetails/reducer';
import {CompanyActionsRedux} from 'store/companyDetails/actions';
//Components
import {columns} from 'components/Tables/TableCompaniesComponent/columns';

import {DELETE_COMPANY} from 'requests/mutations';
import {ModalFiltersCompany} from 'components/Modals';

import EntityList from '../classList';

const optionsSynchronize = [
  {label: 'Sectors', value: 'sectors'},
  {label: 'Perks', value: 'emoji'},
  {label: 'Company size', value: 'company_size'},
];

const Companies: FC = () => {
  const [filterOpen, setfilterOpen] = useState(false);
  const [filtersCurrent, setfiltersCurrent] = useState<any>([]);
  const [cityAvailable, setcityAvailable] = useState({cities: []});

  const {limit, currentPage, sorting, searchName, filters}: any = useSelector((state: AppState) => state.companies);
  const {data: companyOptionsData} = useQuery(GET_COMPANY_OPTIONS_FOR_FILTERS, {
    skip: !filterOpen,
  });

  const getCompany = useQuery(GET_COMPANIES, {
    variables: {
      limit,
      page: currentPage,
      order: sorting?.order,
      orderDirection: sorting?.orderType,
      search: {
        company_size: (filters?.company_sizes?.length > 0 && filters?.company_sizes) || undefined,
        sectors: (filters?.sectors?.length > 0 && filters?.sectors) || undefined,
        emoji: (filters?.emoji?.length > 0 && filters?.emoji) || undefined,
        cities: (filters?.cities?.length > 0 && filters?.cities) || undefined,
        name: searchName,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [deleteCompany] = useMutation(DELETE_COMPANY);

  useEffect(() => {
    if (companyOptionsData) {
      setfiltersCurrent([
        {
          title: 'Cities',
          keySave: 'cities',
          values: companyOptionsData?.availableCities.map((item: any) => {
            return {title: item.name, key: item.name, id: item.name};
          }),
        },
        {
          title: 'Company sizes',
          keySave: 'company_sizes',
          values: companyOptionsData?.companyCompanySizes.map((item: any) => {
            return {title: item.name, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Perks',
          keySave: 'emoji',
          values: companyOptionsData?.companyEmojis.map((item: any) => {
            return {title: item.name + ' ' + item.value_en, key: item.id, id: item.id};
          }),
        },
        {
          title: 'Sectors',
          keySave: 'sectors',
          values: companyOptionsData?.companySectors.map((item: any) => {
            return {title: item.name_en, key: item.id, id: item.id};
          }),
        },
      ]);
      setcityAvailable({
        cities: companyOptionsData?.availableCities,
      });
    }
  }, [companyOptionsData]);

  return (
    <EntityList
      optionsSynchronize={optionsSynchronize}
      EntityActionsRedux={CompaniesActionsRedux}
      SingleEntityActionsRedux={CompanyActionsRedux}
      getEntity={getCompany}
      deleteEntity={deleteCompany}
      deleteNext={getCompany.refetch}
      entityTitle={'company'}
      entitiesTitle={'companies'}
      initialState={initialState.company}
      filterOpen={{filterOpen, setfilterOpen}}
      columns={columns}
      tooltipText={`In case one of the following was changed in the database:
      ▪  sectors
      ▪  emoji
      ▪  company_size
For a change to take place for existing companies this button has to be pressed. 
Example: Perk name was changed. No additional action needed if new Perk was added.`}>
      <ModalFiltersCompany
        open={filterOpen}
        onClose={() => setfilterOpen(false)}
        filters={filtersCurrent}
        optionsData={cityAvailable}
        getCompany={getCompany}
      />
    </EntityList>
  );
};

export default Companies;
