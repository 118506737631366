import {Sorter} from 'store/classForListPage';

export interface ReferralsState {
  referrals: Referral[];
  referralsCount: number;
  currentPage: number;
  limit: number;
  sorting?: Sorter;
  searchName?: string;
  filters?: Filters;
}

export interface Referral {
  id: any;
  code: string;
  type: string;
  created: string;
  updated: string;
}
export interface Filters {
  code: string;
  type: string;
}
export enum ReferralsActionTypes {
  REFERRALS_SET_REFERRALS = 'REFERRALS_SET_REFERRALS',
  REFERRALS_SET_REFERRALS_COUNT = 'REFERRALS_SET_REFERRALS_COUNT',
  REFERRALS_SET_LIMIT = 'REFERRALS_SET_LIMIT',
  REFERRALS_SET_CURRENT_PAGE = 'REFERRALS_SET_CURRENT_PAGE',
  REFERRALS_SET_SORTING = 'REFERRALS_SET_SORTING',
  REFERRALS_SET_SEARCH_NAME = 'REFERRALS_SET_SEARCH_NAME',
  REFERRALS_SET_FILTERS = 'REFERRALS_SET_FILTERS',
}

export interface ReferralsSetReferrals {
  type: ReferralsActionTypes.REFERRALS_SET_REFERRALS;
  payload: Referral[];
}

export interface ReferralsSetReferralsCount {
  type: ReferralsActionTypes.REFERRALS_SET_REFERRALS_COUNT;
  payload: number;
}

export interface ReferralsSetCount {
  type: ReferralsActionTypes.REFERRALS_SET_LIMIT;
  payload: number;
}

export interface ReferralsSetPage {
  type: ReferralsActionTypes.REFERRALS_SET_CURRENT_PAGE;
  payload: number;
}

export interface ReferralsSetSorting {
  type: ReferralsActionTypes.REFERRALS_SET_SORTING;
  payload: Sorter | undefined;
}
export interface ReferralsSetSearchName {
  type: ReferralsActionTypes.REFERRALS_SET_SEARCH_NAME;
  payload: string | undefined;
}
export interface ReferralsSetFilters {
  type: ReferralsActionTypes.REFERRALS_SET_FILTERS;
  payload: Filters | undefined;
}
export type ReferralsActions =
  | ReferralsSetReferrals
  | ReferralsSetReferralsCount
  | ReferralsSetCount
  | ReferralsSetPage
  | ReferralsSetSorting
  | ReferralsSetSearchName
  | ReferralsSetFilters;
