import {Opportunity} from 'store/opportunities/types';

export interface OpportunityState {
  opportunity: Opportunity;
  id: any;
}

export enum OpportunityActionTypes {
  OPPORTUNITY_SET = 'OPPORTUNITY_SET',
  OPPORTUNITY_SET_ID = 'OPPORTUNITY_SET_ID',
}

export interface OpportunitySet {
  type: OpportunityActionTypes.OPPORTUNITY_SET;
  payload: any;
}

export interface OpportunitySetID {
  type: OpportunityActionTypes.OPPORTUNITY_SET_ID;
  payload: any;
}

export type OpportunityDetailsActions = OpportunitySet | OpportunitySetID;
