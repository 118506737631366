import React, {FC} from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {theme} from 'components/UI/materialThemes';
import {Provider} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Redux, {applyMiddleware, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import reduxThunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useStyles} from './styles';
import WithApollo from './apollo';
import rootReducer from 'store';

const middleware: Redux.Middleware[] = [reduxThunk];

interface RootProps {
  children?: React.ReactNode;
  initialState?: any;
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(...middleware)));
const persistor = persistStore(store);

const Root: FC = ({children}: RootProps) => {
  const styles = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <WithApollo>
            <ToastContainer toastClassName={styles.toast} />
            {children}
          </WithApollo>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default Root;
