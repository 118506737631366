import React, {FC} from 'react';
import {Input} from 'antd';
import {useStyles} from '../styles';

import FormInput from 'components/UI/FormInput';
import Selector from 'components/UI/Selector/SelectorFormInput';
import TreeSelector from 'components/UI/TreeSelector/TreeSelectorFormInput';

const {TextArea} = Input;

interface Props {
  optionsData: any;
  dataForSelectors: any;
  fetchMoreCompanies: () => void;
  fetchSearchCompanies: (value: any) => Promise<void>;
  handleOnchangeCompany: () => void;
}

const DetailsBlock: FC<Props> = ({
  optionsData,
  dataForSelectors,
  fetchMoreCompanies,
  fetchSearchCompanies,
  handleOnchangeCompany,
}) => {
  const styles = useStyles();

  return (
    <>
      <FormInput
        name={`name`}
        className={styles.input2}
        labelKey="Opportunity Name:"
        message="Opportunity Name is required field"
        rule={{message: 'Opportunity Name should contain max 64 characters', max: 64}}>
        <Input placeholder="Opportunity Name" />
      </FormInput>
      <Selector
        array={optionsData.listOfCompanies}
        value={'id'}
        title={'name'}
        styles={styles.input2}
        name={`company_id`}
        label={'Associated company'}
        handleScroll={fetchMoreCompanies}
        handleSearch={fetchSearchCompanies}
        handleOnchange={handleOnchangeCompany}
      />
      <TreeSelector array={optionsData.availableCities} name={'cities'} label={'Cities'} />
      <Selector
        array={dataForSelectors.type.data}
        value={'value'}
        title={'title'}
        styles={styles.input2}
        name={`types`}
        label={'Type'}
      />
      <TreeSelector array={dataForSelectors.degree.data} name={`degrees`} label={'Degree'} />
      <Selector
        array={dataForSelectors.experience.data}
        value={'value'}
        title={'title'}
        styles={styles.input2}
        name={`experiences`}
        label={'Experience'}
      />
      <TreeSelector array={dataForSelectors.language.data} name={'languages'} label={'Language'} />
      <FormInput
        name={`main_description_en`}
        className={styles.input2}
        tooltipText="No markdown input here. This is a topmost entry for an Opportunity which lies under Read more section."
        labelKey="Main description EN:"
        message="Main description EN is required field">
        <TextArea placeholder="Main description EN" />
      </FormInput>
      <FormInput
        tooltipText="No markdown input here. This is a topmost entry for an Opportunity which lies under Read more section."
        name={`main_description_fr`}
        message="Main description FR is required field"
        className={styles.input2}
        labelKey="Main description FR:">
        <TextArea placeholder="Main description FR" />
      </FormInput>
      <FormInput
        tooltipText="No markdown input here. This is a topmost entry for an Opportunity which lies under Read more section."
        name={`main_description_nl`}
        message="Main description NL is required field"
        className={styles.input2}
        labelKey="Main description NL:">
        <TextArea placeholder="Main description NL" />
      </FormInput>
    </>
  );
};
export default DetailsBlock;
