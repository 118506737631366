import React, {ReactElement} from 'react';
import {useStyles} from './styles';
import {useSelector, useDispatch} from 'react-redux';
import {AppState} from '../../../store';
import {setMessageId, setEntityLiked} from '../../../store/students/actions';

const SingleStudentsFilter = (): ReactElement => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const {likedEntity, messageId = ''}: any = useSelector((state: AppState) => state.students);

  const removeMessage = () => {
    dispatch(setMessageId(''));
  };

  const removeLiked = () => {
    dispatch(setEntityLiked({}));
  };

  return (
    <div className={styles.component}>
      {messageId && (
        <div className={styles.label}>
          With message{' '}
          <div onClick={removeMessage} className={styles.remove}>
            Remove
          </div>
        </div>
      )}
      {likedEntity?.id && (
        <div className={styles.label}>
          With liked{' '}
          <div onClick={removeLiked} className={styles.remove}>
            Remove
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleStudentsFilter;
