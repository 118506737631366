import {Reducer} from 'redux';
import {produce} from 'immer';
import {OpportunityActionTypes, OpportunityDetailsActions, OpportunityState} from './types';

export const initialState: OpportunityState = {
  opportunity: {
    id: '',
    name: '',
    banner_img: '',
    location: '',
    types: [],
    degrees: [],
    experiences: [],
    languages: [],
    description_en: '',
    description_fr: '',
    description_nl: '',
    main_description_en: '',
    main_description_fr: '',
    main_description_nl: '',
    text_en: '',
  },
  id: null,
};

const opportunityDetailsReducer: Reducer<OpportunityState, OpportunityDetailsActions> = (
  state = initialState,
  action,
): OpportunityState => {
  return produce(state, (draft: OpportunityState) => {
    switch (action.type) {
      case OpportunityActionTypes.OPPORTUNITY_SET:
        draft.opportunity = action.payload;
        return;

      case OpportunityActionTypes.OPPORTUNITY_SET_ID:
        draft.id = action.payload;
        return;

      default:
        return state;
    }
  });
};

export default opportunityDetailsReducer;
