import React, {FC, ReactNode} from 'react';
import {useStyles} from './styles';

interface Props {
  children?: ReactNode;
  buttonTop?: any;
}

const StatusBar: FC<Props> = ({children}) => {
  // Styling
  const styles = useStyles();
  
  return (
    <div className={styles.component}>
      {children}
    </div>
  );
};

export default StatusBar;
