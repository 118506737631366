import React, {FC} from 'react';
import {useStyles} from '../styles';

import moment from 'moment';

interface Props {
  student: any;
}

const DetailsBlock2: FC<Props> = ({student}) => {
  const styles = useStyles();
  return (
    <>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Institutes name:
          {student.institutes?.map((item: any, id: number) => {
            return item.name && <li key={id}>{item.name}</li>;
          })}
        </label>
        <label className={styles.label}>
          Subject name:
          {student.institutes?.map((item: any, id: number) => {
            return item.subject_name && <li key={id}>{item.subject_name}</li>;
          })}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Degree name:
          {student.institutes?.map((item: any, id: number) => {
            return item.degree_name_en && <li key={id}>{item.degree_name_en}</li>;
          })}
        </label>

        <label className={styles.label}>
          Graduation date:
          {student.institutes?.map((item: any, id: number) => {
            return item.graduation_date && <li key={id}>{moment(item.graduation_date).format('YYYY-MM-DD')}</li>;
          })}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Average grades:
          {student.institutes?.map((item: any, id: number) => {
            return <li key={id}>{item.average_grades}</li>;
          })}
        </label>
        <label className={styles.label}>
          Education level:
          <div>{student.education_level?.name_en}</div>
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          International experiences:
          {student.international_experiences?.map((item: any, id: number) => {
            return <li key={id}>{item.name_en}</li>;
          })}
        </label>
        <label className={styles.label}>
          Work experiences:
          {student.work_experiences?.map((item: any, id: number) => {
            return <li key={id}>{item.name_en}</li>;
          })}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Achievements:
          {student.achievements?.map((item: any, id: number) => {
            return <li key={id}>{item.name_en}</li>;
          })}
        </label>
        <label className={styles.label}>
          Activities:
          {student.activities?.map((item: any, id: number) => {
            return <li key={id}>{item.name_en}</li>;
          })}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Languages:
          {student.languages?.map((item: any, id: number) => {
            return <li key={id}>{item.name}</li>;
          })}
        </label>
        <label className={styles.label}>
          Social media:
          {student.social_media?.map((item: any, id: number) => {
            return (
              <div key={id} style={{display: 'flex'}}>
                <div style={{width: '8rem'}}>{item.type + ' '}</div>
                <a href={item.url} target="_blank" rel="noopener noreferrer nofollow">
                  {item.url}
                </a>
              </div>
            );
          })}
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Created:
          <div> {student.created}</div>
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <label className={styles.label}>
          Interest poll:
          {student.interests?.map((item: any, id: number) => {
            return <li key={id}>{item.name_en}</li>;
          })}
        </label>
      </div>
    </>
  );
};
export default DetailsBlock2;
