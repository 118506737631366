import {Reducer} from 'redux';
import {produce} from 'immer';
import {MessagesActionTypes, MessagesActions, MessagesState} from './types';

const initialState: MessagesState = {
  messagesCount: 0,
  messages: [],
  currentPage: 1,
  limit: 20,
  filters: {is_automated: false},
};

const messagesReducer: Reducer<MessagesState, MessagesActions> = (state = initialState, action): MessagesState => {
  return produce(state, (draft: MessagesState) => {
    switch (action.type) {
      case MessagesActionTypes.MESSAGES_SET_MESSAGES:
        draft.messages = action.payload;
        return;

      case MessagesActionTypes.MESSAGES_SET_MESSAGES_COUNT:
        draft.messagesCount = action.payload;
        return;

      case MessagesActionTypes.MESSAGES_SET_LIMIT:
        draft.limit = action.payload;
        return;

      case MessagesActionTypes.MESSAGES_SET_CURRENT_PAGE:
        draft.currentPage = action.payload;
        return;

      case MessagesActionTypes.MESSAGES_SET_SORTING:
        draft.sorting = action.payload;
        return;
      case MessagesActionTypes.MESSAGES_SET_FILTERS:
        draft.filters.is_automated = action.payload.is_automated;
        draft.filters.students = action.payload?.students;
        return;

      case MessagesActionTypes.MESSAGES_SET_SEARCH_OPPORTUNITIES:
        draft.searchOpp = action.payload;
        return;
      case MessagesActionTypes.MESSAGES_SET_SEARCH_EVENTS:
        draft.searchEv = action.payload;
        return;
      case MessagesActionTypes.MESSAGES_SET_SEARCH_COMPANIES:
        draft.searchCom = action.payload;
        return;
      default:
        return state;
    }
  });
};

export default messagesReducer;
