import ApiAddresses from './apiAddresses';
import {axiosInstance} from './axios';
import {Credentials} from './models';
import {getTokens} from '../utils/storage';

const API_URL = process.env.REACT_APP_API_URL;

export const apiLogin = (credentials: Credentials) => {
  const data: Credentials = {
    login: credentials.login,
    password: credentials.password,
  };

  return axiosInstance.post(ApiAddresses.LOGIN, data);
};
export const downloadFile = (url: string): void => {
  const link = window.document.createElement('a');
  link.href = url;
  link.click();
  link.target = '_blank';
};

export const apiExportCV = async (ids: string[]) => {
  const data = {
    students: ids,
  };
  const url = `${API_URL}/students/export-cv?&token=${getTokens()}`;

  return axiosInstance({
    url,
    method: 'POST',
    data,
  }).then((resp) => {
    downloadFile(resp.data.zipUrl);
  });
};
