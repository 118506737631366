/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store';
import {useHistory} from 'react-router-dom';
//GraphQl
import {useQuery, useMutation} from '@apollo/client';
import {DELETE_STUDENT} from 'requests/mutations';
import {GET_STUDENTS_WITH_MESSAGES, GET_STUDENTS_THAT_LIKED, GET_STUDENTS, EXPORT_CSV} from 'requests/queries';
import {useExportCvMutation} from 'test/gql/api/queries';
//Actions
import {
  StudentsActionsRedux,
  setDataCSV,
  setSelectedRowKeys,
  setSelectedRowKeysOnDiffPages,
  setSelectedRowKeysWithMessages,
  setMessageId,
} from 'store/students/actions';
import {StudentActionsRedux} from 'store/studentDetails/actions';
//Components
import Loader from 'components/UI/Loader';
import PaginationComponent from 'components/Pagination';
import PageWithDrawer from 'components/PageWithDrawer';
import LoadingComponent from 'components/LoadingComponent';
import TableComponent from 'components/Tables/TableStudentsComponent';
import SearchAndFilters from 'components/SearchAndFilters';
import StatusBar from 'components/StatusBar';
import SelectAll from 'components/SelectAll/SelectAll';
import {
  ConfirmDeleteChanel,
  ModalFiltersStudents,
  ModalSynchronize,
  ModalMessage,
  SuccessModal,
} from 'components/Modals';
import {Button, Tooltip} from 'antd';
//Styles
import {useStyles} from '../defaultStylesListPage';
//Functions, utils
import {
  setIdOneEntity,
  setValueSearch,
  openModal,
  handleConfirmDeleteClose,
  setNumberPage,
} from 'containers/defaultFunc';
import {getTokens} from 'utils/storage';
import {setEntityLiked} from '../../store/students/actions';
import {NotificationTypes, showNotification} from 'utils/notifications';
import SingleStudentsFilter from '../../components/UI/SingleStudentsFilter';

const optionsSynchronize = [
  {label: 'International experiences', value: 'international_experiences'},
  {label: 'Work experiences', value: 'work_experiences'},
  {label: 'Education level', value: 'education_level'},
  {label: 'Activities', value: 'activities'},
  {label: 'Achievements', value: 'achievements'},
  {label: 'Languages', value: 'languages'},
  {label: 'Sex', value: 'sex'},
];
const API_URL = process.env.REACT_APP_API_URL;

const Students: FC = () => {
  const styles = useStyles();
  const history: any = useHistory();
  const states: any = history.location.state;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isSuccessModal, setisSuccessModal] = useState(false);
  const [filterOpen, setfilterOpen] = useState(false);
  const [isConfirmDeleteOpened, setConfirmDeleteOpened] = useState(false);
  const [extendedV, setextendedV] = useState(false);
  //const [loadedExtendedV, setloadedExtendedV] = useState(true);
  const [messageWindow, setmessageWindow] = useState(false);
  const [syncronizeWindow, setsyncronizeWindow] = useState(false);

  const [open, setOpen] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const {
    limit,
    currentPage,
    sorting,
    searchName,
    students,
    studentsCount,
    filters,
    dataCSV,
    likedEntity,
    selectedRowKeys,
    selectedRowKeysOnDiffPages,
  }: any = useSelector((state: AppState) => state.students);

  const idsStudents: any = useSelector((state: AppState) => state.students.selectedRowKeys) ?? '';
  const idsStudentsWithMessages: any =
    useSelector((state: AppState) => state.students.selectedRowKeysWithMessages) ?? '';
  const messageId: any = useSelector((state: AppState) => state.students.messageId) ?? '';

  const exportedCSVData = useQuery(EXPORT_CSV, {
    variables: {ids: dataCSV || undefined},
    skip: dataCSV === undefined,
  });

  const getStudents = useQuery(GET_STUDENTS, {
    variables: {
      limit,
      page: currentPage,
      order: sorting?.order,
      orderDirection: sorting?.orderType,
      filters: {
        archetype: filters?.archetype || undefined,
        achievements: (filters?.achievements?.length > 0 && filters?.achievements) || undefined,
        activities: (filters?.activities?.length > 0 && filters?.activities) || undefined,
        work_experiences: (filters?.work_experiences?.length > 0 && filters?.work_experiences) || undefined,
        international_experiences:
          (filters?.international_experiences?.length > 0 && filters?.international_experiences) || undefined,
        archetypes: (filters?.archetypes?.length > 0 && filters?.archetypes) || undefined,
        institutes: (filters?.institutes?.length > 0 && [filters?.institutes]) || undefined,
        education_level: (filters?.education_level?.length > 0 && filters?.education_level) || undefined,
        education_subject: (filters?.education_subject?.length > 0 && filters?.education_subject) || undefined,
        city: filters?.city || undefined,
        country: filters?.country || undefined,
        grades: (filters?.grades?.length > 0 && filters?.grades) || undefined,
        degrees: (filters?.degrees?.length > 0 && filters?.degrees) || undefined,
        interests: (filters?.interests?.length > 0 && filters?.interests) || undefined,
        languages: (filters?.languages?.length > 0 && filters?.languages) || undefined,
        username: searchName,
        sex: filters?.sex || undefined,
        referrer: filters?.referrer || undefined,
      },
    },
    skip: messageId || likedEntity?.id,
    fetchPolicy: 'cache-and-network',
  });
  const getStudents2 = useQuery(GET_STUDENTS_WITH_MESSAGES, {
    variables: {
      limit,
      page: currentPage,
      filters: {
        id: messageId,
        account_ids: idsStudentsWithMessages && idsStudentsWithMessages?.map((item: any) => parseInt(item)),
      },
    },
    skip: messageId === '' || likedEntity.id,
  });
  const getStudentsThatLiked = useQuery(GET_STUDENTS_THAT_LIKED, {
    variables: {
      limit,
      page: currentPage,
      order: sorting?.order,
      orderDirection: sorting?.orderType,
      filters: {
        archetype: filters?.archetype || undefined,
        achievements: (filters?.achievements?.length > 0 && filters?.achievements) || undefined,
        activities: (filters?.activities?.length > 0 && filters?.activities) || undefined,
        work_experiences: (filters?.work_experiences?.length > 0 && filters?.work_experiences) || undefined,
        international_experiences:
          (filters?.international_experiences?.length > 0 && filters?.international_experiences) || undefined,
        archetypes: (filters?.archetypes?.length > 0 && filters?.archetypes) || undefined,
        institutes: (filters?.institutes?.length > 0 && [filters?.institutes]) || undefined,
        education_level: (filters?.education_level?.length > 0 && filters?.education_level) || undefined,
        education_subject: (filters?.education_subject?.length > 0 && filters?.education_subject) || undefined,
        city: filters?.city || undefined,
        country: filters?.country || undefined,
        grades: (filters?.grades?.length > 0 && filters?.grades) || undefined,
        degrees: (filters?.degrees?.length > 0 && filters?.degrees) || undefined,
        interests: (filters?.interests?.length > 0 && filters?.interests) || undefined,
        languages: (filters?.languages?.length > 0 && filters?.languages) || undefined,
        username: searchName,
      },
      id: likedEntity.id,
      type: likedEntity.type,
    },
    skip: !likedEntity.id,
  });
  const [deleteStudent] = useMutation(DELETE_STUDENT);
  const [exportCvMutation] = useExportCvMutation({
    variables: {
      accountIds: selectedRowKeys,
    },
  });

  useEffect(() => {
    history.location.state
      ? states && dispatch(setSelectedRowKeysWithMessages(states?.ids?.map((item: any) => item.toString()) || []))
      : dispatch(setSelectedRowKeysWithMessages([]));
    // history.location.state
    //   ? states && dispatch(setSelectedRowKeys(states?.ids?.map((item: any) => item.toString()) || []))
    //   : dispatch(setSelectedRowKeys([]));
    history.location.state ? states && dispatch(setMessageId(states?.messageId)) : dispatch(setMessageId(undefined));
    history.replace(history.location.pathname, {});
  }, [history]);

  useEffect(() => {
    if (exportedCSVData) {
      if (exportedCSVData.data) {
        if (!open) {
          window.open(exportedCSVData.data.exportCSV);
          setOpen(true);
        }
      }
    }
  }, [exportedCSVData]);

  useEffect(() => {
    dispatch(setSelectedRowKeys([]));
    return () => {
      dispatch(setEntityLiked({}));
    };
  }, []);

  // Filter DEFAULT values
  useEffect(() => {
    // dispatch(StudentsActionsRedux.setSorting(undefined));
    // dispatch(StudentsActionsRedux.setSearchName(undefined));
    // dispatch(StudentsActionsRedux.setFilters(undefined));
    dispatch(setDataCSV(undefined));
    if (!idsStudents) {
      dispatch(setSelectedRowKeys([]));
      dispatch(setSelectedRowKeysOnDiffPages([]));
    }
  }, [dispatch]);

  // Passing data from GET_STUDENTS
  useEffect(() => {
    if (getStudents.data) {
      dispatch(
        StudentsActionsRedux.setEntities(
          getStudents.data.students.result.map((item: any) => {
            return {...item, key: item.id};
          }),
        ),
      );
      dispatch(StudentsActionsRedux.setEntityCount(getStudents.data.students.total));
    }
  }, [getStudents.data]);

  // Passing data from GET_STUDENTS_WITH_MESSAGES
  useEffect(() => {
    if (getStudents2.data && states) {
      setextendedV(true);
      dispatch(
        StudentsActionsRedux.setEntities(
          getStudents2.data.studentsWithMessage.result.map((item: any) => {
            return {
              ...item,
              key: item.id ? item.id : item.account_id.toString(),
              id: item.id ? item.id : item.account_id.toString(),
            };
          }),
        ),
      );
      dispatch(StudentsActionsRedux.setEntityCount(getStudents2.data.studentsWithMessage.total));
    }
  }, [getStudents2.data, states]);

  // Passing data from GET_STUDENTS_THAT_LIKED
  useEffect(() => {
    if (getStudentsThatLiked.data && likedEntity.id) {
      setextendedV(true);
      dispatch(
        StudentsActionsRedux.setEntities(
          getStudentsThatLiked.data.studentsWhichLiked.result.map((item: any) => {
            return {
              ...item,
              key: item.id ? item.id : item.account_id.toString(),
              id: item.id ? item.id : item.account_id.toString(),
            };
          }),
        ),
      );
      dispatch(StudentsActionsRedux.setEntityCount(getStudentsThatLiked.data.studentsWhichLiked.total));
    }
  }, [getStudentsThatLiked.data]);

  const onSelectChange = React.useCallback(
    (selectedRowKeysThis: string[]) => {
      const res = selectedRowKeys.slice();

      students.forEach((s: any) => {
        const wasSelected = selectedRowKeys.includes(s.id);
        const isSelected = selectedRowKeysThis.includes(s.id);
        if (wasSelected && !isSelected) {
          res.splice(res.indexOf(s.id), 1);
        } else if (!wasSelected && isSelected) {
          res.push(s.id);
        }
      });
      // находим и удаляем из селектед все что могут быть
      // применяем новый массив
      dispatch(setSelectedRowKeys(res));
    },
    [students],
  );

  const exportCSV = React.useCallback(() => {
    setOpen(false);
    if (dataCSV !== selectedRowKeys) {
      dispatch(setDataCSV(selectedRowKeys));
    } else {
      exportedCSVData.refetch();
    }
  }, [dataCSV, selectedRowKeys, exportedCSVData]);

  const onExportCV = React.useCallback(() => {
    const exportCSV = async () => {
      setLoader(true);
      try {
        await exportCvMutation();
        showNotification(NotificationTypes.success, 'Done! Check your email for the upload link');
      } catch (err) {
        showNotification(NotificationTypes.error, 'Something went wrong');
      }
      setLoader(false);
    };

    exportCSV();
  }, [selectedRowKeys]);

  const closeModalFilter = React.useCallback(() => {
    setfilterOpen(false);
  }, []);

  const {filters: reduxFilters}: any = useSelector((state: any) => state.students);

  return (
    <div className={styles.mainWrapper}>
      <PageWithDrawer>
        {getStudents.error && <LoadingComponent />}
        <StatusBar>
          <SelectAll />
          <SearchAndFilters
            styles={styles}
            active={!!Object.keys(reduxFilters || {}).length}
            setValueSearch={setValueSearch({
              dispatch,
              setFunc: StudentsActionsRedux.setSearchName,
              refetchEntity: getStudents,
              setPageFunc: StudentsActionsRedux.setCurrentPage,
            })}
            setfilterOpen={setfilterOpen}
          />
          <Tooltip
            placement="top"
            title={`In case one of the following was changed in the database:
          ▪  international_experiences
          ▪  work_experiences
          ▪  education_level
          ▪  activities
          ▪  languages
          ▪  sex
          ▪  achievements
For a change to take place for existing students this button has to be pressed. 
Example: Persona label was changed from “Actively looking for an internship“ to “Internship seeker“. No additional action needed if new language was added.`}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => setsyncronizeWindow(true)}
              style={{marginRight: '1rem', marginLeft: '1rem'}}>
              Synchronize
            </Button>
          </Tooltip>
          <Button
            type="primary"
            disabled={selectedRowKeys && selectedRowKeys?.length === 0}
            htmlType="submit"
            onClick={() => setmessageWindow(true)}
            className={styles.customButton}>
            Send message
          </Button>
          <Button
            type="primary"
            disabled={selectedRowKeys && selectedRowKeys?.length === 0}
            htmlType="submit"
            onClick={() => exportCSV()}
            className={styles.customButton}>
            Export CSV
          </Button>
          <Button
            type="primary"
            disabled={selectedRowKeys && selectedRowKeys?.length === 0}
            className={styles.customButton}
            onClick={onExportCV}>
            Export CV
          </Button>
          <SingleStudentsFilter />
        </StatusBar>
        <div className={styles.borderTable}>
          <TableComponent
            onChangeTable={setValueSearch({
              dispatch,
              setFunc: StudentsActionsRedux.setSorting,
              refetchEntity: likedEntity?.id ? getStudentsThatLiked : getStudents,
              setPageFunc: StudentsActionsRedux.setCurrentPage,
            })}
            deleteItem={openModal(setConfirmDeleteOpened, setDeletedId)}
            entityTitle="students"
            extendedV={extendedV}
            entity={students}
            setId={setIdOneEntity(dispatch, StudentActionsRedux.setEntityId)}
            onSelectChange={onSelectChange}
            selectedRowKeys={selectedRowKeys}
          />
          <div className={styles.borderPagination}>
            <PaginationComponent
              currentPage={currentPage}
              limit={limit}
              onChangeNumber={setNumberPage(
                dispatch,
                StudentsActionsRedux.setCurrentPage,
                StudentsActionsRedux.setLimit,
                getStudents,
              )}
              total={studentsCount}
            />
            {selectedRowKeys && selectedRowKeys?.length === 0 ? (
              <div>Totally {studentsCount} students on the list</div>
            ) : (
              <div>
                {selectedRowKeys && selectedRowKeys?.length} out of {studentsCount} students were selected.
              </div>
            )}
          </div>
        </div>
        <ModalFiltersStudents
          open={filterOpen}
          onClose={closeModalFilter}
          getStudents={getStudents}
          listWithMessages={idsStudentsWithMessages}
        />
        <ModalMessage
          open={messageWindow}
          onClose={() => setmessageWindow(false)}
          selectedStudents={selectedRowKeys}
          setLoader={setLoader}
          setisSuccessModal={setisSuccessModal}
        />
        <ModalSynchronize
          open={syncronizeWindow}
          onClose={() => setsyncronizeWindow(false)}
          options={optionsSynchronize}
          entity={'students'}
        />
        <ConfirmDeleteChanel
          open={isConfirmDeleteOpened}
          onClose={handleConfirmDeleteClose(deleteStudent, deletedId, setConfirmDeleteOpened, getStudents.refetch)}
          message="This student will be deleted"
        />
        {loader && (
          <Portal>
            <Loader loader={loader} />
          </Portal>
        )}
        <SuccessModal onClose={() => setisSuccessModal(false)} open={isSuccessModal} messageKey="Message was sent" />
      </PageWithDrawer>
    </div>
  );
};

export default Students;
