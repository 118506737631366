import React from 'react';
import {useHistory} from 'react-router';
import {Table} from 'antd';
import {setEntityLiked} from '../../store/students/actions';
import {useDispatch} from 'react-redux';

interface Props {
  deleteItem: any;
  entityTitle: string;
  columns: any;
  entity: any;
  onChangeTable: any;
  setId: any;
}

const SCROLL = {y: '72vh'};

const TableComponent: React.FC<Props> = ({deleteItem, entityTitle, columns, entity, onChangeTable, setId}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToItem = React.useCallback(
    (id: string) => {
      setId(id);
      history.push(`${entityTitle}/details`, {id});
    },
    [setId, history, entityTitle],
  );

  const setEntityThatLiked = React.useCallback(
    (entity: any) => {
      dispatch(setEntityLiked(entity));
      history.push(`students`);
    },
    [history, dispatch],
  );

  const sorting = React.useCallback(
    (_a: any, _b: any, sorter: any) => {
      const newOrder =
        sorter.order === undefined
          ? {order: undefined, orderType: undefined}
          : sorter.order === 'descend'
          ? {order: sorter.columnKey, orderType: 'desc'}
          : {order: sorter.columnKey, orderType: 'asc'};

      onChangeTable(newOrder);
    },
    [onChangeTable],
  );

  const columnsRes = React.useMemo(() => columns(goToItem, deleteItem, setEntityThatLiked), [
    columns,
    goToItem,
    deleteItem,
    setEntityThatLiked,
  ]);

  return <Table columns={columnsRes} onChange={sorting} dataSource={entity} pagination={false} scroll={SCROLL} />;
};

export default React.memo(TableComponent);
